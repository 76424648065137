import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const GetAllHomeBanner = async () => {
  try {
    return await getData(`/homeBanner`);
  } catch (error) {
    console.log(error);
  }
};

export const upcomingGameGet = async () => {
  try {
    return await getData(`/upcommingGame`);
  } catch (error) {
    console.log(error);
  }
};

export const notificationGet = async (userLanguageId) => {
  try {
    return await getData(`/announcement?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};


export const gameRuleGet = async (userLanguageId) => {
  try {
    return await getData(`/getgamerule?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};

export const homeDetails = async () => {
  try {
    return await getData(`/homeDetails`);
  } catch (error) {
    console.log(error);
  }
};


export const aboutUsBanner = async () => {
  try {
    return await getData(`/aboutBanner`);
  } catch (error) {
    console.log(error);
  }
};

export const aboutUsDescription = async (userLanguageId) => {
  try {
    return await getData(`/aboutDescription?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};

export const getInvitationRule = async (userLanguageId) => {
  try {
    return await getData(`/invitationRule?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};

export const getsubordinateData = async () => {
  try {
    return await getData(`/subordinateData`);
  } catch (error) {
    console.log(error);
  }
};


export const getdepositeData = async () => {
  try {
    return await getData(`/depositebonus`);
  } catch (error) {
    console.log(error);
  }
};

export const allChanel = async () => {
  try {
    return await getData(`/channel`);
  } catch (error) {
    console.log(error);
  }
};


export const DepositeAmount = async (channel_id) => {
  try {
    return await getData(`/depositAmount?channel_id=${channel_id}`);
  } catch (error) {
    console.log(error);
  }
};


export const allInstruction = async (userLanguageId) => {
  try {
    return await getData(`/rechargeInstruction?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};


export const howToPlayGet = async (userLanguageId) => {
  try {
    return await getData(`/howToPlay?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};


export const loginNotification = async () => {
  try {
    return await getData(`/notificationGet`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteNoti = async (id) => {
  try {
    return await postData(`/notificationDelete?id=${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const customerService = async () => {
  try {
    return await getData(`/customerService`);
  } catch (error) {
    console.log(error);
  }
};

export const walletData = async () => {
  try {
    return await getData(`/wallet`);
  } catch (error) {
    console.log(error);
  }
};

export const betData = async () => {
  try {
    return await getData(`/latestbet`);
  } catch (error) {
    console.log(error);
  }
};


export const bankData = async () => {
  try {
    return await getData(`/getuserbank`);
  } catch (error) {
    console.log(error);
  }
};



export const myTransactionHistory = async (selectedtype,selecteddate) => {
  try {
    // return await getData(`/myTransactionHistory?type=${selectedtype}&date=${selecteddate}`);
    if (selectedtype && selecteddate) {
      return await getData(`/myTransactionHistory?type=${selectedtype}&date=${selecteddate}`);
    } else if (selectedtype) {
      return await getData(`/myTransactionHistory?type=${selectedtype}`);
    } else if (selecteddate) {
      return await getData(`/myTransactionHistory?date=${selecteddate}`);
    } else {
      return await getData(`/myTransactionHistory`);
    }
  } catch (error) {
    console.log(error);
  }
};

export const walletDataPost = async (data) => {
  try {
    return await postData(`/wallet`, data);
  } catch (error) {
    console.log(error);
  }
};


export const feedbackData = async (feedback) => {
  try {
    return await postData(`/feedback`, feedback);
  } catch (error) {
    console.log(error);
  }
};


export const editProfile = async (data) => {
  try {
    return await postData(`/editProfile`, data);
  } catch (error) {
    console.log(error);
  }
};


export const changePassword = async (data) => {
  try {
    return await postData(`/changePassword`, data);
  } catch (error) {
    console.log(error);
  }
};

export const todaysEarningGet = async () => {
  try {
    return await getData(`/todaysEarning`);
  } catch (error) {
    console.log(error);
  }
};

export const winningInfo = async () => {
  try {
    return await getData(`/winningInformation`);
  } catch (error) {
    console.log(error);
  }
};

export const gameHistory = async (page) => {
  try {
    return await getData(`/gameHistory?${page}`);
  } catch (error) {
    console.log(error);
  }
};

export const myGameHistory = async (page) => {
  try {
    return await getData(`/myHistory?${page}`);
  } catch (error) {
    console.log(error);
  }
};


export const myHistory = async (selectedtype,selecteddate) => {
  try {
    if (selectedtype && selecteddate) {
      return await getData(`/myGameHistory?type=${selectedtype}&date=${selecteddate}`);
    } else if (selectedtype) {
      return await getData(`/myGameHistory?type=${selectedtype}`);
    } else if (selecteddate) {
      return await getData(`/myGameHistory?date=${selecteddate}`);
    } else {
      return await getData(`/myGameHistory`);
    }
  } catch (error) {
    console.log(error);
  }  
};

export const myWithdrawHistory = async (selectedtype,selecteddate) => {
  try {
    if (selectedtype && selecteddate) {
      return await getData(`/myWithdrawHistory?type=${selectedtype}&date=${selecteddate}`);
    } else if (selectedtype) {
      return await getData(`/myWithdrawHistory?type=${selectedtype}`);
    } else if (selecteddate) {
      return await getData(`/myWithdrawHistory?date=${selecteddate}`);
    } else {
      return await getData(`/myWithdrawHistory`);
    }
  } catch (error) {
    console.log(error);
  }  
};


export const withdrawHistory = async () => {
  try {
    return await getData(`/withdrawHistory`);
  } catch (error) {
    console.log(error);
  }
};


export const depositeHistory = async (selectedtype,selecteddate) => {
  try {
    // return await getData(`/depositeHistory?type=${selectedtype}&date=${selecteddate}`);
    if (selectedtype && selecteddate) {
      return await getData(`/depositeHistory?type=${selectedtype}&date=${selecteddate}`);
    } else if (selectedtype) {
      return await getData(`/depositeHistory?type=${selectedtype}`);
    } else if (selecteddate) {
      return await getData(`/depositeHistory?date=${selecteddate}`);
    } else {
      return await getData(`/depositeHistory`);
    }
  } catch (error) {
    console.log(error);
  }
};

export const gift = async (data) => {
  try {
    return await postData(`/gift`, data);
  } catch (error) {
    console.log(error);
  }
};

export const giftGet = async () => {
  try {
    return await getData(`/giftGet`);
  } catch (error) {
    console.log(error);
  }
};


export const noticeGet = async (userLanguageId) => {
  try {
    return await getData(`/notice?language_id=${userLanguageId}`);
  } catch (error) {
    console.log(error);
  }
};

export const getAttendance = async () => {
  try {
    return await getData(`/getattendencebonus`);
  } catch (error) {
    console.log(error);
  }
};

export const getUserAttendance = async () => {
  try {
    return await getData(`/getAttendancebonus`);
  } catch (error) {
    console.log(error);
  }
};

export const languageGet = async () => {
  try {
    return await getData(`/language`);
  } catch (error) {
    console.log(error);
  }
};

export const userLanguage = async () => {
  try {
    return await getData(`/userLanguage`);
  } catch (error) {
    console.log(error);
  }
};

export const languagePost = async (data) => {
  try {
    return await postData(`/languagePost`, data);
  } catch (error) {
    console.log(error);
  }
};

export const gamePost = async (data) => {
  try {
    return await postData(`/game`, data);
  } catch (error) {
    console.log(error);
  }
};

export const withdrawMoney = async (data) => {
  try {
    return await postData(`/withdraw`, data);
  } catch (error) {
    console.log(error);
  }
};

export const betNew = async (data) => {
  try {
    return await postData(`/bet`, data);
  } catch (error) {
    console.log(error);
  }
};

export const resultAction = async () => {
  try {
    return await getData(`/resultAction`);
  } catch (error) {
    console.log(error);
  }
};