import React, { useState } from "react";
import "./WithdrawTab2.css";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import WithdrawalHistoryCard from "../WithdrawTab1/WithdrawalHistoryCard/WithdrawalHistoryCard";
import AddUsdtModal from "../../../Common-Component/Common_Modal/AllSettingModal/AddUsdtModal";


const WithdrawTab2 = () => {
    const [modalAdd, setModalAdd] = React.useState(false);
    const [showAll, setShowAll] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const Seedata = [
        {
            id: 1,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        },
        {
            id: 2,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        },
        {
            id: 3,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        },
        {
            id: 4,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        }
    ];
    return (
        <>
            <section className="Withdraw-Tab1">
                <Row className="justify-content-center">
                    <Col xxl={6} xl={6} lg={12} md={12} >
                        <div className="Background">
                            <div className="dashed">
                                <div className="main-add">
                                    <div className="plus-background" onClick={() => setModalAdd(true)}>
                                        <FontAwesomeIcon icon={faPlus} className="icon" />
                                    </div>
                                    <p className="text">Add Address</p>
                                </div>
                            </div>
                            <div className="image-holder my-3">
                                <img className="payment-t me-2" src={process.env.PUBLIC_URL + "/assets/Icons/t.png"} /><span>Select Amount Of USDT</span>
                            </div>
                            <div className="form">
                                <Form>
                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm"><img className="rupees me-2" src={process.env.PUBLIC_URL + "/assets/profile/rupay-1.png"} /></InputGroup.Text>
                                        <Form.Control
                                            placeholder="Please Enter the Amount"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </InputGroup>
                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm"><img className="rupees me-2" src={process.env.PUBLIC_URL + "/assets/Icons/t.png"} /></InputGroup.Text>
                                        <Form.Control
                                            placeholder="Please Enter USDT Amount"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        />
                                    </InputGroup>
                                </Form>
                                <div className="d-flex ">
                                    <div className="text-balance">
                                        <p>Withdrawable balance</p>
                                    </div>
                                    <div className="text-balance ms-auto">
                                        <h6><img className="rupees me-2" src={process.env.PUBLIC_URL + "/assets/profile/rupay-1.png"} /><span>104.00</span></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="withdraw-btn-one">
                                <Common_Button text="withdraw" className={"SubmitBtn"} width={"100%"} background={"#F89001"} border={"1px solid #F89001"} border-radius={"7px"} />
                            </div>
                            <div className="see-more-sec">
                                <div className="text-name text-center" onClick={toggleShowAll}>
                                    <p>{showAll ? 'See Less' : 'See More'}</p>
                                    <FontAwesomeIcon icon={showAll ? faAngleUp : faAngleDown} className="fa-down" />
                                </div>
                                <div className="all-see-text">
                                    {Seedata.slice(0, showAll ? Seedata.length : 0).map(item => (
                                        <div key={item.id} className='d-flex mb-3'>
                                            <div className="me-3">
                                                <div className='orange-box text-center mt-2'>
                                                    <div className='ractangle-icon'></div>
                                                </div>
                                            </div>
                                            <p>{item.content}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={12} md={12} >
                        <WithdrawalHistoryCard />
                    </Col>
                </Row>
            </section>
            <AddUsdtModal
                headingName="Add USDT Address"
                show={modalAdd}
                onHide={() => setModalAdd(false)}
            />
        </>
    );
};

export default WithdrawTab2;
