import React, { useState, useEffect } from "react";
import "./Attendance_Bonus.css";
import { Col, Row } from "react-bootstrap";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Attendance_Star_Card from "../../../Common-Component/Attendance_Star_Card/Attendance_Star_Card";
import Attendance_Gift_Card from "../../../Common-Component/Attendance_Gift_Card/Attendance_Gift_Card";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getAttendance, getUserAttendance } from "../../../../utils/apis/master/Master";

function Attendance_Bonus() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Activity", link: "/activity" },
    { text: "Attendance Bonus", link: "" },
  ]);


  const [depositeFirst, SetDepositeFirst] = useState([]);
  const [gameGift, setGameGift] = useState([]);

  const attendenceData = async () => {
    {
    const res = await getUserAttendance();
    if (res?.success) {
      SetDepositeFirst(res?.data);
    }
  }
  {
    const res = await getAttendance();
    if (res?.success) {
      setGameGift(res?.data);
    }
  }
  }
  console.log(depositeFirst, "depositeFirstdepositeFirstdepositeFirst");

  useEffect(() => {
    attendenceData();
  }, []);

  return (
    <>
      <section className="profile_gift_section Attendance_Bonus p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="profile_gift_main mt-5">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="giftbanner">
                <div className="gift_banner_img_div">
                  <img
                    className="gift_banner_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Activity/happy-birthday.png"
                    }
                  />
                </div>
                <div className="banner_half_overlay"></div>

                <div className="gift_formdiv">
                  <Row>
                    <Col xxl={7} xl={9} md={10} lg={10} className="mx-auto">
                      <div className="gift_form_main ">
                        <div className="text-holder">
                          <h4>Attendance Bonus</h4>
                          <p>Get reword based on consecutive login days</p>
                          <h3>
                            <FontAwesomeIcon
                              className="calender-icon"
                              icon={faCalendar}
                            />
                            Attendance Consecutively {depositeFirst?.bonusHistory?.short_by} Day
                          </h3>
                        </div>

                        <div className="patch-holder">
                          <h3>Accumlated</h3>
                          <h4>₹ {depositeFirst?.bonusHistoryAmount}</h4>
                        </div>
                        <div className="btn-holder">
                          <Link to={"/game-rules"}>
                            <Common_Button
                              className="receive_button mt-4"
                              text={"Game Rules"}
                            />
                          </Link>
                          <Link to={"/attendance-history"}>
                            <Common_Button
                              className="receive_button mt-4"
                              text={"Attendance History"}
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="gift_history_div">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="gift_history_main">
                <h6 className="gift_history_title">History</h6>

                <div className="gift-history-data-holder">
                  <div className="card-row-holder">
                    <div className="row justify-content-center">
                      {depositeFirst?.bonusHistors?.map((data, index) => (
                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6">
                          <div className="start-card-holder">
                            <Attendance_Star_Card
                              rupees={`₹${data?.bonus_amount}`}
                              days={data?.short_by}
                            />

                          </div>
                        </div>

                      ))}
                      {/* <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <div className="start-card-holder">
                          <Attendance_Star_Card
                            rupees={"₹ 20.00"}
                            days={"2 Day"}
                          />
                        </div>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <div className="start-card-holder">
                          <Attendance_Star_Card
                            rupees={"₹ 100.00"}
                            days={"3 Day"}
                          />
                        </div>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <div className="start-card-holder">
                          <Attendance_Star_Card
                            rupees={"₹ 200.00"}
                            days={"4 Day"}
                          />
                        </div>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <div className="start-card-holder">
                          <Attendance_Star_Card
                            rupees={"₹ 450.00"}
                            days={"5 Day"}
                          />
                        </div>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6">
                        <div className="start-card-holder">
                          <Attendance_Star_Card
                            rupees={"₹ 2,800.00"}
                            days={"6 Day"}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="Attendance_Gift_Card_main">
                    <Attendance_Gift_Card gameGift={gameGift}/>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="text-center mt-5">
          <Common_Button
            className={"SubmitBtn"}
            background={"#F89001"}
            padding={"7px 50px"}
            border={"1px solid #F89001"}
            text={"Attendance"}
          />
        </div>
      </section>
    </>
  );
}

export default Attendance_Bonus;
