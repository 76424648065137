import React, { useState, useEffect, useContext } from 'react'
import './Profile_language_main.css'
import { Col, Row } from 'react-bootstrap'
import { Context } from '../../../../utils/context'
import { languageGet, userLanguage, languagePost } from '../../../../utils/apis/master/Master'
import Cookies from "js-cookie";

function Profile_language_main() {
    const language = [
        {
            name: "Hindi",
            image: process.env.PUBLIC_URL + "/assets/Wallet/india.png"
        },
        {
            name: "English",
            image: process.env.PUBLIC_URL + "/assets/Wallet/kingdom.png"
        },
        {
            name: "Hindi",
            image: process.env.PUBLIC_URL + "/assets/Wallet/india.png"
        },
    ]

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const handleLanguageClick = async (language_id) => {
        const res = await languagePost({ language_id });
        setSelectedLanguage(language_id);
        if (res?.success) {
            Cookies.set('language_id', language_id);
            getProject();
            getProjectTwo();
            window.location.reload();
        }
    };

    const { IMG_URL } = useContext(Context)
    const [getLanguage, setLanguage] = useState();
    const getProject = async () => {
        const res = await languageGet();
        if (res?.success) {
            setLanguage(res?.data)
        }
    }
    const [getUserLanguage, setUserLanguage] = useState();
    const getProjectTwo = async () => {
        const res = await userLanguage();
        if (res?.success) {
            setUserLanguage(res?.data)
        }
    }

    useEffect(() => {
        getProject();
        getProjectTwo();
    }, []);
    return (
        <section className='profile_language_section'>
            <div className='profile_language_main'>
                <Row>
                    {getLanguage?.map((langs, index) => (
                        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={6}>
                            <div className={`language_box ${getUserLanguage?.language_id === langs?.id ? 'selected' : ''}`}
                                onClick={() => handleLanguageClick(langs?.id)}>
                                <img className="laguage_logo " src={IMG_URL + langs?.image} alt="Logo" />
                                <div className='lang_name_main'>
                                    <div className='lang_name'>
                                        <p className='lang_text'>{langs?.name}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

            </div>
        </section>
    )
}

export default Profile_language_main