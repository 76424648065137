import React, { useContext, useState, useEffect } from "react";
import "./Homepage.css";
import HomeBanner from "./HomeBanner/HomeBanner";
import Om_Winner_Info from "./Om_Winner_Info/Om_Winner_Info";
import Earnings_Chart from "./Earnings_Chart/Earnings_Chart";
import Sidebar from "../Sidebar/Sidebar";
import UpcomingGame from "./UpcomingGame/UpcomingGame";
import Notice from "./Notice/Notice";
import { Context } from "../../utils/context";
import { GetAllHomeBanner, upcomingGameGet, todaysEarningGet } from "../../utils/apis/master/Master";

function Homepage() {
  const { signin } = useContext(Context)
  const [homeBanner, setHomeBanner] = useState([]);
  const [firstHomeBanner, setFirstHomeBanner] = useState();
  const getHomeBanner = async () => {
    const res = await GetAllHomeBanner();
    if (res?.success) {
      setHomeBanner(res?.data);
      setFirstHomeBanner(res?.data[0]);
    }
  }

  const [upcomingGame, setUpcomingGame] = useState([]);
  const getUpcomingGame = async () => {
    const res = await upcomingGameGet();
    if (res?.success) {
      setUpcomingGame(res?.data)
    }
  }

  const [todaysEarning, setTodaysEarning] = useState();
  const getTodaysEarning = async () => {
    const res = await todaysEarningGet();
    if (res?.success) {
      setTodaysEarning(res?.data)
    }
  }

  useEffect(() => {
    getHomeBanner();
    getUpcomingGame();
    getTodaysEarning();
  }, []);

  return (
    <>

      <div className="row me-0 ms-0">
        <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
          <div className="sidebar_holder">
            <Sidebar />
          </div>
        </div>

        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
          <div className="main-content-holder">
            <HomeBanner homeBanner={homeBanner} firstHomeBanner={firstHomeBanner} />
            <Notice />
            <Om_Winner_Info />
            <UpcomingGame upcomingGame={upcomingGame} />
            <Earnings_Chart todaysEarning={todaysEarning} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
