import React, { useState } from "react";
import "./Attendance_History.css";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import BreadcrumComponent from "../../../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import Common_Button from "../../../../../Common-Component/Common_Button/Common_Button";
import Gift_Img_Holder from "../../../../../Common-Component/Gift_Img_Holder/Gift_Img_Holder";

function Attendance_History() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Activity", link: "/activity" },
    { text: "Attendance Bonus", link: "/attendance-bonus" },
    { text: "Attendance Bonus", link: "" },
  ]);

  return (
    <>
      <section className="profile_gift_section Attendance_History  p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="profile_gift_main mt-5">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="giftbanner">
                <div className="gift_banner_img_div">
                  <img
                    className="gift_banner_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Activity/happy-birthday.png"
                    }
                  />
                </div>
                <div className="banner_half_overlay"></div>

                <div className="gift_formdiv">
                  <Row>
                    <Col xxl={10} xl={10} lg={10} md={8} className="mx-auto">
                      <div className="gift_form_main ">
                        <Common_Button
                          className={"SubmitBtn"}
                          text={"History"}
                          width={"100%"}
                        />
                        <div className="data-holder">
                          <Gift_Img_Holder />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Attendance_History;
