import React, { useState, useContext, useEffect } from "react";
import "./Game_Bid.css";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import Win_Go_Modal from "../../../Common-Component/Common_Modal/Win_Go_Modal/Win_Go_Modal";
import Circle_Text_Holder from "../../../Common-Component/Circle_Text_Holder/Circle_Text_Holder";
import { Context } from "../../../../utils/context";

function Game_Bid() {


  const { setSelectedColor, setSelectedSize,multiply,setMultiply,activeIndex, setActiveIndex} = useContext(Context);
  const [coinNumber, setNumberCoin] = useState('');

  const [BigModalShow, setBigModalShow] = useState(false);
  const [SmallModalShow, setSmallModalShow] = useState(false);
  const [coinModalShow, setCoinModalShow] = useState(false);
  // const [setSelectedColor, setSelectedColor] = useState('');
  const [modalShow, setModalShow] = useState({
    Red: false,
    Violet: false,
    Green: false,
  });

  const buttons = [
    {
      text: "Red",
      border: "#7C0101",
      background:
        "transparent linear-gradient(180deg, #F80101 0%, #7C0101 100%)",
      color: "white",
      onClick: () => { setModalShow({ ...modalShow, Red: true }); setSelectedColor("Red") },
    },
    {
      text: "Violet",
      border: "#070756",
      background:
        "transparent linear-gradient(180deg, #0D0DAB 0%, #070756 100%)",
      color: "white",
      onClick: () => { setModalShow({ ...modalShow, Violet: true }); setSelectedColor("Violet") },
    },
    {
      text: "Green",
      border: "#1B5100",
      background:
        "transparent linear-gradient(180deg, #35A200 0%, #1B5100 100%)",
      color: "white",
      onClick: () => { setModalShow({ ...modalShow, Green: true }); setSelectedColor("Green") },
    },
  ];

  const sectionButtons = [
    {
      text: "Big",
      background: "#3A0061",
      border: "#3A0061",
      onClick: () => { setBigModalShow(true); setSelectedSize("Big") },
    },
    {
      text: "Small",
      background:
        "transparent linear-gradient(180deg, #F89001 0%, #FFAA00 100%)",
      border: "#FFAA00",
      onClick: () => { setSmallModalShow(true); setSelectedSize("Small") },
    },
  ];

  return (
    <div className="Game_Bid">
      <div className="bid_btn_holder">
        <div className="row">
          {buttons.map((button, index) => (
            <div
              className="col-md-4 col-4"
              id={
                button.text === "Red" || button.text === "Violet"
                  ? "bid-btn"
                  : ""
              }
              key={index}
            >
              <div className="btn-bid">
                <Common_Button
                  className={"SubmitBtn"}
                  text={button.text}
                  border={button.border}
                  background={button.background}
                  color={button.color}
                  boxShadow={" 0px 3px 6px #FFFFFF29"}
                  borderRadius={"8px"}
                  width={"100%"}
                  padding={"8px 0px"}
                  onClick={button.onClick}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="coins-holder">
        <div className="row justify-content-center">
          {[0, 2, 4, 6, 8]?.map((points, index) => (
            <div className="col-md-2" key={index}>
              <div
                className="coin_point"
                onClick={() => { setCoinModalShow(true); setNumberCoin(points); }}
              >
                <div className="coin-img-holder">
                  <img
                    className="coin_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Home/Home-inner/Game_Card/squre-coin.png"
                    }
                    alt="coin_img"
                  />
                </div>
                <div className="point-text-holder">
                  <p>{points}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" balance-holder">
        <div className="text-holder">
          <p>Balance</p>
        </div>

        <div className="balance-point-holder">
          <Circle_Text_Holder  setMultiply={setMultiply} activeIndex={activeIndex} setActiveIndex={setActiveIndex}
            background={"#F5F5F5"}
          />
        </div>
      </div>

      <div className="section-btn-holder">
        {sectionButtons.map((button, index) => (
          <Common_Button
            key={index}
            text={button.text}
            width={"100%"}
            className={"SubmitBtn"}
            background={button.background}
            border={button.border}
            margin={"0px 50px"}
            padding={"10px 0px"}
            onClick={button.onClick}
          />
        ))}
      </div>

      {Object.entries(modalShow).map(([key, value]) => (
        <Win_Go_Modal
          key={key}
          show={value}
          onHide={() => setModalShow({ ...modalShow, [key]: false })}
          background={
            key === "Red"
              ? "transparent linear-gradient(180deg, #f80101 0%, #7c0101 100%)"
              : key === "Violet"
                ? "transparent linear-gradient(180deg, #0D0DAB 0%, #070756 100%)"
                : key === "Green"
                  ? "transparent linear-gradient(180deg, #35A200 0%, #1B5100 100%)"
                  : ""
          }
          subHeadingText={
            key === "Red"
              ? "Select Red"
              : key === "Violet"
                ? "Select Violet"
                : key === "Green"
                  ? "Select Green"
                  : ""
          }
          btnTextColor={
            key === "Red"
              ? "#FF0000"
              : key === "Violet"
              ? "#7300CF"
              : key === "Green"
              ? "#00FF08"
              : ""
          }
        />
      ))}

      <Win_Go_Modal
        show={BigModalShow}
        onHide={() => setBigModalShow(false)}
        background={"#3A0061"}
        subHeadingText={"Select Big"}
        btnTextColor={"#AC46FF"}
      />

      <Win_Go_Modal
        show={SmallModalShow}
        onHide={() => setSmallModalShow(false)}
        background={
          "transparent linear-gradient(180deg, #F89001 0%, #F9BA63 100%)"
        }
        subHeadingText={"Select Small"}
        btnTextColor={"#F9BA63"}
      />

      <Win_Go_Modal
        show = {coinModalShow}
        coinNumber = {coinNumber}
        onHide={() => setCoinModalShow(false)}
        background={
          "transparent linear-gradient(180deg, #FFAE09 0%, #FACF04 49%, #EAA202 100%)"
        }
        subHeadingText={"Select  "+coinNumber}
        btnTextColor={"#FFE600"}
      />
    </div>
  );
}

export default Game_Bid;
