import React, { useContext } from "react";
import "./Sidebar.css";
import { Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBlog,
  faBullhorn,
  faDownload,
  faHouse,
  faRightFromBracket,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommonsSa } from "@fortawesome/free-brands-svg-icons";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { Context } from "../../utils/context";
import Cookies from 'js-cookie';

function Sidebar({ handleClose }) {
  const userLanguageId = Cookies.get('language_id');

  const sidebarItems = [
    { icon: faHouse, text: userLanguageId == 2 ? "घर" : "Home", path: "/" },
    { icon: faBullhorn, text: userLanguageId == 2 ? "पदोन्नति" : "Promotion", path: "/promotion" },
    { icon: faWallet, text: userLanguageId == 2 ? "बटुआ" : "Wallet", path: "/wallet" },
    { icon: faCreativeCommonsSa, text: userLanguageId == 2 ? "गतिविधि" : "Activity", path: "/activity" },
    { icon: faBell, text: userLanguageId == 2 ? "अधिसूचना" : "Notification", path: "/notification" },
    { icon: faDownload, text: userLanguageId == 2 ? "हमारे बारे में" : "About Us", path: "/aboutus" },
  ];

  const navigate = useNavigate();
  const { signin, setSignin } = useContext(Context);
  const location = useLocation();

  const handleNavLinkClick = () => {
    handleClose && handleClose();
  };

  const handelclick = () => {
    Cookies.remove("om_winners_security");
    Cookies.remove("userID");
    setSignin(false);
    navigate("/");
  };

  return (
    <div className="Sidebar">
      <Nav>
        {signin ? (
          sidebarItems.map((item, index) => (
            <Nav.Item key={index}>
              <Nav.Link
                as={Link}
                to={item.path}
                onClick={handleNavLinkClick}
                className={`links_nav ${location.pathname === item.path ? "active" : ""}`} >
                <FontAwesomeIcon className="icons" icon={item.icon} />
                {item.text}
              </Nav.Link>
            </Nav.Item>
          ))
        ) : (
          <>
            <Nav.Item key={0}>
              <Nav.Link
                as={Link}
                to={sidebarItems[0].path}
                onClick={handleNavLinkClick}
                className={`links_nav ${location.pathname === sidebarItems[0].path ? "active" : ""}`} >
                <FontAwesomeIcon className="icons" icon={sidebarItems[0].icon} />
                {sidebarItems[0].text}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key={4}>
              <Nav.Link
                as={Link}
                to={sidebarItems[4].path}
                onClick={handleNavLinkClick}
                className={`links_nav ${location.pathname === sidebarItems[4].path ? "active" : ""}`} >
                <FontAwesomeIcon className="icons" icon={sidebarItems[0].icon} />
                {sidebarItems[4].text}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key={5}>
              <Nav.Link
                as={Link}
                to={sidebarItems[5].path}
                onClick={handleNavLinkClick}
                className={`links_nav ${location.pathname === sidebarItems[5].path ? "active" : ""}`} >
                <FontAwesomeIcon className="icons" icon={sidebarItems[0].icon} />
                {sidebarItems[5].text}
              </Nav.Link>
            </Nav.Item>
          </>
        )}

        {signin && (
          <Common_Button
            className={"logout-btn mt-5"}
            onClick={handelclick}
            text={
              <>
                <FontAwesomeIcon className="icons me-3" icon={faRightFromBracket} />
                Logout
              </>
            }
          />
        )}
      </Nav>
    </div>
  );
}

export default Sidebar;
