import React, { useContext, useEffect, useState } from "react";
import "./Total_Commission.css";
import Heading_Holder from "../../Common-Component/Heading_Holder/Heading_Holder";
import Dropdown_Btn from "../../Common-Component/Common_Button/Dropdown_Btn/Dropdown_Btn";
import Promotion_Card from "../../Common-Component/Promotion_Card/Promotion_Card";
import SuccessfullyPopUp from "../../Common-Component/Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";
import { Context } from "../../../utils/context";

function Total_Commission() {
  const [modalShow, setModalShow] = React.useState(false);
  // Array of objects containing props for Dropdown_Btn components
  const dropdownBtnProps = [
    {
      buttonTitle: "Direct Subordinates",
      className: "DropdownButton ",
    },
    {
      buttonTitle: "Team Subordinates",
      className: "DropdownButton DropdownButtonTwo ",
    },
    // Add more objects as needed for additional Dropdown_Btn components
  ];

  const { getData } = useContext(Context);


  const [invitationCode, setInvitationCode] = React.useState(false);
  // Array of objects for Promotion Cards
  const promotionCards = [
    {
      image: "/assets/promotion/copy.png",
      titleText: "Copy Invitation Code",
      getReferCode: async () => {
        const res = await getData(`/getProfile`);
        if (res?.success) {
          const code = res?.data?.udi;
          setInvitationCode(code);

          if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
              .writeText(code)
              .then(() => {
                console.log("Text copied to clipboard:", code);
                setModalShow(true);
              })
              .catch((error) => {
                console.error("Error copying text to clipboard:", error);
                // Fallback to textarea method
                copyToClipboardFallback(code);
              });
          } else {
            // Fallback to textarea method
            copyToClipboardFallback(code);
          }
        }
      },
    },
    {
      image: "/assets/promotion/Layer.png",
      titleText: "Subordinate Data",
      baseLink: "/promotion/subordinate-data",
    },
    {
      image: "/assets/promotion/commision.png",
      titleText: "Commission Detail",
      baseLink: "/promotion/commission-detail",
    },
    {
      image: "/assets/promotion/users.png",
      titleText: "New subordinates",
      baseLink: "/promotion/new-subordinates",
    },
    {
      image: "/assets/promotion/page.png",
      titleText: "Invitation Rules",
      baseLink: "/promotion/invitation-rules",
    },
    {
      image: "/assets/promotion/gentelmen.png",
      titleText: "Rebate Ratio",
      baseLink: "/promotion/rebate-ratio",
    },
  ];
  function copyToClipboardFallback(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';  // Prevent scrolling to bottom
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      document.execCommand('copy');
      console.log('Text copied to clipboard:', text);
      setModalShow(true);
    } catch (error) {
      console.error('Error copying text to clipboard:', error);
    } finally {
      document.body.removeChild(textarea);
    }
  }

  return (
    <div className="Total_Commission">
      <div className="row">
        <div className="col-xl-5 col-lg-5  col-md-5 col-sm-12 mb-md-5 mb-lg-0">
          <div className="Total_Commission_card">
            <Heading_Holder
              headingText={"Yesterday's Total Commission"}
              color={"#F89001"}
              fontSize={"20px"}
            />

            <div className="count-holder">
              <p>0</p>
            </div>

            <div className="subtext-holder">
              <p>Upgrade The level to Increase Commission Income</p>
            </div>

            <div className="invitation_link">
              <p>Invitation Link</p>
            </div>

            <div className="btn-holder desktop-view-btn">
              {dropdownBtnProps.map((data, index) => (
                <div key={index} className="dropdown_btn">
                  <Dropdown_Btn
                    buttonTitle={data.buttonTitle}
                    className={data.className}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="btn-holder  mobile-view-btn">
            {dropdownBtnProps.map((data, index) => (
              <div key={index} className="dropdown_btn">
                <Dropdown_Btn
                  buttonTitle={data.buttonTitle}
                  className={data.className}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="col-xl-7 col-lg-7  col-md-7 col-sm-12">
          <div className="Total_Commission_card scrollable_area">
            <div className="row">
              {promotionCards.map((card, index) => (
                <div key={index} className="col-xl-4 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="card-holder">
                    <Promotion_Card
                      image={card.image}
                      titleText={card.titleText}
                      baseLink={card.baseLink}
                      onClick={card.getReferCode}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <SuccessfullyPopUp
        textHeading={"Invitation Code Copied Successfully!"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Total_Commission;
