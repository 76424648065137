import React, { useContext, useState, useEffect } from "react";
import './Deposit_Main.css'
import BreadcrumComponent from '../../../Common-Component/BreadcrumComponent/BreadcrumComponent'
import AddCash_Options from './AddCash_Options/AddCash_Options';
import SelectChannel from './SelectChannel/SelectChannel';
import Recharge_Instructions from './Recharge_Instructions/Recharge_Instructions';
import { allChanel, allInstruction, walletData } from "../../../../utils/apis/master/Master";
import Cookies from 'js-cookie'


function Deposit_Main() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Wallet", link: "/Wallet" },
    { text: "Deposit", link: "#" },

  ]);

  const [chanel, setChanel] = useState([]);

  const getChanel = async () => {
    const res = await allChanel();
    if (res?.success) {
      setChanel(res?.data)
    }
  }


  const [instruction, setInstruction] = useState([]);

  const userLanguageId = Cookies.get('language_id');
  const getRechargeInstruction = async () => {
    const res = await allInstruction(userLanguageId);
    if (res?.success) {
      setInstruction(res?.data)
    }
  }

  const [selectedCard, setSelectedCard] = useState(null);
 
  const [wallet, setWalletData] = useState();
  const getProjrct = async () => {
    const res = await walletData();
    if (res?.success) {
      setWalletData(res?.data)
    }
  }



  useEffect(() => {
    getChanel();
    getRechargeInstruction();
    getProjrct();
  }, []);


  return (
    <section className='deposit_main_section'>
      <div className='deposit_main'>
        <BreadcrumComponent items={breadcrumbItems} />
        <AddCash_Options wallet={wallet} setWalletData={setWalletData} selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
        <SelectChannel chanel={chanel} getProjrct={getProjrct}/>
        <Recharge_Instructions instruction={instruction} />


      </div>
    </section>
  )
}

export default Deposit_Main