import React, { useContext, useState, useEffect } from "react";
import "./Common_Card.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";

function Common_Card({ imgClassName, imgSource, className, baseLink }) {
  
  const {IMG_URL} = useContext(Context)
  return (
    <>
      <Link to={baseLink}>
        <div className="Common_Card">
          <div className={className}>
            <div className="img-holder">
              <img className={imgClassName} src={IMG_URL + imgSource} />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Common_Card;
