import React from "react";
import "./Nav_Pills_Holder.css";
import Nav from "react-bootstrap/Nav";

function Nav_Pills_Holder({ tabs, className }) {
 
  return (
    <>
      <div className="Nav_Pills_Holder">
        <Nav variant="pills" className="Pills_Holder">
          {tabs.map((tab) => (
            <Nav.Item>
              <Nav.Link className={className} eventKey={tab.eventKey} >
                {tab.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
    </>
  );
}

export default Nav_Pills_Holder;
