import React, { useState,useContext,useEffect } from "react";
import "./ActivityMain.css";
import { Col, Row } from "react-bootstrap";
import First_Deposit_Modal from "../../Common-Component/Common_Modal/First_Deposit_Modal/First_Deposit_Modal";
import Second_Deposit_Modal from "../../Common-Component/Common_Modal/Second_Deposit_Modal/Second_Deposit_Modal";
import Third_Deposit_Modal from "../../Common-Component/Common_Modal/Third_Deposit_Modal/Third_Deposit_Modal";
import { getdepositeData } from "../../../utils/apis/master/Master";


import { Link } from "react-router-dom";
function ActivityMain() {

  const [modalShowFirst, setModalShowFirst] = useState(false);
  const [modalShowSecond, setModalShowSecond] = useState(false);
  const [modalShowThird, setModalShowThird] = useState(false);


  const [depositeFirst,SetDepositeFirst]  = useState([]);
  const [depositeSecond,SetDepositeSecond]  = useState([]);
  const [depositeThird,SetDepositeThird]  = useState([]);

  const depositeData = async () => {
    const res = await getdepositeData();
    if (res?.success) {
      SetDepositeFirst(res?.data?.firsttime);
      SetDepositeSecond(res?.data?.secondtime);
      SetDepositeThird(res?.data?.thirdtime);
    }
  }

  useEffect(() => {
    depositeData();
  }, []);

  return (
    <>
      <section className="activity_section ">
        <div className="activity_main">
          <Row>
            <Col
              xxl={4}
              xl={4}
              lg={12}
              md={12}
              className="order-xxl-none order-xl-none order-lg-1 order-md-2 order-sm-2 order-2"
            >
              <div className="smallcards_div mb-xxl-0 mb-xl-0 mb-lg-3 mb-md-3">
                <Row>
                  <Col xxl={12} xl={12} lg={6} md={6} sm={6}>
                    <Link to={"/profile_gift"}>
                      <div className="smallcardsmain_div">
                        <div className="gift_img_div">
                          <img
                            className="gift_image"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Activity/giftboxes.png"
                            }
                          />
                        </div>
                        <div className="imageoverlayss"></div>
                        <div className="cards_texts">
                          <h6 className="cardstitle">Gifts</h6>
                          <p className="carddstexts">
                            Enter the redemption code to <br />
                            receive gift rewards
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col xxl={12} xl={12} lg={6} md={6} sm={6}>
                    <Link to={"/attendance-bonus"}>
                      <div className="smallcardsmain_div ">
                        <div className="gift_img_div">
                          <img
                            className="gift_image"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Activity/bonuspoint.png"
                            }
                          />
                        </div>
                        <div className="imageoverlayss"></div>
                        <div className="cards_texts">
                          <h6 className="cardstitle">Attendance Bonus</h6>
                          <p className="carddstexts">
                            The more consecutive days you sign in, the higher
                            the reward will be.
                          </p>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xxl={8}
              xl={8}
              lg={12}
              md={12}
              className="order-xxl-none order-xl-none order-md-1 order-sm-1 order-1"
            >
              <div className="bigcards_div mb-xxl-0 mb-xl-0 mb-lg-3 mb-md-3 mb-sm-3 mb-3">
                <div onClick={() => setModalShowFirst(true)}>
                  <div className="big_gift_img_div">
                    <img
                      className="big_gift_image"
                      src={
                        process.env.PUBLIC_URL + "/assets/Activity/giftbox.png"
                      }
                    />
                  </div>
                  {/* <div className="imageoverlayss"></div> */}
                  <div className="bigcards_texts">
                    <h1 className="bigcardstitle">FIRST</h1>
                    <p className="bigcarddstexts">Deposit Bonus</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={12}
              md={12}
              className="order-xxl-none order-xl-none order-md-2 order-sm-2 order-2"
            >
              <div className="bottom_cards_div mb-xxl-0 mb-xl-0 mb-lg-3 mb-md-3">
              <div onClick={() => setModalShowSecond(true)}>
                <div className="bottom_gift_img_div">
                  <img
                    className="bottom_gift_image"
                    src={process.env.PUBLIC_URL + "/assets/Activity/coins.png"}
                  />
                </div>
                <div className="bottom_imageoverlayss"></div>
                <div className="bottomcards_texts">
                  <h1 className="bottomcardstitle">SECOND</h1>
                  <p className="bottomcarddstexts">Deposit Bonus</p>
                </div>
              </div>
              </div>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={12}
              md={12}
              className="order-xxl-none order-xl-none order-md-3 order-sm-3 order-3"
            >
              <div className="bottom_cards_div">
              <div onClick={() => setModalShowThird(true)}>
                <div className="bottom_gift_img_div">
                  <img
                    className="bottom_gift_image"
                    src={process.env.PUBLIC_URL + "/assets/Activity/gift.png"}
                  />
                </div>
                <div className="bottom_imageoverlayss"></div>
                <div className="bottomcards_texts">
                  <h1 className="bottomcardstitle">THIRD</h1>
                  <p className="bottomcarddstexts">Deposit Bonus</p>
                </div>
              </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <First_Deposit_Modal
        depositeFirst={depositeFirst}
        show={modalShowFirst}
        onHide={() => setModalShowFirst(false)}
      />
      <Second_Deposit_Modal
        depositeSecond={depositeSecond}
        show={modalShowSecond}
        onHide={() => setModalShowSecond(false)}
      />
      <Third_Deposit_Modal
        depositeThird={depositeThird}
        show={modalShowThird}
        onHide={() => setModalShowThird(false)}
      />
    </>
  );
}

export default ActivityMain;
