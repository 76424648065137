import React from "react";
import "./Text_Circle_Holder.css";

function Text_Circle_Holder({text}) {
  return (
    <>
      <div className="Text_Circle_Holder">
        <div className="circle-text-holder">
          <p>{text}</p>
        </div>
      </div>
    </>
  );
}

export default Text_Circle_Holder;
