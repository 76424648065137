import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Total_Commission from './Total_Commission/Total_Commission'
import Promotion_Data from './Promotion_Data/Promotion_Data'

function Promotion() {
  return (
    <>
    <section>
    <div className="row me-0 ms-0">
    <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
          <div className="sidebar_holder">
            <Sidebar />
          </div>
        </div>

        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
          <div className="main-content-holder">
          <Total_Commission/>
          <Promotion_Data/>
          </div>
        </div>
      </div>

    </section>
    </>
  )
}

export default Promotion