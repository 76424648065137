import React, { useState } from "react";
import "./Commission_Detail.css";
import Sidebar from "../../Sidebar/Sidebar";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { Form } from "react-bootstrap";
import Choose_Date_Modal from "../../Common-Component/Common_Modal/Choose_Date_Modal/Choose_Date_Modal";

function Commission_Detail() {
  const [modalShow, setModalShow] = React.useState(false);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Promotion", link: "/promotion" },
    { text: "Commission Detail" },
  ]);

  return (
    <>
      <section className="Invitation_Rules">
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              <BreadcrumComponent items={breadcrumbItems} />

              <div className="Commission_Detail-calender-field-holder">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                    <div
                      className="Commission_Detail-calender-field "
                      onClick={() => setModalShow(true)}
                    >
                      <Form>
                        <Form.Group className="mb-3">
                          <Form.Control id="" placeholder="Select Date" />
                        
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Choose_Date_Modal
        headingName={"Choose a Date"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Commission_Detail;
