import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const emailRegister = async (data) => {
  try {
    const res = await postData(`/emailRegister`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const mobileRegister = async (data) => {
  try {
    const res = await postData(`/mobileRegister`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postGame = async (data) => {
  try {
    const res = await postData(`/game`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};


export const changePassword = async (data) => {
  try {
    const res = await postData(`/changePassword`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};