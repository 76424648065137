import React from "react";
import { Modal } from "react-bootstrap";
import "./How_To_Play_Modal.css";

function How_To_Play_Modal(props, { }) {
  return (
    <>
      <div className="How_To_Play_Modal_sec">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="How_To_Play_Modal"
        >
          <Modal.Header closeButton >
            <Modal.Title id="contained-modal-title-vcenter" ></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-holder mx-auto">
              <ol>
                {props.howToPlay?.map((data) => (
                  <li>
                    {data?.how_to_play_language?.name}
                  </li>
                ))}
                {/* 
                <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur, officiis exercitationem. Cum, magnam numquam
                  officia rerum earum ipsam porro laborum molestias suscipit.
                </li>

                <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur, officiis exercitationem. Cum, magnam numquam
                  officia rerum earum ipsam porro laborum molestias suscipit.
                </li>

                <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur, officiis exercitationem. Cum, magnam numquam
                  officia rerum earum ipsam porro laborum molestias suscipit.
                </li> */}
              </ol>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default How_To_Play_Modal;
