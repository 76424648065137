import React, { useContext, useState, useEffect } from "react";
import Sidebar from '../Sidebar/Sidebar'
import Annoncement from './Annoncement/Annoncement'
import { notificationGet } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
import Cookies from 'js-cookie'

function Notification() {

  const userLanguageId = Cookies.get('language_id');
  const [notification, setNotification] = useState([]);

  const getNotification = async () => {
    const res = await notificationGet(userLanguageId);
    if (res?.success) {
      setNotification(res?.data)
    }
  }

  useEffect(() => {
    getNotification();
  }, []);


  return (
    <>
      <section>
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              {/* insert your promotion componants */}
              <Annoncement notification={notification} userLanguageId={userLanguageId} />
            </div>
          </div>
        </div>

      </section>
    </>
  );
}

export default Notification;
