import React from "react";
import "./Promotion_Data.css";
import Heading_Holder from "../../Common-Component/Heading_Holder/Heading_Holder";

function Promotion_Data() {
  const data = [
    { text: "This Week", count: 0, className: "borderline" },
    { text: "Total Commision", count: 0, className: "borderline" },
    { text: "Direct Subordinate", count: 0, className: "borderline" },
    { text: "Total Number of Subordinates in the team", count: 0 },
  ];

  return (
    <>
      <section className="Promotion_Data">
        <Heading_Holder headingText={"Promotion Data"} />

        <div className="data-holder">
          <div className="row">
            {/* Map over the data array and render each item */}
            {data.map((item, index) => (
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mb-md-5 col-lg-0" key={index}>
                <div className={item.className}>
                  <div className="data-content-holder">
                    <div className="count-holder">
                      <p>{item.count}</p>
                    </div>
                    <div className="subtext-holder">
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Promotion_Data;
