import React, { useState, useEffect } from "react";
import "./Circle_Cownt_Down_Modal.css";
import { Modal } from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";

function Circle_Cownt_Down_Modal({ show, onHide, MP3, smsText, seconds }) {
  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(10);

  // useEffect(() => {
  //   // Reset timer when modal is shown
  //   if (show) {
  //     setMinutes(0);
  //     setSeconds(10);
  //   }
  // }, [show]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     } else {
  //       if (minutes === 0) {
  //         clearInterval(intervalId);
  //         // Optionally trigger any action on timer expiration
  //       } else {
  //         setMinutes(minutes - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 1000);

  // Cleanup function to clear interval when component unmounts or when modal is hidden
  //   return () => clearInterval(intervalId);
  // }, [minutes, seconds, show]);

  // // Format the timer values with leading zeros if necessary
  // const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  // const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  // console.log("formattedSecondsformattedSeconds", formattedSeconds);

  return (
    <>
      <section className="popup_section">
        <Modal
          show={show}
          onHide={onHide}
          className="Successfull_Modal Circle_Cownt_Down_Modal"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="timer-holder">
              <span>{seconds}</span>
            </div>
          </Modal.Body>
          <ReactAudioPlayer
            src={MP3}
            autoPlay // or autoplay, depending on your preference
            controls
            className="play" // You can add your CSS class here
            type="audio/mp3" // Set the type of your audio file
            style={{ display: 'none' }} // Set the display property to 'none' to hide the player
          />
        </Modal>
      </section>
    </>
  );
}

export default Circle_Cownt_Down_Modal;
