import React, { useContext, useEffect, useState } from "react";
import "./GameHistoryTable.css";
import { Pagination, Table } from "react-bootstrap";
import Simple_Box from "../../../../Common-Component/Simple_Box/Simple_Box";
import ReactFlow, { Background, MarkerType } from "reactflow";
import { Context } from "../../../../../utils/context";
import { gameHistory } from "../../../../../utils/apis/master/Master";

// Import Swiper styles
import "swiper/css";

function GameHistoryTable() {
  
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [pageLinks, setPageLinks] = useState([]);
  const [page, setPage] = useState();
  const { getGameHistory, setGameHistory } = useContext(Context);

  const getProject = async (page) => {
    const res = await gameHistory(page);
    if (res?.success) {
      setGameHistory(res?.data?.data);      
      setPageLinks(res?.data?.links);
    }
  }


  useEffect(() => {
      getProject(page);
  }, [page]);

  return (
    <>
      <div className="GameHistoryTable">
        <div className="table-data-holder">
          <div className="table-responsive">
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Period"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Big/ Small"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Number"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Color"} />
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {getGameHistory?.map((slide, index) => (
                  <tr>
                    <td>
                      <Simple_Box
                        className={"Simple_Box grey_Box"}
                        text={slide?.period}
                      />
                    </td>
                    <td>
                      <Simple_Box
                        className={"Simple_Box grey_Box"}
                        text={slide?.size}
                        background={"#F5F5F5"}
                      />
                    </td>
                    <td>
                      <Simple_Box className={"Simple_Box grey_Box"} text={slide?.number} />
                    </td>
                    <td>
                      <Simple_Box
                        className={
                          slide?.color === "Red" ? "Simple_Box red_Box" :
                            slide?.color === "Violet" ? "Simple_Box voilet_Box" :
                              slide?.color === "Green" ? "Simple_Box green_Box" :
                                " "
                        }
                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="pagination-design">

     
       <Pagination>
          {pageLinks?.map((val) => (
            <>
              {val?.label === "&laquo; Previous" && (
                <button
                  className="ms-3 previous"
                  onClick={() => {
                    setPage(val?.url?.split("?")[1]);
                  }}
                >
                 {htmlToReactParser.parse(val?.label)}
                </button>
              )}

              {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                <button
                  className={val?.active ? "ms-3 active number" : "ms-3 number"}
                  onClick={() => {
                    setPage(val?.url?.split("?")[1]);
                  }}
                >
                 {htmlToReactParser.parse(val?.label)}
                </button>
              )}

              {val?.label === "Next &raquo;" && (
                <button
                  className="ms-3 next"
                  onClick={() => {
                    setPage(val?.url?.split("?")[1]);
                  }}
                >
                 {htmlToReactParser.parse(val?.label)}
                </button>
              )}
            </>
          ))}
        </Pagination>

        {/* <Pagination>
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Next />
        </Pagination> */}
      </div>
    </>
  );
}

export default GameHistoryTable;
