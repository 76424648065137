import React from "react";
import "./Winner_Card.css";

function Winner_Card({
  medalImgClassName,
  winnerImgClassName,
  amountText,
  rupeeImgSource,
  winnerImgSource,
  winnerName,
  medalImgSource
}) {
  return (
    <>
      <div className="Winner_Card ">
        <div className="amount_holder">
          <div className="rupee-img-holder">
            <img
              className="rupee_img"
              src={process.env.PUBLIC_URL + rupeeImgSource}
              alt="rupee_img"
            />
          </div>

          <div className="amount_text_holder">
            <h4>{amountText}</h4>
          </div>
        </div>

        <div className="winner-img-holder">
          <img
            className={winnerImgClassName}
            src={process.env.PUBLIC_URL + winnerImgSource}
            alt="winner_img"
          />
        </div>

        <div className="winner_name_holder">
          <h4>{winnerName}</h4>
        </div>

        <div className="winner-medal-holder">
          <img
            className={medalImgClassName}
            src={process.env.PUBLIC_URL + medalImgSource}
            alt="medal_img"
          />
        </div>
      </div>
    </>
  );
}

export default Winner_Card;
