import React from "react";
import "./Dropdown_Btn.css";
import { DropdownButton } from "react-bootstrap";

function Dropdown_Btn({ buttonTitle, className }) {
  const dropdownContent = [
    { title: "Number Of Register", value: 0 },
    { title: "Deposit Number", value: 0 },
    { title: "Deposit Amount", value: 0 },
    { title: "Number of People Making First Deposit", value: 0 },
  ];

  return (
    <div className="Dropdown_Btn">
      <DropdownButton
        id="dropdown-basic-button"
        title={buttonTitle}
        className={className}
      >
        <div className="dropdown_content ">
          {dropdownContent.map((item, index) => (
            <div key={index} className="text-holder">
              <p>{item.title}</p>
              <p>{item.value}</p>
            </div>
          ))}
        </div>
      </DropdownButton>
    </div>
  );
}

export default Dropdown_Btn;
