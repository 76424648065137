import React, {} from 'react'
import './CallCenterLottey.css'
import Lottie from 'react-lottie';
import * as animationData from './call-center.json';
const CallCenterLottey = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='main'>
                <div className='call-center'>
                    <Lottie className="mx-auto"
                        options={defaultOptions}

                    />
                </div>
            </section>
        </>
    )
}

export default CallCenterLottey