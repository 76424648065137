import React,{useState,useContext,useEffect} from 'react'
import Sidebar from '../../../../Sidebar/Sidebar'
import Game_Rules from './Game_Rules/Game_Rules'
import { gameRuleGet, getAttendance } from "../../../../../utils/apis/master/Master";
import Cookies from 'js-cookie'

function Game_Rules_main() {

    const userLanguageId = Cookies.get('language_id');
    const [gameRule, setGameRule] = useState([]);
  
    const getGameRule = async () => {
      const res = await gameRuleGet(userLanguageId);
      if (res?.success) {
        setGameRule(res?.data)
      }
    }

    const [getAttendanceData, setgetAttendanceData] = useState([]);
  
    const getgetAttendance = async () => {
      const res = await getAttendance();
      if (res?.success) {
        setgetAttendanceData(res?.data)
      }
    }

  
    useEffect(() => {
      getGameRule();
      getgetAttendance();
    }, []);

    
  return (
    <section>
            <div className="row me-0 ms-0">
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
                    <div className="sidebar_holder">
                        <Sidebar/>
                    </div>
                </div>

                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
                    <div className="main-content-holder">
                        {/* insert your promotion componants */}
                        <Game_Rules gameRule={gameRule} getAttendanceData={getAttendanceData}/>
                    </div>
                </div>
            </div>

        </section>
  )
}

export default Game_Rules_main