import React from "react";
import "./First_Deposit_Modal.css";
import { Modal } from "react-bootstrap";
import Heading_Holder from "../../Heading_Holder/Heading_Holder";
import Common_Button from "../../Common_Button/Common_Button";
import { Link } from "react-router-dom";

function First_Deposit_Modal(props) {
  // Define array of card content
  const cards = [
    {
      title: "First deposit",
      amount: "20000",
      bonus: "+₹10,00000",
      description:
        "Deposit 20000 for the first time and you will receive 10000 bonus",
    },

    {
      title: "First deposit",
      amount: "20000",
      bonus: "+₹10,00000",
      description:
        "Deposit 20000 for the first time and you will receive 10000 bonus",
    },

    {
      title: "First deposit",
      amount: "20000",
      bonus: "+₹10,00000",
      description:
        "Deposit 20000 for the first time and you will receive 10000 bonus",
    },

    {
      title: "First deposit",
      amount: "20000",
      bonus: "+₹10,00000",
      description:
        "Deposit 20000 for the first time and you will receive 10000 bonus",
    },

    {
      title: "First deposit",
      amount: "20000",
      bonus: "+₹10,00000",
      description:
        "Deposit 20000 for the first time and you will receive 10000 bonus",
    },

    {
      title: "First deposit",
      amount: "20000",
      bonus: "+₹10,00000",
      description:
        "Deposit 20000 for the first time and you will receive 10000 bonus",
    },
  ];


  return (
    <>
      <div className="First_Deposit_Modal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Notice_Modal First_Deposit_Modal"
        >
           <Link to={"/activity"}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
          </Link>
          <Modal.Body>
            <Heading_Holder
              headingText={
                <>
                  Extra First Deposit Bonus
                  <p className="subheading ">
                    Each account can only Receive Rewards once
                  </p>
                </>
              }
            />
            <div className="Deposit_Bonus_card_holder mx-auto">
              <div className="row justify-content-center me-0 ms-0">
                {props?.depositeFirst?.map((card, index) => (
                  <div className="col-xxl-10 col-xl-10 col-lg-10">
                    <div className="Deposit_Bonus_card card">
                      <div className="heading-text-holder card-header">
                        <div className="heading-holder">
                          <h4>
                            First Deposite <span>{card?.depositamount?.value}</span>
                          </h4>
                        </div>
                        <div className="subheading-holder">
                          <p>+₹{card?.bonus}</p>
                        </div>
                      </div>

                      <div className="content-text-holder card-body">
                        {/* <p>{card.description}</p> */}
                        Deposit {card?.depositamount?.value} for the first time and you will receive {card?.bonus} bonus
                      </div>

                      <div className="card-btn-holder card-footer">
                        <div className="out_off_score">
                          <span>0/{card?.depositamount?.value}</span>
                        </div>
                        <div className="deposit-btn">
                          <Link to={"/wallet/deposit"}>
                            <Common_Button
                              className={"SubmitBtn"}
                              text={"Deposit"}
                              background={"#fff"}
                              border={"1px solid #f89001"}
                              color={"#000"}
                              font-weight={"500"}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <span>No more reminders today</span>
            <Common_Button className={"SubmitBtn"} text={"Activity"} borderRadius={"50px"} onClick={props.onHide} />

          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default First_Deposit_Modal;
