import React, { useState } from "react";
import "./Annoncement.css";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons/faCalendarDays";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
function Annoncement({ notification, userLanguageId }) {
  const announce = [
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      date: "13 Jan 24",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      date: "13 Jan 24",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      date: "13 Jan 24",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      date: "13 Jan 24",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      date: "13 Jan 24",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      date: "13 Jan 24",
    },
  ];

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    {
      text: (
        <>
          <FontAwesomeIcon icon={faArrowLeft} />
        </>
      ),
      link: "/",
    },
  ]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  }

  return (
    <section className="announce_section">
      <BreadcrumComponent items={breadcrumbItems} />
      <Row>
        {notification?.map((item, index) => (
          <Col xxl={4} xl={4} lg={6} md={6} sm={6} className="mb-4">
            <div className="Announce_main">
              <div className="announce_title_div">
                <h5 className="announce_title">{userLanguageId == 2 ? "घोषणा" : "Announcement"}</h5>
              </div>

              <div className="announce_text_div">
                <p className="announce_text">{item?.announcement_language?.name}</p>
              </div>
              <div className="Annnounce_date">
                <p className="datess">
                  <FontAwesomeIcon icon={faCalendarDays} className="me-1" /> {formatDate(item?.updated_at)}
                </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default Annoncement;
