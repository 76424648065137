import React, { useState, useContext, useEffect } from 'react'
import './ProfilecustomerService.css'
import BreadcrumComponent from '../../../Common-Component/BreadcrumComponent/BreadcrumComponent';
import { Col, Row } from 'react-bootstrap';
import CallCenterLottey from '../../../Animation/CallCenterLottey/CallCenterLottey';
import { Context } from '../../../../utils/context';
import { customerService } from '../../../../utils/apis/master/Master';
const ProfileServices = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Profile", link: "/profile" },
        { text: "Settings", link: "/profile-setting" },
        { text: "Contact Customer Service", link: "" },
    ]);
    const [getCustomerService, setCustomerService] = useState();

    const getUserProfile = async () => {
        const res = await customerService();
        if (res?.success) {
            setCustomerService(res?.data);
        }
    };
    useEffect(() => {
        getUserProfile();
    }, []);
    return (
        <>
            <section className='profile-customer-services'>

                <BreadcrumComponent items={breadcrumbItems} />

                <div className='banner-customer mt-5'>
                    <Row >
                    <Col xxl={10} xl={9} lg={8} md={8} sm={8} xs={6}>
                            <div className='text-holder'>
                                <h2>Exceptional Customer Service</h2>
                                <p>Your Satisfaction, Our Priority {getCustomerService?.number}</p>
                            </div>
                        </Col>
                        <Col  xxl={2} xl={3} lg={4} md={4} sm={4} xs={6}>
                            <a >
                                <div className='lottey-box text-center'>
                                    <CallCenterLottey />
                                    <p >Self Service<br /> Center</p>
                                </div>

                            </a>
                        </Col>
                    </Row>
                </div>

            </section>
        </>
    )
}

export default ProfileServices