import React from "react";
import "./Winner_ScoreCard_Card.css";

function Winner_ScoreCard_Card({
  playerImgSource,
  playerName,
  rupeeImgSource,
  amountText,
  scoreText
}) {
  return (
    <>
      <div className="Winner_ScoreCard_Card">
        <div className="score-img-text-holder ">
          <div className="img-text-holder">
            <div className="img-holder">
              <img
                className="player_img"
                src={process.env.PUBLIC_URL + playerImgSource}
                alt="player_img"
              />
            </div>
            <div className="text-holder">
              <h4>{playerName}</h4>
            </div>
          </div>
          <div className="score_number_holder">
            <p>{scoreText}</p>
          </div>
        </div>

        <div className="amount_holder">
          <div className="rupee-img-holder">
            <img
              className="rupee_img"
              src={process.env.PUBLIC_URL + rupeeImgSource}
              alt="rupee_img"
            />
          </div>

          <div className="amount_text_holder">
            <h4>{amountText}</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Winner_ScoreCard_Card;
