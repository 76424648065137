import React, { useState } from 'react'
import "./LoginModal.css"
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import Common_Button from '../../Common_Button/Common_Button';
const BindModal = (props) => {
    const { headingName } = props;
    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);

    const handleChange = (index, value) => {
        const newVerificationCode = [...verificationCode];
        newVerificationCode[index] = value;
        setVerificationCode(newVerificationCode);
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && verificationCode[index] === '') {
            // Move focus to previous input field on backspace if current field is empty
            if (index > 0) {
                document.getElementById(`otp-${index - 1}`).focus();
            }
        } else if (e.key.match(/[0-9]/) && index < 5) {
            // Move focus to next input field on digit input if current field is filled
            if (verificationCode[index] !== '') {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        }
    };


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='all-setting-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-secc'>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Mail</Form.Label>
                            <Form.Control type="email" placeholder="Enter Your mail" />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Verification Code</Form.Label>
                            <div className='d-flex'>
                            {verificationCode.map((digit, index) => (
                                <Form.Control
                                    key={index}
                                    id={`otp-${index}`}
                                    type="text"
                                    placeholder=""
                                    className='otp-text'
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                />
                            ))}
                            </div>
                        </Form.Group>
                        <div className='button-holder mt-5 text-center'>
                            <Common_Button className={'save-btn'} text={'Bind'} onClick={props.onHide}></Common_Button>
                        </div>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default BindModal