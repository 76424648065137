import "./Header.css";
import React, { useState, useEffect, useContext } from "react";
import { Dropdown, DropdownButton, Nav, Navbar, Offcanvas, } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Win_Go_Modal from "../Common-Component/Common_Modal/Win_Go_Modal/Win_Go_Modal";
import Sidebar from "../Sidebar/Sidebar";
import { Context } from "../../utils/context";

function Header() {
  const { signin } = useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { IMG_URL, getData } = useContext(Context);

  const [profile, setProfile] = useState();
  const getUserProfile = async () => {
    const res = await getData(`/getProfile`);
    if (res?.success) {
      setProfile(res?.data?.image);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <>
      <div className="Header ">
        <div className="container-fluid ">
          <Navbar expand={false} className="">
            <div className="toggle-logo">
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                // onClick={() => setModalShow(true)}
                onClick={handleShow}
              />
              <Navbar.Brand className="header-img">
                <Link to={"/"}>
                  <img
                    className="header-logo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Header/Header_logo/winner_logo.png"
                    }
                    alt="Logo"
                  />
                </Link>
              </Navbar.Brand>
            </div>
            {/* mobile view sidebar start*/}
            {windowWidth <= 991 && (
              <div className="mobile-view-offcanvas-holder">
                <Offcanvas
                  className="mobile-view-offcanvas"
                  show={show}
                  onHide={handleClose}
                >
                  <Offcanvas.Body>
                    <Sidebar handleClose={handleClose} />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            )}
            {/* mobile view sidebar end*/}

            <div className="nav-btn-holder">
              {signin == false ? (
                <>
                  {/* <Common_Button className="SubmitBtn" text="Login" /> */}
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Login"
                    className="dropmenulist dropmenu1"
                  >
                    <Link to={"/login-with-phone"}>
                      <Dropdown.Item
                        href="/login-with-phone"
                        className="loginbutonlist"
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="cell_icon"
                          icon={faMobileScreen}
                        />
                        Login With Phone
                      </Dropdown.Item>
                    </Link>
                    <Link to={"/login-with-email"}>
                      <Dropdown.Item
                        href="/login-with-email"
                        className="loginbutonlist"
                      >
                        {" "}
                        <FontAwesomeIcon className="cell_icon" icon={faEnvelope} />
                        Login With Email
                      </Dropdown.Item>
                    </Link>
                  </DropdownButton>

                  {/* <Common_Button
                  className="SubmitBtn"
                  background={"#fff"}
                  border={"1px solid #F89001"}
                  padding={"5px 15px"}
                  margin={"0px 0px 0px 15px"}
                  color={"#000"}
                  text="Register"
                /> */}
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Register"
                    className="dropmenulist dropmenu2"
                  >
                    <Link to={"/register-with-phone"}>
                      <Dropdown.Item
                        href="/register-with-phone"
                        className="loginbutonlist"
                      >
                        <FontAwesomeIcon
                          className="cell_icon"
                          icon={faMobileScreen}
                        />{" "}
                        Register With Phone
                      </Dropdown.Item>
                    </Link>
                    <Link to={"/register-with-email"}>
                      <Dropdown.Item
                        href="/register-with-email"
                        className="loginbutonlist"
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="cell_icon"
                          icon={faEnvelope}
                        />{" "}
                        Register With Email
                      </Dropdown.Item>
                    </Link>
                  </DropdownButton>

                </>
              ) : (
                <div className="profile-logo">
                  <Link to="/profile">
                    {profile ? (
                      <img
                        className="profile-icon"
                        src={IMG_URL + profile}
                        alt={
                          "logo"
                        }
                      />
                    ) : (
                      <img
                        className="profile-icon"
                        src={process.env.PUBLIC_URL +
                          "/assets/Header/Header_logo/user-circle.png"}
                        alt={
                          "logo"
                        }
                      />
                      )}
                  </Link>
                </div>)}
            </div>
          </Navbar>
        </div>
      </div>

      <Win_Go_Modal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Header;
