import React, { useState, useEffect, useContext, useRef } from "react";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { Col, Dropdown, DropdownButton, Nav, Row, Tab } from "react-bootstrap";
import Calendar from "react-calendar";
import Gift_Img_Holder from "../../../Common-Component/Gift_Img_Holder/Gift_Img_Holder";
import { Link } from "react-router-dom";
import { myGameHistory } from "../../../../utils/apis/master/Master";
import Wallet_Withdraw_Card from "../../../Common-Component/Wallet_Withdraw_Card/Wallet_Withdraw_Card";
import { Context } from "../../../../utils/context";
import { format } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import { useForm } from 'react-hook-form';
import { myHistory } from "../../../../utils/apis/master/Master";

const GameHistoryMain = () => {

  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Wallet", link: "/wallet" },
    { text: "Game history", link: "#" },
  ]);


  
  const { register: registerDropdown, handleSubmit: handleSubmitDropdown, setValue: setValueDropdown } = useForm();
  const { register: registerCalendar, handleSubmit: handleSubmitCalendar, setValue: setValueCalendar } = useForm();

  const [activeItem, setActiveItem] = useState("");
  const [wallethistory, setWalletHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");


  const dropdownRef = useRef(null);

  const handleItemClick = (item) => () => {
    setActiveItem(item);
    setValueDropdown('type', item);
    getProject(item);
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValueCalendar('selectedDate', date);
    getProject(activeItem,format(date, 'yyyy-MM-dd'));    
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const onSubmitDropdown = (data) => {
    const selectedType = data.type;
    getProject(selectedType);
  };

  const onSubmitCalendar = (data) => {
    const selectedDateFormatted = format(data.selectedDate, 'yyyy-MM-dd');
    getProject(activeItem,selectedDateFormatted);
  };

  useEffect(() => {
    getProject();
  }, []);

  // const { getMyGameHistory, setMyGameHistory, getData } = useContext(Context);
  const [getMyGameHistory, setGameHistory] = useState();
  const getProject = async (selectedType, selectedDate) => {
    const res = await myHistory(selectedType, selectedDate);
    if (res?.success) {
      setGameHistory(res?.data);
    }
  }

  useEffect(() => {
    getProject();
  }, []);


  return (
    <>
       <section className="withdraw_history_section">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="withdraw_history_main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="history_tabs_top mb-4">
              <Nav variant="pills">
                <Nav.Item className="me-md-4 me-sm-2 me-2 mb-2">
                  <Nav.Link eventKey="first">
                    <FontAwesomeIcon icon={faGrip} className="me-2" /> All
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="dropdown_div mb-2">
                <form onSubmit={handleSubmitDropdown(onSubmitDropdown)}>
                  <DropdownButton id="dropdown-basic-button" title="All" className="me-4 mb-2">
                    {/* <div className={`dropdown-item ${activeItem === "Processing" ? "active" : ""}`}>
                      <div className="drop_menu" onClick={handleItemClick("Processing")}>Processing</div>
                    </div> */}
                    <div className={`dropdown-item ${activeItem === "Failed" ? "active" : ""}`}>
                      <div className="drop_menu" onClick={handleItemClick("Failed")}>Failed</div>
                    </div>
                    <div className={`dropdown-item ${activeItem === "Succeed" ? "active" : ""}`}>
                      <div className="drop_menu" onClick={handleItemClick("Succeed")}>Succeed</div>
                    </div>
                    <input type="hidden" {...registerDropdown('type')} value={activeItem} />
                    {/* <button type="submit" className="savebutton">
                      <div className="drop_menu">save</div>
                    </button> */}
                  </DropdownButton>
                </form>
                <form onSubmit={handleSubmitCalendar(onSubmitCalendar)}>
                  <DropdownButton id="dropdown-basic-button" title="Date" className="mb-2" ref={dropdownRef}>
                    <div className="mb-2">
                      <Calendar
                        onChange={handleDateChange}
                        value={selectedDate}
                        maxDate={new Date()}
                      />
                    </div>
                    {/* <Dropdown.Item as="button" type="submit" className="savebutton">
                      <div className="drop_menu">save</div>
                    </Dropdown.Item> */}
                  </DropdownButton>
                  <input type="hidden" {...registerCalendar('selectedDate')} value={selectedDate} />
                </form>
              </div>
            </div>
            <Tab.Content>
              <div className="history_tab_div">
                <Row>
                  {getMyGameHistory?.map((data, index) => (
                    <Col xxl={4} xl={4} lg={6} md={6} key={index}>
                      <div className="withdraw_card_div mb-3">
                        <Wallet_Withdraw_Card
                          deposit={data?.result}
                          status={data?.transaction_status}
                          type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"}
                          balance={data?.amount}
                          data={data?.created_at}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Tab.Content>
          </Tab.Container>
          {getMyGameHistory === null && (
            <div className="image-holder">
              <Gift_Img_Holder />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default GameHistoryMain;
