import React, { useState } from "react";
import "./New_Subordinates.css";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import Sidebar from "../../Sidebar/Sidebar";
import { Nav, Tab } from "react-bootstrap";
import Nav_Pills_Holder from "../../Common-Component/Nav_Pills_Holder/Nav_Pills_Holder";
import Gift_Img_Holder from "../../Common-Component/Gift_Img_Holder/Gift_Img_Holder"

function New_Subordinates() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Promotion", link: "/promotion" },
    { text: "New Subordinates" },
  ]);

  const ShopTrendsPiils = [
    { eventKey: "all", title: "All" },
    { eventKey: "yesterday", title: "Yesterday" },
    { eventKey: "month", title: "This Month" },
  ];
  return (
    <>
      <section className="New_Subordinates">
        <div className="row me-0 ms-0">
        <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              <BreadcrumComponent items={breadcrumbItems} />

              <div className="row">
                <div className="col-md-12">
                  <div className="Game_Info_Tabs">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all"
                    >
                      <div className="nav-tabs-holder">
                        <Nav_Pills_Holder tabs={ShopTrendsPiils} />
                      </div>
                      <div className="content-holder">
                        <Tab.Content>
                          <Tab.Pane eventKey="all">
                            <Gift_Img_Holder />
                          </Tab.Pane>

                          <Tab.Pane eventKey="yesterday">
                            <Gift_Img_Holder />
                          </Tab.Pane>

                          <Tab.Pane eventKey="month">
                            <Gift_Img_Holder />
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default New_Subordinates;
