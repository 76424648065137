import React, { useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import BreadcrumComponent from '../../Common-Component/BreadcrumComponent/BreadcrumComponent';
import Profile_Notification_Main from './Profile_Notification_Main/Profile_Notification_Main';

function Profile_Notification() {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Profile", link: "/profile" },
        { text: "Notification", link: "#" },


    ]);
    return (
        <section>
            <div className="row me-0 ms-0">
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
                    <div className="sidebar_holder">
                        <Sidebar />
                    </div>
                </div>

                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
                    <div className="main-content-holder">
                        {/* insert your promotion componants */}
                        <BreadcrumComponent items={breadcrumbItems} />
                        <Profile_Notification_Main />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Profile_Notification