/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from "react";
import "./Win_Go_Modal.css";
import { Modal, ModalFooter } from "react-bootstrap";
import Heading_Holder from "../../Heading_Holder/Heading_Holder";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Circle_Text_Holder from "../../Circle_Text_Holder/Circle_Text_Holder";
import Common_Button from "../../Common_Button/Common_Button";
import SuccessfullyPopUp from "../../Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";
import Congratulations_Modal from "../Congratulations_Modal/Congratulations_Modal";
import Sorry_Modal from "../Sorry_Modal/Sorry_Modal";
import { Context } from "../../../../utils/context";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import {
  gamePost,
  myGameHistory,
  walletData,
} from "../../../../utils/apis/master/Master";
import Without_Login_PopUp from "../Without_Login_PopUp/Without_Login_PopUp";

function Win_Go_Modal({
  show,
  onHide,
  subHeadingText,
  background,
  borderRadius,
  border,
  fontSize,
  fontWeight,
  color,
  padding,
  margin,
  className,
  textAlgin,
  btnTextColor,
  width,
  coinNumber,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [modalWithoutLoginShow, setModalWithoutLoginShow] = useState(false);
  const [modalCongratsShow, setCongratsModalShow] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();
  const [count, setCount] = useState(1);
  const modalStyle = {
    background,
    borderRadius,
    border,
    fontSize,
    fontWeight,
    color,
    padding,
    margin,
    className,
    textAlgin,
    width,
  };

  const {
    setSelectedColor,
    selectedColor,
    setSelectedSize,
    signin,
    selectedSize,
    setGameHistory,
    getGameHistory,
    setMultiply,
    multiply,
    activeIndex,
    setActiveIndex,
    setWalletData,
  } = useContext(Context);
  const [cupError, setCupError] = useState("");
  const [code, setCode] = useState(1);
  // const [multiply, setMultiply] = useState();
  const handleChange = async (e) => {
    setCode(e.target.value);
  };

  function increment() {
    //setCount(prevCount => prevCount+=1);
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function decrement() {
    setCount(function (prevCount) {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
  }

  const [isChecked, setIsChecked] = useState(false);
  const [checkError, setCheckError] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleLogin = async () => {
    if (signin) {
      if (isChecked) {
        const number = coinNumber;
        const color = selectedColor;
        const size = selectedSize;
        const amount = count * code * multiply;
        const res = await gamePost({ color, amount, number, size });
        if (res?.success) {
          setSelectedColor("");
          setSelectedSize("");
          setModalShow(true);
          setIsChecked(false);
          getProject();
          getWallet();
          onHide();
          setTimeout(() => {
            setModalShow(false);
          }, 3000);
        } else {
          setCupError(res?.response?.data?.data);
        }
      } else {
        setCheckError("Please Accepting Privacy Agreement");
      }
    } else {
      setModalWithoutLoginShow(true); // Show the modal instead of alert
    }
  };

  const getProject = async () => {
    const res = await myGameHistory();
    if (res?.success) {
      setGameHistory(res?.data?.data);
    }
  };

  const getWallet = async () => {
    const res = await walletData();
    if (res?.success) {
      setWalletData(res?.data);
    }
  };

  return (
    <>
      <section className="Win_Go_Modal_section">
        <Modal
          show={show}
          onHide={onHide}
          className="Win_Go_Modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={modalStyle}
        >
          <div className="modal-content" style={modalStyle}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="main_heading_holder">
                <Heading_Holder headingText={"Om Winners"} />
              </div>

              <div className="main_heading_holder">
                <Heading_Holder
                  headingText={subHeadingText}
                  textDecoration={"underline"}
                />
              </div>

              <div className="form_content-holder">
                <div className="row">
                  <div className="col-md-8">
                    <div className="overlay_content_holder">
                      <div className="form_field_content">
                        <h4 className="form_label_heading">Amount</h4>
                        <div className="balance_holder">
                          <Form.Control
                            type="number"
                            name="code"
                            id="code"
                            placeholder="Amount"
                            value={code}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="form_field_content">
                        <h4 className="form_label_heading">Quantity</h4>
                        <div className="incre_decre_btn_holder">
                          <button
                            className="incre_decre_btn"
                            onClick={decrement}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <h1>{count}</h1>
                          <button
                            className="incre_decre_btn"
                            onClick={increment}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>

                        <div className="balance_holder balance_holder-scroller">
                          <Circle_Text_Holder
                            setMultiply={setMultiply}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                          />
                        </div>
                      </div>

                      <div className="checkbox_div">
                        {/* <Form> */}
                        <Form.Check
                          type="checkbox"
                          label="I Agree"
                          onChange={handleCheckboxChange}
                        />
                        {/* </Form> */}

                        <span style={{ color: "black" }}>{checkError}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="img-holder">
                      <img
                        className="game_kit-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Home/Om_Winner_Info/om_winner_laptop.png"
                        }
                        alt="site-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <span style={{ color: "black", fontSize: 30 }}>{cupError}</span>
            <Modal.Footer>
              <div className="footer_btn_holder">
                <Common_Button
                  className={"SubmitBtn"}
                  text={
                    <>
                      Total Amount :{" "}
                      <div className="amount_holder">
                        <div className="rupee-img-holder">
                          <img
                            className="rupee_img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Home/Om_Winner_Info/rupee.png"
                            }
                            alt="rupee_img"
                          />
                        </div>

                        <div className="amount_text_holder">
                          <h4>{count * code * multiply}</h4>
                        </div>
                      </div>{" "}
                    </>
                  }
                  background="#000"
                  borderRadius="8px"
                  textAlgin="center"
                  fontSize="16px"
                  fontWeight="600"
                  color={btnTextColor}
                  width=" 100%"
                  border="none"
                  margin="0px 2px"
                  padding="5px 0px"
                />

                <Common_Button
                  className={"SubmitBtn "}
                  text="Place Bet"
                  background="#fff"
                  borderRadius="8px"
                  textAlgin="center"
                  fontSize="16px"
                  fontWeight="600"
                  color=" #000000"
                  width=" 100%"
                  border="none"
                  margin="0px 2px"
                  padding="5px 0px"
                  // onClick={() => {
                  //   setModalShow(true);
                  //   onHide();
                  // }}
                  onClick={handleLogin}
                />
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </section>
      <SuccessfullyPopUp
        show={modalShow}
        onHide={() => setModalShow(false)}
        textHeading={"Bet Placed Successfully!"}
      />

      <Congratulations_Modal
        show={modalCongratsShow}
        onHide={() => setCongratsModalShow(false)}
      />

      <Without_Login_PopUp
        show={modalWithoutLoginShow}
        onHide={() => setModalWithoutLoginShow(false)}
        smsText={"Please Log in!"}
      />

      {/* <Sorry_Modal
        show={modalCongratsShow}
        onHide={() => setCongratsModalShow(false)}
      /> */}
    </>
  );
}

export default Win_Go_Modal;
