import React from "react";
import "./Attendance_Gift_Card.css";

function Attendance_Gift_Card({gameGift}) {
  return (
    <section className="Attendance_Gift_Card">
      <div className="Attendance_Gift_Card_holder">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row main-gift-card-holder">
              <div className="col-md-3 col-3">
                <div className="gift-boxes_img_div">
                  <img
                    className="gift-boxes"
                    src={
                      process.env.PUBLIC_URL + "/assets/Activity/gift-boxes.png"
                    }
                  />
                </div>
              </div>

              <div className="col-md-9 col-9">
                <div className="text-holder-main">
                  <div className="row">
                    <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-5 col-6">
                      <div className="text-holder">
                        <h4>₹ {gameGift.reduce((sum, value) => Number(sum) + (Number(value.bonus) || 0), 0)}</h4>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-5 col-6">
                      <div className="text-holder">
                        <p>7 Day</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Attendance_Gift_Card;
