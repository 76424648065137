import React, { useState, useEffect } from "react";
import "./Subordinate_Data.css";
import Sidebar from "../../Sidebar/Sidebar";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getsubordinateData } from "../../../utils/apis/master/Master";

function Subordinate_Data() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Promotion", link: "/promotion" },
    { text: "Subordinate Data" },
  ]);


  const [depositeAmount, setDepositeAmount] = useState(0);
  const [deposite, setDeposite] = useState([]);
  const [depositeNumber, setDepositeNumber] = useState(0);
  const [totalNobet, setTotalNobet] = useState(0);
  const [totalnobetters, setTotalNoBetters] = useState(0);
  const [totalNoDeposite, setTotalNoDeposite] = useState(0);

  const subordinateData = async () => {
    const res = await getsubordinateData();
    if (res?.success) {
      setDepositeAmount(res?.data?.deposite_amount);
      setDeposite(res?.data?.deposite_number);
      setDepositeNumber(res?.data?.deposite_number_total);
      setTotalNobet(res?.data?.total_no_of_bet);
      setTotalNoBetters(res?.data?.total_no_of_betters);
      setTotalNoDeposite(res?.data?.total_no_of_deposite);
    }
  }


  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    // Only update the state if the value is a number or empty
    if (/^\d*$/.test(value)) {
      setInputValue(value);
    }
  };


  useEffect(() => {
    subordinateData();
  }, []);

  return (
    <>
      <section className="Subordinate_Data">
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              <BreadcrumComponent items={breadcrumbItems} />

              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-9 col-md-12">
                  <div className="search-bar-holder">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Search Subordinate UID"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </div>

                  <div className="data_content_holder">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 borderline">
                        <div className="data-holder">
                          <div className="data">
                            <p>Deposit Number</p>
                            <p className="box-color">{depositeNumber}</p>
                          </div>

                          <div className="data">
                            <p>Number Of Bettors</p>
                            <p className="box-color">{totalnobetters}</p>
                          </div>

                          <div className="data">
                            <p>Number of People Making First Deposit</p>
                            <p className="box-color">{totalNoDeposite}</p>
                          </div>


                        </div>
                      </div>



                      <div className="col-md-6 col-sm-6">
                        <div className="data-holder">
                          <div className="data">
                            <p>Deposit Amount</p>
                            <p className="box-color">{depositeNumber * depositeAmount?.amount}</p>
                          </div>

                          <div className="data">
                            <p>Total Bet</p>
                            <p className="box-color">{totalNobet}</p>
                          </div>

                          <div className="data">
                            <p>First Deposit Amount</p>
                            <p className="box-color">{depositeAmount?.amount}</p>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Subordinate_Data;
