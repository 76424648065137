import React, { useContext, useState, useEffect } from "react";
import "./Notice.css";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { notificationGet } from "../../../utils/apis/master/Master";
import Cookies from 'js-cookie'

function Notice() {

  const userLanguageId = Cookies.get('language_id');
  const [details, setDetails] = useState();
  const getDetails = async () => {
    const res = await notificationGet(userLanguageId);
    if (res?.success) {
      setDetails(res?.data)
    }
  }
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <section className="Notice">
        <div className="notice-text-holder">
          {details?.length > 0 && (
            <p>{details[0]?.announcement_language?.name}</p>
          )}
        </div>
        <Link to={"/notification"}>
          <Common_Button className={"SubmitBtn"} text={"Details"} />
        </Link>
      </section>
    </>
  );
}

export default Notice;
