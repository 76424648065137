import React, { useState,useEffect, useContext } from "react";
import "./LoginWith.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Succsessfull_Modal from "../../Common-Component/Common_Modal/Succsessfull_Modal/Succsessfull_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Notice_Modal from "../../Common-Component/Common_Modal/Notice_Modal/Notice_Modal";
import { Context } from "../../../utils/context";
import classNames from "classnames";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'

function LoginWith() {
  const { IMG_URL, userdata, getData, postData, deleteData,setSignin,setuserId } = useContext(Context);
  const [error, setErrors] = useState('');
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    const res = await postData(`/emailLogin`,data);
    if (res?.success) {
      Cookies.set("om_winners_security",res?.data?.token);
      Cookies.set("userID",res?.data?.user);
      reset();
      setErrors('');
      setSignin(true);
      navigate('/');
      setuserId(res?.data?.user);
    } else {
      setErrors(res?.response?.data?.data);
    }
  }
  const [successfullModalShow, setSuccessfullModalShow] = useState(false);
  const [noticeModalShow, setNoticeModalShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    // Show success modal
    setSuccessfullModalShow(true);

    // After 3 seconds, hide success modal and show notice modal
    setTimeout(() => {
      setSuccessfullModalShow(false);
      setNoticeModalShow(true);
    }, 3000);
  };


  return (
    <>
      <section className="loginwith_section">
        <Container fluid className="p-0">
          <div className="img-holder desktop-view-image">
            <img className="bg_img" src={process.env.PUBLIC_URL + "/assets/Home/Login/bg-img.png"} alt="bg_img" />
          </div>
          <div className="img-holder mobile-view-image">
            <img className="bg_img" src={process.env.PUBLIC_URL + "/assets/modal-bg-img/login-bg-banner.png"} alt="bg_img" />
          </div>
          <div className="logincontainner">
            <Container>
              <div className="loginrowdiv">
                <Row>
                  <Col xxl={4} xl={5} lg={6} md={7} sm={9} className="mx-auto auth-form">
                    <div className="form_main">
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="labelnames">Email</Form.Label>
                          <Form.Control
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                          {...register("email", {
                            required: "Email Id required",
                            pattern: {
                              value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                              message: "Email Id Invalid",
                            },
                          })}
                            type="email"
                            placeholder="Enter Your email"
                            // className="inputboxess"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="labelnames">
                            Password
                          </Form.Label>
                          {/* <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            className="inputboxess"
                          /> */}

                          <div className="password-field-holder">
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.password,
                              })}
                              {...register("password", {
                                required: "Password is required"
                              })}
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter Password"
                            />
                            <div className="eye-icon-holder ">
                              <FontAwesomeIcon
                                onClick={togglePasswordVisibility}
                                className="eye-icon"
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Remember Password"
                          />
                        </Form.Group>
                        <br></br>
                        <div className="row">
                          <span className="text-center" style={{ color :'red'}}>{error}</span>
                        </div>
                        <br></br>
                        <div className="loginbuttonmain">
                          <Common_Button
                            text="Login"
                            className="loginbutton"
                            onClick={handleSubmit(onSubmit)}
                          />
                          <p className="donthave">
                            Don't have an account?{" "}
                            <Link
                              to={"/register-with-email"}
                              className="registerclick"
                            >
                              Register
                            </Link>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Container>
      </section>
      <Succsessfull_Modal
        show={successfullModalShow}
        onHide={() => setSuccessfullModalShow(false)}
        smsText="Login Successfully!"
      />

      <Notice_Modal
        show={noticeModalShow}
        onHide={() => setNoticeModalShow(false)}
      />

    </>
  );
}

export default LoginWith;
