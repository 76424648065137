import React, { useState, useEffect, useContext } from 'react'
import './Profile_Notification_Main.css'
import { Col, Row } from 'react-bootstrap'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Delete_Popup from '../../../Common-Component/Common_Modal/Delete_Popup/Delete_Popup'
import { Context } from '../../../../utils/context'
import { loginNotification, deleteNoti } from '../../../../utils/apis/master/Master'

function Profile_Notification_Main() {
    const [smShow, setSmShow] = useState(false);
    const [deleteId, setId] = useState();
    const [notificationLogin, setloginNotification] = useState();
    const getloginNotification = async () => {
        const res = await loginNotification();
        if (res?.success) {
            setloginNotification(res?.data)
        }
    }

    const DeleteNotification = async () => {
        const res = await deleteNoti(deleteId);
        if (res?.success) {
            getloginNotification();
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getloginNotification();
    }, []);

    const notification = [
        {
            name: "Login Information",
            image: process.env.PUBLIC_URL + "/assets/Wallet/india.png"
        },
        {
            name: "Login Information",
            image: process.env.PUBLIC_URL + "/assets/Wallet/kingdom.png"
        },
        {
            name: "Login Information",
            image: process.env.PUBLIC_URL + "/assets/Wallet/india.png"
        },
        {
            name: "Login Information",
            image: process.env.PUBLIC_URL + "/assets/Wallet/india.png"
        },
    ]

    return (
        <section className='profile_notification_section'>
            <div className='profile_notification_main'>
                <Row>
                    {notificationLogin?.map((langs, index) => (
                        <Col xxl={2} xl={3} lg={4} md={4} sm={4} xs={6} className='mb-4'>
                            <div className='notification_box'>
                                <div className='notifi_name_main'>
                                    <div className='lang_name'>
                                        <p className='lang_text'>{langs?.name}</p>
                                    </div>

                                </div>
                                <div className='nofici_date'>
                                    <p className='mb-1'>{langs?.name}</p>
                                    <p className='mb-1'>{new Date(langs?.date).toLocaleDateString('en-GB', {
                                        year: 'numeric',
                                        month: 'short', // Use 'short' to display month name abbreviated like 'May'
                                        day: '2-digit',
                                    })}</p>
                                    <p>{langs?.time}</p>
                                </div>

                                <div className='delete_div' onClick={() => { setId(langs?.id); setSmShow(true); }} >
                                    <FontAwesomeIcon icon={faTrash} className="delete_icon" />
                                </div>

                            </div>
                        </Col>
                    ))}
                </Row>
                <Delete_Popup
                    show={smShow}
                    id={deleteId}
                    DeleteNotification={DeleteNotification}
                    onHide={() => setSmShow(false)}
                    smtext="Are You want to delete Login History?" />
            </div>
        </section>
    )
}

export default Profile_Notification_Main