import React from "react";
import "./Promotion_Card.css";
import { Link } from "react-router-dom";
function Promotion_Card({ image, titleText, baseLink , onClick}) {
  const handleClick = () => {
    if (onClick) {
      onClick(); // If there's an onClick function passed, call it
    }
  };
  return (
    <>
      <Link to={baseLink} onClick={handleClick}>
        <div className="Promotion_Card">
          <div className="img-holder">
            <img
              className="promotion_img"
              src={process.env.PUBLIC_URL + image}
              alt="promotion_img"
            />
          </div>

          <div className="title-holder">
            <p>{titleText}</p>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Promotion_Card;
