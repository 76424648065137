import React from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import TransectionHistory from './TransectionHistory/TransectionHistory'

const TransectionHistoryPage = () => {
    return (
        <>
            <section>
                <div className="row me-0 ms-0">
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
                        <div className="sidebar_holder">
                            <Sidebar />
                        </div>
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
                        <div className="main-content-holder">
                            {/* insert your promotion componants */}

                            <TransectionHistory />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TransectionHistoryPage