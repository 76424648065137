import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Succsessfull_Modal from "../../Common-Component/Common_Modal/Succsessfull_Modal/Succsessfull_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Notice_Modal from "../../Common-Component/Common_Modal/Notice_Modal/Notice_Modal";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
import { emailRegister } from "../../../utils/apis/common/Common";
import { Context } from "../../../utils/context";
import Cookies from 'js-cookie'

function RegisterWith() {


  const [successfullModalShow, setSuccessfullModalShow] = useState(false);
  const [noticeModalShow, setNoticeModalShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);

  const toggleconfirmPasswordVisibility = () => {
    setShowconfirmPassword(!showconfirmPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    // Show success modal
    setSuccessfullModalShow(true);

    // After 3 seconds, hide success modal and show notice modal
    setTimeout(() => {
      setSuccessfullModalShow(false);
      setNoticeModalShow(true);
    }, 3000); // Adjust the delay time as needed
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate()

  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")
  const [emailerror, setemailErrors] = useState("");
  const [contactError, setContactError] = useState("");
  const [checkError, setCheckError] = useState("");
  const { setSignin } = useContext(Context);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  const onSubmit = async (d) => {
    if (d?.password == d?.confirm_password) {
      if (isChecked) {
        const res = await emailRegister(d);
        if (res?.success) {
          setSuccessfullModalShow(true);
          Cookies.set("om_winners_security", res?.data?.token);
          setSignin(true);
          setTimeout(() => {
            setSuccessfullModalShow(false);
            setNoticeModalShow(true);
          }, 3000);
        } else {
          setemailErrors(res?.response?.data?.data?.email?.[0]);
        }
      } else {
        setCheckError("Please Accepting Privacy Agreement");
      }
    } else {
      setContactError("PassWord Does Not Match")
    }
  };

  return (
    <>
      <section className="loginwith_section">
        <Container fluid className="p-0">
          <div className="img-holder desktop-view-image">
            <img className="bg_img" src={process.env.PUBLIC_URL + "/assets/Home/Login/bg-img.png"} alt="bg_img" />
          </div>
          <div className="img-holder mobile-view-image">
            <img className="bg_img" src={process.env.PUBLIC_URL + "/assets/modal-bg-img/login-bg-banner.png"} alt="bg_img" />
          </div>
          <div className="logincontainner">
            <Container>
              <div className="loginrowdiv">
                <Row>
                  <Col xxl={4} xl={5} lg={6} md={7} sm={9} className="mx-auto auth-form">
                    <div className="form_main">
                      <Form>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label className="labelnames">Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Your email"
                            {...register("email", {
                              required: "Email Id required",
                              pattern: {
                                value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                message: "Email Id Invalid",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                            })}
                          />
                        </Form.Group>
                        {errors?.email && (
                          <span className="text text-danger">
                            {errors.email.message}
                          </span>
                        )}
                        <div>
                          <span className="text-center" style={{ color: 'red' }}>{emailerror}</span>
                        </div>

                        <Form.Group
                          className="mb-4"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="labelnames">
                            Set Password
                          </Form.Label>
                          {/* <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            className="inputboxess"
                          /> */}
                          <div className="password-field-holder">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter Password"
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message: "Password must be at least 8 characters long",
                                },
                                pattern: {
                                  // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S.{8,}$/,
                                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*()-+=]{8,}$/,
                                  message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                }
                              })}
                              onChange={e => setPassword(e.target.value)}
                            />
                            <div className="eye-icon-holder ">
                              <FontAwesomeIcon
                                onClick={togglePasswordVisibility}
                                className="eye-icon"
                                icon={showPassword ? faEye : faEyeSlash}
                              />
                            </div>
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-4"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="labelnames">
                            Confirm Password
                          </Form.Label>
                          <div className="password-field-holder">
                            <Form.Control
                              type={showconfirmPassword ? "text" : "password"}
                              placeholder="Confirm Password"
                              className={classNames("", {
                                "is-invalid": errors?.confirm_password,
                              })}
                              {...register("confirm_password", {
                                required: "Password is required",
                                pattern: {
                                  value: /^(?!.*\s).*$/, // Regex to disallow spaces
                                  message: "Password cannot contain spaces",
                                },
                              })}
                              onChange={e => setPasswordAgain(e.target.value)}
                            />
                            <div className="eye-icon-holder ">
                              <FontAwesomeIcon
                                onClick={toggleconfirmPasswordVisibility}
                                className="eye-icon"
                                icon={showconfirmPassword ? faEye : faEyeSlash}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        
                        <span style={{ color: 'red' }}>{contactError}</span>

                        <Form.Group
                          className="mb-4"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="labelnames">
                            Referal Code
                          </Form.Label>
                          <div className="password-field-holder">
                            <Form.Control
                              placeholder=" Referal Code"
                              className={classNames("", {
                                "is-invalid": errors?.udi,
                              })}
                              {...register("udi")}
                            />

                          </div>
                        </Form.Group>

                        <PasswordChecklist
                          rules={["minLength", "specialChar", "number", "capital", "match","lowercase"]}
                          minLength={8}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => { }}
                        />

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label="I have Read and Agree(Privacy Agreement)"
                            onChange={handleCheckboxChange}
                          />
                        </Form.Group>


                        <span style={{ color: 'red' }}>{checkError}</span>

                        <br></br>
                        <div className="loginbuttonmain">
                          <Common_Button
                            type="button"
                            text="Register"
                            className="loginbutton"
                            // onClick={handleLogin}
                            onClick={handleSubmit(onSubmit)}
                          />
                          <p className="donthave">
                            I have an account?{" "}
                            <Link
                              to={"/login-with-email"}
                              className="registerclick"
                            >
                              Login
                            </Link>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Container>
      </section>

      <Succsessfull_Modal
        show={successfullModalShow}
        onHide={() => setSuccessfullModalShow(false)}
        smsText="Register Successfully!"
      />

      <Notice_Modal
        show={noticeModalShow}
        onHide={() => setNoticeModalShow(false)}
      />
    </>
  );
}

export default RegisterWith;
