import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row } from "react-bootstrap";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import "./SettingInformation.css";
import {
  faChevronRight,
  faCopy,
  faKey,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginModal from "../../../Common-Component/Common_Modal/AllSettingModal/LoginModal";
import BindModal from "../../../Common-Component/Common_Modal/AllSettingModal/BindModal";
import { Link } from "react-router-dom";
import ChangeNameModal from "../../../Common-Component/Common_Modal/AllSettingModal/ChangeNameModal";
import SuccessfullyPopUp from "../../../Common-Component/Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";
import { Context } from "../../../../utils/context";
import { getData } from "../../../../utils/api";
import { editProfile } from "../../../../utils/apis/master/Master";


const SettingInformation = () => {
  const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }
  
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard:", text);
        // You can add a success message or any other action here if needed
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };
  const [modalLogin, setModalLogin] = React.useState(false);
  const [modalUid, setModalUid] = React.useState(false);
  const [modalName, setModalName] = React.useState(false);
  const [modalBind, setModalBind] = React.useState(false);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Settings", link: "#" },
  ]);

  const {
    IMG_URL,
    userdata,
    getData,
    postData,
    deleteData,
    userId,
    setSignin,
  } = useContext(Context);


  const [profile, setProfile] = useState();
  const getUserProfile = async () => {
    const res = await getData(`/getProfile`);
    if (res?.success) {
      setProfile(res?.data);
    }
  };

  const importData = () => {
    let profilePhoto;
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    // Accept only image files
    input.onchange = async () => {
      let files = input.files;
      for (let i = 0; i < files.length; i++) {
        profilePhoto = (files[i]);
      }
      var data = new FormData();
      data.append('image', profilePhoto);
      const res = await editProfile(data)
      if (res?.success) {
        getUserProfile();
      } else {
        console.error('Error fetching  data:', res?.error);
      }
    };
    input.click();
  }

  useEffect(() => {
    getUserProfile();
  }, []);
  return (
    <>
      <section className="profile-infornation">
        <Row>
          <BreadcrumComponent items={breadcrumbItems} />
          <Col xxl={5} xl={7} lg={6} md={6} sm={6}>
            <div className="card-holder mb-3">
              <div className="profile-box">
                <div className="profile-img text-center">
                  <img
                    className="prof-logo "
                    src={IMG_URL + profile?.image}
                    alt="Logo"
                  />
                </div>
                <div className="white-circle text-center">
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={importData} />
                </div>
              </div>
              <div className="name-holder text-center mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-2 mt-sm-3 mt-3">
                <h2>Change Avatar</h2>
              </div>
              <div
                className="d-flex mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-3"
                onClick={() => setModalName(true)}
              >
                <div className="name-holder ">
                  <h3>Nickname</h3>
                </div>
                <div className="name-holder ms-auto  ">
                  <h3>
                    {profile?.name}{" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="right-icon"
                    />
                  </h3>
                </div>
              </div>

              <div
                className="d-flex mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-3"
              >
                <div className="name-holder">
                  <h3>UID</h3>
                </div>
                <div className="name-holder ms-auto"
                  onClick={() => {
                    copyToClipboard(profile?.udi);
                    setModalUid(true);
                  }}>
                  <h3>
                    <FontAwesomeIcon icon={faCopy} className="me-2" />
                    {profile?.udi}{" "}
                  </h3>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={4} xl={5} lg={6} md={6} sm={6}>
            <Card className="security-card mb-3">
              <Card.Header>Security Information</Card.Header>
              <Card.Body>
                <div
                  className="d-flex border-line  mb-3"
                  onClick={() => setModalLogin(true)}
                >
                  <div className="name-holder">
                    <h3>
                      <FontAwesomeIcon icon={faKey} className="me-2" />
                      Login Password
                    </h3>
                  </div>
                  <div className="name-holder ms-auto">
                    <h3>
                      Edit{" "}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="right-icon ms-2"
                      />
                    </h3>
                  </div>
                </div>
                <div
                  className="d-flex border-line  mb-3"
                  onClick={() => setModalBind(true)}
                >
                  <div className="name-holder">
                    <h3>
                      {" "}
                      <img
                        className="right-icon me-2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/profile/Layer_27.png"
                        }
                        alt="Logo"
                      />
                      Bind Mailbox
                    </h3>
                  </div>
                  <div className="name-holder ms-auto">
                    <h3>
                      To Bind{" "}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="right-icon ms-2"
                      />
                    </h3>
                  </div>
                </div>
                <Link to="/profile-verification">
                  {" "}
                  <div className="d-flex border-line  mb-3">
                    <div className="name-holder">
                      <h3>
                        <img
                          className="right-icon me-2"
                          src={
                            process.env.PUBLIC_URL + "/assets/profile/true.png"
                          }
                          alt="Logo"
                        />
                        Google Verification
                      </h3>
                    </div>
                    <div className="name-holder ms-auto">
                      <h3>
                        Unopened
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="right-icon ms-2"
                        />
                      </h3>
                    </div>
                  </div>
                </Link>
                <div className="d-flex border-line mb-3">
                  <div className="name-holder">
                    <h3>
                      <img
                        className="right-icon me-2"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/profile/System_Update.png"
                        }
                        alt="Logo"
                      />
                      Updated Version
                    </h3>
                  </div>
                  <div className="name-holder ms-auto">
                    <h3>
                      1.0.9
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="right-icon ms-2"
                      />
                    </h3>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      <LoginModal
        headingName="Change Login Password"
        show={modalLogin}
        onHide={() => setModalLogin(false)}
      />
      <BindModal
        headingName="Bind Mailbox"
        show={modalBind}
        onHide={() => setModalBind(false)}
      />
      <ChangeNameModal
        headingName="Change Nickname"
        show={modalName}
        getUserProfile={getUserProfile}
        onHide={() => setModalName(false)}
      />

      <SuccessfullyPopUp
        textHeading="UID Copied Successfully!"
        show={modalUid}
        onHide={() => setModalUid(false)}
      />
    </>
  );
};

export default SettingInformation;
