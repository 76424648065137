// Without_Login_PopUp.js

import React from "react";
import "./Without_Login_PopUp.css";
import { Modal } from "react-bootstrap";

function Without_Login_PopUp({ show, onHide, smsText }) {
  return (
    <>
      <section className="popup_section">
        <Modal
          show={show}
          onHide={onHide}
          className="Successfull_Modal Without_Login_PopUp"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // fullscreen={true}
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body >
            <div>
              <p className="mb-0">{smsText}</p>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default Without_Login_PopUp;
