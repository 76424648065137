import React, { useState } from "react";
import "./LoginModal.css";
import Modal from "react-bootstrap/Modal";
import { Form, InputGroup } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../../Common_Button/Common_Button";
import GoogleForgotPassModal from "./GoogleForgotPassModal";
const PasswordValidationModal = (props) => {
  const [modalForgot, setModalForgot] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { headingName } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="all-setting-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-secc">
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Login Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your  Password"
                  type={showPassword ? "text" : "password"}
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <InputGroup.Text id="basic-addon1">
                  {" "}
                  <FontAwesomeIcon
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <p
              className="service-text"
              onClick={() => {
                setModalForgot(true);
                props.onHide();
              }}
            >
              Forget Password?
            </p>
            <div className="button-holder mt-3 text-center">
              <Common_Button
                className={"save-btn"}
                text={"Confirm"}
                onClick={props.onHide}
              ></Common_Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <GoogleForgotPassModal
        headingName="Forget Password"
        show={modalForgot}
        onHide={() => setModalForgot(false)}
      />
    </>
  );
};

export default PasswordValidationModal;
