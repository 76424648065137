import React, { useState, useEffect, useContext } from 'react'
import { Card, CardBody, CardFooter, Col, Nav, Row, Tab } from 'react-bootstrap'
import Common_Button from '../../../../Common-Component/Common_Button/Common_Button'
import './WithdrawalHistoryCard.css'
import Wallet_Withdraw_Card from '../../../../Common-Component/Wallet_Withdraw_Card/Wallet_Withdraw_Card'
import AllHistoryCard from '../../../../Common-Component/AllHistoryCard/AllHistoryCard'
import { withdrawHistory } from '../../../../../utils/apis/master/Master'
import { Context } from '../../../../../utils/context'

const WithdrawalHistoryCard = () => {

    // const [getWithdrawHistory, setWithdrawHistory] = useState();    
    // const [userBankDetailsHistory, setUserBankDetailsHistory] = useState([]);
    // const [upiDetailsHistory, setUpiDetailsHistory] = useState([]);
    const{setWalletData,wallet,getWithdrawHistory,setWithdrawHistory,userBankDetailsHistory,setUserBankDetailsHistory,upiDetailsHistory,setUpiDetailsHistory}=useContext(Context);

    const getWithdrawHistorys = async () => {
        const res = await withdrawHistory();
        if (res?.success) {
            setWithdrawHistory(res?.data)
            setUserBankDetailsHistory(res?.data.filter(item => item.user_b_d_id !== null));
            setUpiDetailsHistory(res?.data.filter(item => item.upi_id !== null));
        }
    }

    useEffect(() => {
        getWithdrawHistorys();
    }, []);


    const [showHistory, setShowHistory] = useState(false);

    const toggleHistory = () => {
        setShowHistory(!showHistory);
    };
    return (
        <>
            <Card className="Background">
                <CardBody>
                    <h4 className="history">Withdrawal History</h4>
                </CardBody>
                {getWithdrawHistory?.length > 0 ? (
                    <CardFooter>
                        <div className="withdraw-btn">
                            <Common_Button text="All History" className={"SubmitBtn"} width={"100%"} background={"#F89001"} border={"1px solid #F89001"} border-radius={"7px"} onClick={toggleHistory} />
                        </div>

                        {showHistory && (
                            <AllHistoryCard getWithdrawHistory={getWithdrawHistory} upiDetailsHistory={upiDetailsHistory} userBankDetailsHistory={userBankDetailsHistory}/>
                        )}
                    </CardFooter>
                ) : (
                    <CardBody>
                        <div className="Data-image">
                            <img className="data" src={process.env.PUBLIC_URL + "/assets/Icons/box.png"} />
                        </div>
                        <p className="data">No Data</p>
                    </CardBody>
                )}
            </Card>
        </>
    )
}

export default WithdrawalHistoryCard