import React, { useContext, useState, useEffect } from "react";
import "./SelectChannel.css";
import { Col, Row } from "react-bootstrap";
import Common_Button from "../../../../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import useRazorpay from "react-razorpay";
import { Grid } from "swiper/modules";
import { DepositeAmount } from "../../../../../utils/apis/master/Master";
import Form from "react-bootstrap/Form";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import { useCallback } from "react";
import { walletDataPost } from "../../../../../utils/apis/master/Master";
import SuccessfullyPopUp from "../../../../Common-Component/Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";

function SelectChannel({ chanel,getProjrct }) {

  const { control, register, handleSubmit, getValues, setError, setValue, clearErrors, formState: { errors }, reset, } = useForm();
  const [Razorpay, isLoaded] = useRazorpay();
  const [selectedCard, setSelectedCard] = useState(null);
  const [modalSuccess, setModalSuccess] = React.useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [depositeAmount, setDepositeAmount] = useState([]);
  const [deposite, setDeposite] = useState(null);
  const [channel, setChannel] = useState(null);
  const [ID, setID] = useState(null);

  const handleCardClick = (cardIndex) => {
    setSelectedCard(cardIndex);
  };


  const handleAmountClick = (amtIndex) => {
    setSelectedAmount(amtIndex);
  };


  const getDepositeAmount = async (channelID) => {
    const res = await DepositeAmount(channelID);
    if (res?.success) {
      await setDeposite(res?.data?.[0]?.value);
      await setID(res?.data?.[0]?.id);
      await setChannel(channelID)
      await  setDepositeAmount(res?.data)
    }
  }


  const handleDeposite = async (amount, id) => {
    await setDeposite(amount);
    await setID(id)
  };


  const handleCancle = async (amount, id) => {
    await setDeposite(amount);
    await setID(id)
  };


  const finalFunction = async (razor,chn,id,dep) => {
    console.log(chn);
      const data = {};
      data.amount = dep;
      data.channel_id = chn;
      data.deposit_amount_id = id;
      const res = await walletDataPost(data)
      if (res?.success) {
        setModalSuccess(true);
        setTimeout(() => {
          getProjrct();
        }, 2000);
      }
  }


  const handlePayment = useCallback(async (chn,id,dep) => {
    try {
      if (chn && id && dep) {
        const options = {
          key: "rzp_test_KCP2DQFiHRB3cs",
          amount: dep*100,
          currency: "INR",
          name: "Om Winner",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          handler: async (res) => {
            console.log(res);
            await finalFunction(res,chn,id,dep);
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();

      } else {
        alert("please select chanel and deposit amount")
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  }, [Razorpay]);


  return (
    <section className='select_channel_section mt-3'>
      <div className='select_channel_main'>
        <Form>
          <Row>
            <Col xxl={8} xl={8} lg={10} md={12} className='mb-3'>
              <div className='selectchannel'>
                <h6 className='channel_text'>Select Channel</h6>
                <div>
                  <Row>
                    {chanel?.map((channel, index) => (
                      <Col xxl={3} xl={3} lg={3} md={3} className='mb-3'>
                        <div className={`chann_div ${selectedAmount === index ? 'selectchan_div' : ''}`}
                          onClick={() => { handleAmountClick(index); getDepositeAmount(channel?.id) }}>
                          <p className='chann_text'>{channel?.name}</p>
                          <p className='chann_text'>{channel?.balance}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={7} md={6} className='mb-3'>
              <div className='dpAmount'>
                <h6 className='dpamt_text'>Deposit Amount</h6>
                <div>
                  <Row>
                    {depositeAmount?.map((amt, index) => (
                      <Col xxl={3} xl={3} lg={3} md={3} className='mb-3'>
                        <div className={`rs_div ${ID === amt?.id ? 'rs_click' : ''}`}
                          onClick={() => {handleDeposite(amt?.value, amt?.id)}}>
                          <img className="rscoin me-1" src={process.env.PUBLIC_URL + "/assets/Icons/doller.png"} /><span className='amttt'>{amt?.amount}</span>
                        </div>

                      </Col>
                    ))}
                  </Row>
                  <div className='bttn_div'>
                    {deposite &&
                      // <button type='button' className='amtbuttn'> <img className="rscoin" src={process.env.PUBLIC_URL + "/assets/Icons/doller.png"} />{deposite} <FontAwesomeIcon icon={faXmark} /></button>
                      <button type='button' className='amtbuttn'> <img className="rscoin" src={process.env.PUBLIC_URL + "/assets/Icons/doller.png"} />{deposite} </button>
                    }
                    <Common_Button text="Deposit" type="button" className="depo_button" onClick={() => handlePayment(channel,ID,deposite)} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <SuccessfullyPopUp
        textHeading="Money added Successfully Send!"
        show={modalSuccess}
        onHide={() => setModalSuccess(false)}
      />
    </section>
  )
}

export default SelectChannel;
