import React, { useState, useContext, useEffect } from "react";
import "./WithdrawContent.css";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import WithdrawTab1 from "../WithdrawTab1/WithdrawTab1";
import WithdrawTab2 from "../WithdrawTab2/WithdrawTab2";
import WithdrawTab3 from "../WithdrawTab3/WithdrawTab3";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { walletData } from "../../../../utils/apis/master/Master";
import { Context } from "../../../../utils/context";

const WithdrawContent = () => {
    const [activeTab, setActiveTab] = useState("first");

    // const handleTabChange = (eventKey) => {
    //     setActiveTab(eventKey);
    // };
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Profile", link: "/profile" },
        { text: "Wallet", link: "/wallet" },
        { text: "Withdraw", link: "#" },
    ]);

    // const [wallet, setWalletData] = useState();

    const{setWalletData,wallet}=useContext(Context);
    
    const getProjrct = async () => {
        const res = await walletData();
        if (res?.success) {
            setWalletData(res?.data)
        }
    }

    useEffect(() => {
        getProjrct();
    }, []);
    return (
        <>
            <section className="WithdrawContent">
                <BreadcrumComponent items={breadcrumbItems} />
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col xl={4} lg={4} md={6}>
                            <div className="withdraw-sidebar">
                                <div className="balance-card">
                                    <p className="balance-heading">Balance</p>
                                    <div className="bank-balance">
                                        <div className="coins">
                                            <div className="rupees">
                                                <img
                                                    className="rupee"
                                                    src={process.env.PUBLIC_URL + "/assets/Home/Om_Winner_Info/rupee.png"}
                                                />
                                            </div>
                                            <div className="count">
                                                <p>{wallet?.available_balance}</p>
                                            </div>
                                        </div>
                                        <div className="white-back">
                                            <FontAwesomeIcon icon={faWallet} className="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Nav variant="pills" className="flex-column withdraw-sidebar">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" >
                                        <div className="inner">
                                            <div className="payment-mode">
                                                <div className="payment-image">
                                                    <img
                                                        className="payment"
                                                        src={process.env.PUBLIC_URL + "/assets/profile/transparent-payments.png"}
                                                    />
                                                </div>
                                                <div className="payment-name">
                                                    <p>Phonepe/ Googlepay</p>
                                                </div>
                                                <div className="icons-main">
                                                    <FontAwesomeIcon icon={faCircleArrowRight} />
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="second" >
                                        <div className="inner">
                                            <div className="payment-mode">
                                                <div className="payment-image">
                                                    <img
                                                        className="payment"
                                                        src={process.env.PUBLIC_URL + "/assets/Icons/t.png"}
                                                    />
                                                </div>
                                                <div className="payment-name">
                                                    <p>USDT O</p>
                                                </div>
                                                <div className="icons-main">
                                                    <FontAwesomeIcon icon={faCircleArrowRight} />
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <Nav.Link eventKey="three" >
                                        <div className="inner">
                                            <div className="payment-mode">
                                                <div className="payment-image">
                                                    <img
                                                        className="payment"
                                                        src={process.env.PUBLIC_URL + "/assets/profile/3d-credit-card.png"}
                                                    />
                                                </div>
                                                <div className="payment-name">
                                                    <p>Bank Card</p>
                                                </div>
                                                <div className="icons-main">
                                                    <FontAwesomeIcon icon={faCircleArrowRight} />
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col xl={8} lg={8} md={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <WithdrawTab1 />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <WithdrawTab2 />
                                </Tab.Pane>
                                <Tab.Pane eventKey="three">
                                    <WithdrawTab3 />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>

            </section>
        </>
    );
};

export default WithdrawContent;