import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import Deposit_Main from "./Deposit_Main/Deposit_Main";

function ProfileCart_Deposit() {
  return (
    <>
      <section>
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              {/* insert your promotion componants */}
              <Deposit_Main />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfileCart_Deposit;
