import React from 'react'
import { Nav, Row, Tab } from 'react-bootstrap'
import Wallet_Withdraw_Card from '../Wallet_Withdraw_Card/Wallet_Withdraw_Card'
import './AllHistoryCard.css'
const AllHistoryCard = ({ getWithdrawHistory ,upiDetailsHistory,userBankDetailsHistory}) => {
    return (
        <>
            <div className='slider-btn-history my-4'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="card-slide-nav">
                        <Nav.Item>
                            <Nav.Link eventKey="first">All</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Bank Card</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">UPI ID</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>

                        {getWithdrawHistory?.map((data, index) => (
                            <Tab.Pane eventKey="first">
                                <div className="withdraw_card_div mb-3">
                                    <Wallet_Withdraw_Card deposit="Withdraw" status={data?.transaction_status} type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"} orderno={data?.transaction_number} balance={data?.amount} data={data?.created_at} />
                                </div>
                            </Tab.Pane>
                        ))}
                        {userBankDetailsHistory?.map((data, index) => (
                            <Tab.Pane eventKey="second">
                                <div className="withdraw_card_div mb-3">
                                    <Wallet_Withdraw_Card deposit="Withdraw" status={data?.transaction_status} type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"} orderno={data?.transaction_number} balance={data?.amount} data={data?.created_at} />
                                </div>
                            </Tab.Pane>
                        ))}
                        {upiDetailsHistory?.map((data, index) => (
                            <Tab.Pane eventKey="third">
                                <div className="withdraw_card_div mb-3">
                                    <Wallet_Withdraw_Card deposit="Withdraw" status={data?.transaction_status} type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"} orderno={data?.transaction_number} balance={data?.amount} data={data?.created_at} />
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>

                </Tab.Container>
            </div>
        </>
    )
}

export default AllHistoryCard