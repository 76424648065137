import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Col, Row } from "react-bootstrap";
import "./WithdrawTab1.css";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import WithdrawalHistoryCard from "./WithdrawalHistoryCard/WithdrawalHistoryCard";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import { walletData, withdrawHistory, withdrawMoney } from "../../../../utils/apis/master/Master";
import SuccessfullyPopUp from "../../../Common-Component/Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";

const WithdrawTab1 = () => {
    const [cupError, setCupError] = useState("");
    const [modalShow, setModalShow] = useState(false);

    const { setWalletData, setWithdrawHistory, setUserBankDetailsHistory, setUpiDetailsHistory } = useContext(Context);

    const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();
    const onSubmit = async (data) => {
        console.log(data.mobile_number.length,"data.mobile_number.lengthdata.mobile_number.length ");
        if (data.mobile_number.length == 10) {
            const res = await withdrawMoney(data)
            if (res?.success) {
                setModalShow(true);
                setCupError('');
                getProjrct();
                getWithdrawHistorys();
                reset();
                setTimeout(() => {
                    setModalShow(false);
                }, 3000);

            } else {
                setCupError(res?.response?.data?.data)
            }
        } else {
            setError("mobile_number", {
                message: "Mobile Number must be exactly 10 digits",
            });
        }
    }
    // console.log(getValues(), "mobile_numbermobile_number");

    const getProjrct = async () => {
        const res = await walletData();
        if (res?.success) {
            setWalletData(res?.data)
        }
    }


    const getWithdrawHistorys = async () => {
        const res = await withdrawHistory();
        if (res?.success) {
            setWithdrawHistory(res?.data)
            setUserBankDetailsHistory(res?.data.filter(item => item.user_b_d_id !== null));
            setUpiDetailsHistory(res?.data.filter(item => item.upi_id !== null));
        }
    }
    return (
        <>
            <section className="Withdraw-Tab1">
                <Row className="justify-content-center">
                    <Col xxl={6} xl={6} lg={12} md={12} >
                        <Form>
                            <Card className="Background">
                                <CardBody>
                                    <div className="Bank-image">
                                        <img className="LIP" src={process.env.PUBLIC_URL + "/assets/profile/unified-payments.png"} />
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Enter Name"
                                            {...register("name", {
                                                required: "Name is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.name,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="mobile_number"
                                            placeholder="Mobile Number"
                                            {...register("mobile_number", {
                                                required: "Mobile Number required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.mobile_number,
                                            })}
                                            onKeyPress={(event) => {
                                                const charCode = event.which ? event.which : event.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            maxLength={10}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="amount"
                                            placeholder="Enter Amount"
                                            {...register("amount", {
                                                required: "Amount is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.amount,
                                            })}
                                            onKeyDown={(e) => {
                                                const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace'];
                                                if (!allowedKeys.includes(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>UPI ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="upi_id"
                                            placeholder="Enter UPI Number"
                                            {...register("upi_id", {
                                                required: "upi_id is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.upi_id,
                                            })}
                                        // onKeyPress={(event) => {
                                        //     const charCode = event.which ? event.which : event.keyCode;
                                        //     if (charCode < 48 || charCode > 57) {
                                        //         event.preventDefault();
                                        //     }
                                        // }}
                                        // // minLength={7}
                                        // maxLength={12}
                                        />
                                    </Form.Group>

                                    <Form.Label><span style={{ color: "red" }}>{cupError}</span></Form.Label>
                                </CardBody>
                                <CardFooter>
                                    <div className="withdraw-btn-one">
                                        <Common_Button text="withdraw" className={"SubmitBtn"} width={"100%"} background={"#F89001"} border={"1px solid #F89001"} border-radius={"7px"} onClick={handleSubmit(onSubmit)} />
                                    </div>
                                </CardFooter>
                            </Card>
                        </Form>
                    </Col>
                    <Col xxl={6} xl={6} lg={12} md={12}>
                        <WithdrawalHistoryCard />
                    </Col>
                </Row>
            </section>
            <SuccessfullyPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                textHeading={"Withdrow request placed Successfully!"}
            />
        </>
    );
};

export default WithdrawTab1;
