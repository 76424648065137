import React, { useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faCircleNotch,
  faHouse,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  const [activeMenu, setActiveMenu] = useState("home");
  return (
    <>
      <div className="footer-holder">
        <div className="row me-0 ms-0">
          <div className="col-2 p-0">
            <div
              className={`footer-menu-holder ${activeMenu === "home" ? "active" : ""
                }`}
            >
              <Link to={"/"} onClick={() => setActiveMenu("home")}>
                <nav-link className="">
                  <FontAwesomeIcon className="other-icon" icon={faHouse} />
                  <p>Home</p>
                </nav-link>
              </Link>
            </div>
          </div>

          <div className="col-2 p-0">
            <div className={`footer-menu-holder ${activeMenu === "activity" ? "active" : ""}`}>
              <Link to={"/activity"} onClick={() => setActiveMenu("activity")}>
                <nav-link>
                  <FontAwesomeIcon className="other-icon" icon={faCircleNotch} />
                  <p>Activity</p>
                </nav-link>
              </Link>
            </div>
          </div>

          <div className="col-4 p-0">
            <div className={`footer-menu-holder ${activeMenu === "promotion" ? "active" : ""}`} >
              <Link to={"/promotion"} onClick={() => setActiveMenu("promotion")}>
                <nav-link>
                  <FontAwesomeIcon className="notification-icon" icon={faBullhorn} />
                </nav-link>
              </Link>
            </div>
          </div>

          <div className="col-2 p-0">
            <div className={`footer-menu-holder ${activeMenu === "wallet" ? "active" : ""}`}>
              <Link to={"/wallet"} onClick={() => setActiveMenu("wallet")}>
                <nav-link>
                  <FontAwesomeIcon className="other-icon" icon={faWallet} />
                  <p>Wallet</p>
                </nav-link>
              </Link>
            </div>
          </div>

          <div className="col-2 p-0">
            <div
              className={`footer-menu-holder ${activeMenu === "profile" ? "active" : ""
                }`}
            >
              <Link to={"/profile"} onClick={() => setActiveMenu("profile")}>
                <nav-link>
                  <FontAwesomeIcon className="other-icon" icon={faUser} />
                  <p>Profile</p>
                </nav-link>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
