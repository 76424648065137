import React, { useState, useEffect, useContext, useRef } from "react";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import Calendar from "react-calendar";
import { Col, Dropdown, DropdownButton, Nav, Row, Tab } from "react-bootstrap";
import Gift_Img_Holder from "../../../Common-Component/Gift_Img_Holder/Gift_Img_Holder";
import Wallet_Withdraw_Card from "../../../Common-Component/Wallet_Withdraw_Card/Wallet_Withdraw_Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { myTransactionHistory } from "../../../../utils/apis/master/Master";

const TransactionHistory = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Wallet", link: "/wallet" },
    { text: "Transaction history", link: "#" },
  ]);

  const { register: registerDropdown, handleSubmit: handleSubmitDropdown, setValue: setValueDropdown } = useForm();
  const { register: registerCalendar, handleSubmit: handleSubmitCalendar, setValue: setValueCalendar } = useForm();

  const [activeItem, setActiveItem] = useState("");
  const [wallethistory, setWalletHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  const { getMyGameHistory } = useContext(Context);

  const getHistory = async (selectedType, selectedDate) => {
    const res = await myTransactionHistory(selectedType, selectedDate);
    if (res?.success) {
      setWalletHistory(res?.data);
    }
  };
  const dropdownRef = useRef(null);

  const handleItemClick = (item) => () => {
    setActiveItem(item);
    setValueDropdown('type', item);
    getHistory(item);
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValueCalendar('selectedDate', date);
    getHistory(activeItem, format(date, 'yyyy-MM-dd'));    
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const onSubmitDropdown = (data) => {
    const selectedType = data.type;
    getHistory(selectedType);
  };

  const onSubmitCalendar = (data) => {
    const selectedDateFormatted = format(data.selectedDate, 'yyyy-MM-dd');
    getHistory(activeItem, selectedDateFormatted);
  };

  useEffect(() => {
    getHistory();
  }, []);


  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   // Close the dropdown
  // };

  return (
    <>
      <section className="withdraw_history_section">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="withdraw_history_main">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className="history_tabs_top mb-4">
              <Nav variant="pills">
                <Nav.Item className="me-md-4 me-sm-2 me-2 mb-2">
                  <Nav.Link eventKey="first">
                    <FontAwesomeIcon icon={faGrip} className="me-2" /> All
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="dropdown_div mb-2">
                <form onSubmit={handleSubmitDropdown(onSubmitDropdown)}>
                  <DropdownButton id="dropdown-basic-button" title="All" className="me-4 mb-2">
                    {/* <div className={`dropdown-item ${activeItem === "Processing" ? "active" : ""}`}>
                      <div className="drop_menu" onClick={handleItemClick("Processing")}>Processing</div>
                    </div> */}
                    <div className={`dropdown-item ${activeItem === "Pending" ? "active" : ""}`}>
                      <div className="drop_menu" onClick={handleItemClick("Pending")}>Pendings</div>
                    </div>
                    <div className={`dropdown-item ${activeItem === "Complete" ? "active" : ""}`}>
                      <div className="drop_menu" onClick={handleItemClick("Complete")}>Completed</div>
                    </div>
                    <input type="hidden" {...registerDropdown('type')} value={activeItem} />
                    {/* <button type="submit" className="savebutton">
                      <div className="drop_menu">save</div>
                    </button> */}
                  </DropdownButton>
                </form>
                <form onSubmit={handleSubmitCalendar(onSubmitCalendar)}>
                  {/* <DropdownButton id="dropdown-basic-button" title="Date" className="mb-2">
                    <div className="mb-2">
                      <Calendar
                        onChange={handleDateChange}
                        value={selectedDate}
                        maxDate={new Date()}
                      />
                    </div>
                    <Dropdown.Item as="button" type="submit" className="savebutton">
                      <div className="drop_menu">save</div>
                    </Dropdown.Item>
                  </DropdownButton> */}

                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Date"
                    className="mb-2"
                    ref={dropdownRef}
                  > 
                    <div className="mb-2">
                      <Calendar
                        onChange={handleDateChange}
                        value={selectedDate}
                        maxDate={new Date()}
                      />
                    </div>
                    {/* Uncomment if you need a save button inside the dropdown
      <Dropdown.Item as="button" type="submit" className="savebutton">
        <div className="drop_menu">save</div>
      </Dropdown.Item>
      */}
                  </DropdownButton>
                  <input type="hidden" {...registerCalendar('selectedDate')} value={selectedDate} />
                </form>
              </div>
            </div>
            <Tab.Content>
              <div className="history_tab_div">
                <Row>
                  {wallethistory?.map((data, index) => (
                    <Col xxl={4} xl={4} lg={6} md={6} key={index}>
                      <div className="withdraw_card_div mb-3">
                        <Wallet_Withdraw_Card
                          deposit={data?.type}
                          status={data?.transaction_status}
                          type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"}
                          balance={data?.amount}
                          data={data?.created_at}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Tab.Content>
          </Tab.Container>
          {getMyGameHistory === null && (
            <div className="image-holder">
              <Gift_Img_Holder />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default TransactionHistory;
