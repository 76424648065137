import React, { useEffect, useRef, useState } from "react";
import "./Mobile_View_Landing.css";
import { faEnvelope, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import LazyLoad from "react-lazyload";

function Mobile_View_Landing() {
  const vidRef = useRef();
  const [showImage, setShowImage] = useState(true);
  const [showVideo, setShowVideo] = useState(false); // State to control video visibility

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowImage(false);
      setShowVideo(true);
    }, 2500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <section className="Mobile_View_Landing ">
        {showImage && (
          <div className="front-page-bg-holder">
            <div className="img-holder">
              <img
                className="img-class-grid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/mobile-view/landing-page/landing-page-img1.jpg"
                }
              />
            </div>
            <div className="overlay"></div>

            <div className="overlay-logo-img-holder">
              <div className="logo-img-holder">
                <img
                  className="overlay-logo-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Header/Header_logo/winner_logo.png"
                  }
                />
              </div>
            </div>
          </div>
        )}

        {showVideo && ( // Render the video only if showVideo is true
          <>
            <div className="main-vd-content-holder Header ">
              <div className="vdo-holder">
                
                <video
                  className="video-class-grid"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/mobile-view/landing-page/landing-page-vdo.mp4"
                  }
                  ref={vidRef}
                  muted
                  loop
                  autoPlay // Add autoPlay attribute to make the video play automatically
                />

               
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default Mobile_View_Landing;
