import React from 'react'
import './Recharge_Instructions.css'
import { Col, Row } from 'react-bootstrap'
import Gift_Img_Holder from '../../../../Common-Component/Gift_Img_Holder/Gift_Img_Holder'
import AllHistoryCard from '../../../../Common-Component/AllHistoryCard/AllHistoryCard'
function Recharge_Instructions({ instruction }) {
    // const instruction = [
    //     {
    //         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    //     },
    //     {
    //         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    //     },
    //     {
    //         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    //     },
    //     {
    //         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
    //     }
    // ]
    return (
        <section className='recharge_instruct_section mt-3'>
            <div className='recharge_instruct_main'>
                <Row>
                    <Col xxl={8} xl={8} lg={6} md={6}  sm={12} className='mb-3'>
                        <div className='recharge_instruct'>
                            <h6 className='instruct_text'>Recharge Instructions</h6>
                            <div className='instruction'>


                                {instruction?.map((instruct, index) => (
                                    <div className='instruct_main'>
                                        <div className='me-3'>
                                            <div className='dot_div mt-2 ms-2'>
                                                <div className='dot_list'></div>
                                            </div>
                                        </div>
                                        <div className='instruct_text_div'>
                                            <p className='inst_text'>{instruct?.recharge_instruction_language?.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={6} md={6} sm={12} className='mb-3'>
                        <div className='recharge_instruct'>
                            <h6 className='instruct_text'>Deposit History</h6>
                            <div className='instruction'>
                                <Gift_Img_Holder />
                                <p className='nodata'>No data</p>
                            </div>
                            <AllHistoryCard />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Recharge_Instructions