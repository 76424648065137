import React, { useState, useEffect, useContext } from "react";
import "./LoginModal.css"
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import Common_Button from '../../Common_Button/Common_Button';
import { Context } from "../../../../utils/context";
import { editProfile } from "../../../../utils/apis/master/Master";

const ChangeNameModal = (props) => {
    const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();
    const { headingName } = props;

    const onSubmit = async (data) => {
        const res = await editProfile(data)
        if (res?.success) {
            reset();
            props.onHide();
            props.getUserProfile();
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='all-setting-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-secc'>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nickname</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter Your Nickname"
                                {...register("name", {
                                    required: "Name is required",
                                })}
                                className={classNames("", {
                                    "is-invalid": errors?.name,
                                })}
                            />
                        </Form.Group>
                        <div className='button-holder mt-3 text-center'>
                            <Common_Button className={'save-btn'} text={'Save'} onClick={handleSubmit(onSubmit)}></Common_Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeNameModal