import React from "react";
import "./Simple_Box.css";

function Simple_Box({
  text,
  background,
  borderRadius,
  boxshadow,
  padding,
  color,
  width,
  height,
  margin,
  fontWeight,
  border,
  className,
  fontSize,
  textDecoration,
  onClick,
}) {
  const BoxStyle = {
    background,
    borderRadius,
    boxshadow,
    padding,
    color,
    width,
    height,
    margin,
    fontWeight,
    border,
    fontSize,
    textDecoration,
  };
  return (
    <>
      <div className={className} onClick={onClick} style={BoxStyle}>
        {text}
      </div>
    </>
  );
}

export default Simple_Box;
