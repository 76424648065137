import React, { useState } from "react";
import "./Rebate_Ratio.css";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import Sidebar from "../../Sidebar/Sidebar";
import Heading_Holder from "../../Common-Component/Heading_Holder/Heading_Holder";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination } from "swiper/modules";
function Rebate_Ratio() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Promotion", link: "/promotion" },
    { text: "Rebate Ratio" },
  ]);

  const rebateLevels = [
    {
      level: "Rebate Level",
      number: "01",
      items: [
        {
          className: "content-holder border_bottom",
          label: "1 Level Lower level Commission Rebate",
          ratio: "0.6%",
        },
        {
          className: "content-holder border_bottom",
          label: "2 Level Lower level Commission Rebate",
          ratio: "0.12%",
        },
        {
          className: "content-holder border_bottom",
          label: "3 Level Lower level Commission Rebate",
          ratio: "0.34%",
        },
        {
          className: "content-holder border_bottom",
          label: "4 Level Lower level Commission Rebate",
          ratio: "0.034%",
        },
        {
          className: "content-holder border_bottom",
          label: "5 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
        {
          className: "content-holder ",
          label: "6 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
      ],
    },

    {
      level: "Rebate Level",
      number: "02",
      items: [
        {
          className: "content-holder border_bottom",
          label: "1 Level Lower level Commission Rebate",
          ratio: "0.6%",
        },
        {
          className: "content-holder border_bottom",
          label: "2 Level Lower level Commission Rebate",
          ratio: "0.12%",
        },
        {
          className: "content-holder border_bottom",
          label: "3 Level Lower level Commission Rebate",
          ratio: "0.34%",
        },
        {
          className: "content-holder border_bottom",
          label: "4 Level Lower level Commission Rebate",
          ratio: "0.034%",
        },
        {
          className: "content-holder border_bottom",
          label: "5 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
        {
          className: "content-holder ",
          label: "6 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
      ],
    },

    {
      level: "Rebate Level",
      number: "03",
      items: [
        {
          className: "content-holder border_bottom",
          label: "1 Level Lower level Commission Rebate",
          ratio: "0.6%",
        },
        {
          className: "content-holder border_bottom",
          label: "2 Level Lower level Commission Rebate",
          ratio: "0.12%",
        },
        {
          className: "content-holder border_bottom",
          label: "3 Level Lower level Commission Rebate",
          ratio: "0.34%",
        },
        {
          className: "content-holder border_bottom",
          label: "4 Level Lower level Commission Rebate",
          ratio: "0.034%",
        },
        {
          className: "content-holder border_bottom",
          label: "5 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
        {
          className: "content-holder ",
          label: "6 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
      ],
    },

    {
      level: "Rebate Level",
      number: "04",
      items: [
        {
          className: "content-holder border_bottom",
          label: "1 Level Lower level Commission Rebate",
          ratio: "0.6%",
        },
        {
          className: "content-holder border_bottom",
          label: "2 Level Lower level Commission Rebate",
          ratio: "0.12%",
        },
        {
          className: "content-holder border_bottom",
          label: "3 Level Lower level Commission Rebate",
          ratio: "0.34%",
        },
        {
          className: "content-holder border_bottom",
          label: "4 Level Lower level Commission Rebate",
          ratio: "0.034%",
        },
        {
          className: "content-holder border_bottom",
          label: "5 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
        {
          className: "content-holder ",
          label: "6 Level Lower level Commission Rebate",
          ratio: "0.0034%",
        },
      ],
    },
  ];

  return (
    <>
      <section className="Rebate_Ratio">
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              <BreadcrumComponent items={breadcrumbItems} />
              <div className="row desktop-view-slider">
                {rebateLevels.map((rebateLevel, index) => (
                  <div
                    key={index}
                    className="col-xxl-3 col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 "
                  >
                    <div className="Rebate_Ratio_Card_holder">
                      <div className="Rebate_Ratio_Card">
                        <div className="card-heading-holder">
                          <h1>{rebateLevel.level}</h1>
                        </div>

                        <div className="card-text-holder">
                          {rebateLevel.items.map((item, itemIndex) => (
                            <div key={itemIndex} className={item.className}>
                              <div className="row">
                                <div className="col-md-8 col-sm-8 col-8">
                                  <div className="text-holder">
                                    <p>{item.label}</p>
                                  </div>
                                </div>

                                <div className="col-md-4 col-sm-4 col-4">
                                  <div className="text-holder ratio-holder">
                                    <p>{item.ratio}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="note-bar-holder">
                          <div className="inner-arrow-holder">
                            <div className="number-note">
                              <div className="note_holder">
                                <p>{rebateLevel.number}</p>
                              </div>
                            </div>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row mobile-view-slider">
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    420: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    486: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },

                  }}

                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper1"

                >
                  {rebateLevels.map((rebateLevel, index) => (
                    <SwiperSlide key={index}>
                      <div className="Rebate_Ratio_Card_holder">
                        <div className="Rebate_Ratio_Card">
                          <div className="card-heading-holder">
                            <h1>{rebateLevel.level}</h1>
                          </div>

                          <div className="card-text-holder">
                            {rebateLevel.items.map((item, itemIndex) => (
                              <div key={itemIndex} className={item.className}>
                                <div className="row">
                                  <div className="col-md-8 col-sm-8 col-8">
                                    <div className="text-holder">
                                      <p>{item.label}</p>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-sm-4 col-4">
                                    <div className="text-holder ratio-holder">
                                      <p>{item.ratio}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="note-bar-holder">
                            <div className="inner-arrow-holder">
                              <div className="number-note">
                                <div className="note_holder">
                                  <p>{rebateLevel.number}</p>
                                </div>
                              </div>
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Rebate_Ratio;
