import React, { useContext, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./UpcomingGame.css";
// Import Swiper styles
import "swiper/css";
import { Context } from "../../../utils/context";
const UpcomingGame = ({upcomingGame}) => {

  
  const {IMG_URL} = useContext(Context)
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const UpcomingGames = [
    {
      images: process.env.PUBLIC_URL + "/assets/Home/UpcomingGame/dice.png",
      title: "Upcoming Game",
      text: "Ludo",
    },
    {
      images: process.env.PUBLIC_URL + "/assets/Home/UpcomingGame/cards.png",
      title: "Upcoming Game",
      text: "Teen Patte",
    },
    {
      images:
        process.env.PUBLIC_URL + "/assets/Home/UpcomingGame/air-spacing.png",
      title: "Upcoming Game",
      text: "Aviator",
    },

    {
      images: process.env.PUBLIC_URL + "/assets/Home/UpcomingGame/dice.png",
      title: "Upcoming Game",
      text: "Rummy",
    },
  ];
  return (
    <>
      <section className="Upcoming-Game">
        <Swiper
          className="mySwiper"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            380: {
              slidesPerView: 1,
            },
            485: {
              slidesPerView: 1,
            },
            575: {
              slidesPerView: 2,
            },

            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            2000: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {upcomingGame?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="Upcoming-Card">
                <div className="dice-image">
                  <img className="bg_img" src={IMG_URL + item?.image} alt="bg_img" />
                </div>
                <div className="overlay"></div>
                <div className="content">
                  <div className="upcoming-text">
                    {/* <h4 className="title">{item.title}</h4> */}
                    <h4 className="title">Upcoming Game</h4>
                  </div>
                  <h1 className="game-text">{item?.name}</h1>
                </div>
              </div>
            </SwiperSlide>
          ))}

           <div className="silder-btn">
          <div
            className="back-btn"
            onClick={() => handleMainSliderClick("next")}
          ></div>
          <div
            className="next-btn"
            onClick={() => handleMainSliderClick("prev")}
          ></div>
        </div>
        </Swiper>
       
      </section>
    </>
  );
};

export default UpcomingGame;
