import React from "react";
import "./View_full_history.css";
import { Modal } from "react-bootstrap";
import Simple_Box from "../../Simple_Box/Simple_Box";

function View_full_history(props) {
  // Data for the repeated rows
  const repeatedRowsData = [
    {
      title: "Order Number",
      orderNumber: "WG20202799890789236475473954802873",
    },
    { title: "Period", orderNumber: "123456789012" },
    { title: "Purchase Amount", orderNumber: <>10.00</> },
    { title: "Quantity", orderNumber: "1" },
    {
      title: "Result",
      orderNumber: (
        <>
          <div>
            <p className="data-text-holder">
              2{" "}
              <Simple_Box
                className={"Simple_Box red_Box"}
                padding={"5px 12px"}
                margin={"2px 30px 2px 10px"}
                borderRadius={"3px"}
              />
              Small
            </p>
          </div>
        </>
      ),
    },

    {
      title: "Selected",
      orderNumber: (
        <>
          <div>
            <p className="data-text-holder">
              <Simple_Box
                className={"Simple_Box green_Box"}
                padding={"8px 12px"}
                margin={"4px 5px 4px 5px"}
                borderRadius={"3px"}
              />
            </p>
          </div>
        </>
      ),
    },

    { title: "Status", orderNumber: "Failed" },

    { title: "Win/Lose", orderNumber: "-9" },

    { title: "Order Time", orderNumber: "4 Feb 24" },
  ];
  return (
    <>
      <div className="View_full_history">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Notice_Modal View_full_history"
        >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {repeatedRowsData.map((rowData, index) => (
                <>
                  <div key={index} className="scale-up-ver-top">
                    <div>
                      <Simple_Box
                        text={
                          <>
                            <div className="text-holder">
                              <div className="title-holder">
                                <p>{rowData.title} </p>
                              </div>
                              <div className="info-holder">
                                <p>{rowData.orderNumber}</p>
                              </div>
                            </div>
                          </>
                        }
                        className={"Simple_Box grey_Box"}
                        padding={"7px 5px"}
                        fontSize="13px"
                        borderRadius={"0px"}
                        margin={"10px 0px"}
                      />
                    </div>
                  </div>
                </>
              ))}
            </>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default View_full_history;
