import React from "react";
import "./Banner_Heading.css"

function Banner_Heading({heading}) {
  return (
    <>
      <div className="Banner_Heading">
        <div className="text-heading text-center">
          <h2>{heading}</h2>
        </div>
      </div>
    </>
  );
}

export default Banner_Heading;
