import React, { useState, useEffect, useContext } from "react";
import "./Choose_Date_Modal.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import Common_Button from "../../Common_Button/Common_Button";
import { Context } from "../../../../utils/context";
import { editProfile } from "../../../../utils/apis/master/Master";
import { ModalFooter } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Choose_Date_Modal = (props) => {
  const { headingName } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="all-setting-modal Choose_Date_Modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-holder">
            <Calendar />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-holder text-center">
            <Common_Button
              className={"cancle-btn"}
              text={"Cancle"}
              border={"1px solid #000"}
              margin={"0px 5px"}
              background={"#fff"}
              padding={"4px 41px "}
              width={"100%"}
              borderRadius={"8px "}
              onClick={props.onHide}
            />
            <Common_Button
              className={"save-btn"}
              margin={"0px 5px"}
              padding={"4px 41px "}
              width={"100%"}
              borderRadius={"8px "}
              border={"1px solid #F89001"}
              text={"Confirm"}
              onClick={props.onHide}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Choose_Date_Modal;
