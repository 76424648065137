import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Homepage from "../Homepage/Homepage";
import LoginWith from "../Login/LoginWith/LoginWith";
import RegisterWith from "../Login/RegisterWith/RegisterWith";
import LoginWithPhone from "../Login/LoginWithPhone/LoginWithPhone";
import RegisterWithPhone from "../Login/RegisterWithPhone/RegisterWithPhone";

import Promotion from "../Promotion/Promotion";
import Wallet from "../Wallet/Wallet";
import Activity from "../Activity/Activity";
import Notification from "../Notification/Notification";
import AboutUs from "../AboutUs/AboutUs";
import HomeInner_Page from "../Homepage/HomeInner_Page/HomeInner_Page";
import Profile from "../Profile/Profile";
import ProfileSetting from "../Profile/ProfileSetting/ProfileSetting";

import Witdraw from "../Wallet/Witdraw/Witdraw";
import WalletInnerPage from "../Wallet/WalletInnerPage/WalletInnerPage";
import ProfileCart_Deposit from "../Profile/ProfileCart_Deposit/ProfileCart_Deposit";
import Profile_gift from "../Profile/Profile_Gift/Profile_gift";
import Subordinate_Data from "../Promotion/Subordinate_Data/Subordinate_Data";
import ProfileGoogleVerification from "../Profile/ProfileGoogleVerification/ProfileGoogleVerification";
import Profile_language from "../Profile/Profile_language/Profile_language";
import Profile_Notification from "../Profile/Profile_Notification/Profile_Notification";
import New_Subordinates from "../Promotion/New_Subordinates/New_Subordinates";
import Invitation_Rules from "../Promotion/Invitation_Rules/Invitation_Rules";
import ProfilecustomerService from "../Profile/ProfilecustomerService/ProfilecustomerService";
import Rebate_Ratio from "../Promotion/Rebate_Ratio/Rebate_Ratio";
import TransectionHistoryPage from "../Wallet/WalletInnerPage/TransectionHistoryPage";
import GameHistoryPage from "../Wallet/WalletInnerPage/GameHistoryPage";
import Deposit_HistoryPage from "../Wallet/WalletInnerPage/Deposit_HistoryPage";
import Commission_Detail from "../Promotion/Commission_Detail/Commission_Detail";
import Attendance_Bonus_main from "../Activity/Attendance_Bonus_main/Attendance_Bonus_main";
import Attendance_History from "../Activity/Attendance_Bonus_main/Attendance_Bonus_inner/Attendance_History_main/Attendance_History/Attendance_History";
import Attendance_History_main from "../Activity/Attendance_Bonus_main/Attendance_Bonus_inner/Attendance_History_main/Attendance_History_main";
import Game_Rules_main from "../Activity/Attendance_Bonus_main/Attendance_Bonus_inner/Game_Rules_main/Game_Rules_main";

function AllRoutes() {
  const location = useLocation();
  const [showDownloadButton, setShowDownloadButton] = useState(
    ![
      "/login-with-email",
      "/register-with-email",
      "/login-with-phone",
      "/register-with-phone",
    ].includes(location.pathname)
  );

  useEffect(() => {
    setShowDownloadButton(
      ![
        "/login-with-email",
        "/register-with-email",
        "/login-with-phone",
        "/register-with-phone",
      ].includes(location.pathname)
    );
  }, [location]);
  return (
    <div className="position-relative">
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/home-inner" element={<HomeInner_Page />}></Route>
        <Route path="/promotion" element={<Promotion />}></Route>
        <Route
          path="/promotion/subordinate-data"
          element={<Subordinate_Data />}
        ></Route>

        <Route
          path="/promotion/commission-detail"
          element={<Commission_Detail />}
        ></Route>
        <Route
          path="/promotion/invitation-rules"
          element={<Invitation_Rules />}
        ></Route>
        <Route
          path="/promotion/rebate-ratio"
          element={<Rebate_Ratio />}
        ></Route>
        <Route
          path="/promotion/new-subordinates"
          element={<New_Subordinates />}
        ></Route>
        <Route
          path="/promotion/invitation-rules"
          element={<Invitation_Rules />}
        ></Route>
        <Route path="/wallet" element={<Wallet />}></Route>
        <Route
          path="/wallet_withdraw_history"
          element={<WalletInnerPage />}
        ></Route>
        <Route
          path="/wallet_deposit_history"
          element={<Deposit_HistoryPage />}
        ></Route>
        <Route
          path="/wallet_transection_history"
          element={<TransectionHistoryPage />}
        ></Route>
        <Route
          path="/wallet_game_history"
          element={<GameHistoryPage />}
        ></Route>
        <Route path="/activity" element={<Activity />}></Route>
        <Route path="/notification" element={<Notification />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/login-with-email" element={<LoginWith />}></Route>
        <Route path="/register-with-email" element={<RegisterWith />}></Route>
        <Route path="/login-with-phone" element={<LoginWithPhone />}></Route>
        <Route
          path="/register-with-phone"
          element={<RegisterWithPhone />}
        ></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/profile-setting" element={<ProfileSetting />}></Route>
        <Route path="/wallet/deposit" element={<ProfileCart_Deposit />}></Route>
        <Route path="/profile_gift" element={<Profile_gift />}></Route>
        <Route
          path="/attendance-bonus"
          element={<Attendance_Bonus_main />}
        ></Route>
        <Route
          path="/attendance-history"
          element={<Attendance_History_main />}
        ></Route>

        <Route path="/game-rules" element={<Game_Rules_main />}></Route>
        <Route path="/wallet/withdraw" element={<Witdraw />}></Route>
        <Route
          path="/profile-verification"
          element={<ProfileGoogleVerification />}
        ></Route>
        <Route path="/profile_language" element={<Profile_language />}></Route>
        <Route
          path="/profile_notification"
          element={<Profile_Notification />}
        ></Route>
        <Route
          path="/profile/Customer-services"
          element={<ProfilecustomerService />}
        ></Route>
      </Routes>

      {showDownloadButton && (
        <>
          <Link to={"/profile/Customer-services"}>
            <div className="serviceCenterButton_div">
              <div className="downloadButton">
                <img
                  className="service_call_image"
                  src={
                    process.env.PUBLIC_URL + "/assets/Icons/service-call.png"
                  }
                />
              </div>
            </div>
          </Link>

          <Link to={"https://play.google.com/"} target="_blank">
            <div className="downloadbutton_div">
              <button className="downloadButton">
                Download App Now{" "}
                <img
                  className="download_image ms-2"
                  src={process.env.PUBLIC_URL + "/assets/Icons/download.png"}
                />
              </button>
            </div>
          </Link>
        </>
      )}
    </div>
  );
}

export default AllRoutes;
