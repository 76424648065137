import React from "react";
import "./Attendance_Star_Card.css";

function Attendance_Star_Card({rupees, days}) {
  return (
    <section className="Attendance_Star_Card">
      <div className="text-holder">
        <h4>{rupees}</h4>
      </div>

      <div className="star_img_div">
        <img
          className="star_img"
          src={process.env.PUBLIC_URL + "/assets/Activity/star-img.png"}
        />
      </div>

      
      <div className="text-holder">
        <p>{days}</p>
      </div>
    </section>
  );
}

export default Attendance_Star_Card;
