import React from "react";
import "./Winning_Information_Card.css";

function Winning_Information_Card({ReceiveHeadingText, playerName, amountText, playerImgSource, playKitImgSource, rupeeImgSource}) {
  return (
    <>
      <div className="Winning_Information_Card">
        <div className="player_info">
          <div className="row">
            <div className="col-xl-6 col-md-6 col-sm-6  col-6">
              <div className="img-text-holder">
                <div className="img-holder">
                  <img
                    className="player_img"
                    src={
                      process.env.PUBLIC_URL +
                      playerImgSource
                    }
                    alt="player_img"
                  />
                </div>
                <div className="text-holder">
                  <h4>{playerName }</h4>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-sm-6  col-6">
              <div className="PlayKit_img_holder">
                <div className="img-holder">
                  <img
                    className="PlayKit_img"
                    src={
                        process.env.PUBLIC_URL +
                        "/assets/Home/Om_Winner_Info/Img.png"
                      }
                    alt="PlayKit_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Winning_Amount">
          <div className="row me-0 ms-0">
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
              <div className="text-holder">
                <h4>{ReceiveHeadingText}</h4>
              </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 ">
              <div className="amount_holder">
                <div className="rupee-img-holder">
                <img
                    className="rupee_img"
                    src={
                      process.env.PUBLIC_URL +
                      rupeeImgSource
                      
                    }
                    alt="rupee_img"
                  />

                </div>

                <div className="amount_text_holder">
                    <h4>{amountText}</h4>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Winning_Information_Card;
