import React, { useState } from "react";
import "./Game_Rules.css";
import { Col, Row, Table } from "react-bootstrap";
import BreadcrumComponent from "../../../../../Common-Component/BreadcrumComponent/BreadcrumComponent";

function Game_Rules({ gameRule,getAttendanceData }) {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Activity", link: "/activity" },
    { text: "Attendance Bonus", link: "/attendance-bonus" },
    { text: "Game Rules", link: "" },
  ]);

  const attendanceData = [
    {
      continuousAttendance: 1,
      accumulatedAmount: "₹ 200.00",
      attendanceBonus: "₹ 6.00",
    },
    {
      continuousAttendance: 2,
      accumulatedAmount: "₹ 1,000.00",
      attendanceBonus: "₹ 20.00",
    },
    {
      continuousAttendance: 3,
      accumulatedAmount: "₹ 3,000.00",
      attendanceBonus: "₹ 200.00",
    },
    {
      continuousAttendance: 4,
      accumulatedAmount: "₹ 4,000.00",
      attendanceBonus: "₹ 300.00",
    },
    {
      continuousAttendance: 5,
      accumulatedAmount: "₹ 5,000.00",
      attendanceBonus: "₹ 400.00",
    },
    {
      continuousAttendance: 6,
      accumulatedAmount: "₹ 6,000.00",
      attendanceBonus: "₹ 500.00",
    },
    {
      continuousAttendance: 7,
      accumulatedAmount: "₹ 7,000.00",
      attendanceBonus: "₹ 600.00",
    },
    {
      continuousAttendance: 8,
      accumulatedAmount: "₹ 8,000.00",
      attendanceBonus: "₹ 700.00",
    },
    {
      continuousAttendance: 9,
      accumulatedAmount: "₹ 9,000.00",
      attendanceBonus: "₹ 800.00",
    },
  ];

  return (
    <>
      <section className="profile_gift_section Attendance_History Game_Rules p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="profile_gift_main mt-5">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="giftbanner">
                <div className="gift_banner_img_div">
                  <img
                    className="gift_banner_img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Activity/happy-birthday.png"
                    }
                    alt="Gift Banner"
                  />
                </div>
                <div className="banner_half_overlay"></div>

                <div className="gift_formdiv">
                  <Row>
                    <Col xxl={10} xl={10} lg={10} md={8} className="mx-auto">
                      <div className="gift_form_main">
                        <div className="Game_Rules_table">
                          <Table className="table-holder" responsive>
                            <thead>
                              <tr>
                                <th>Continuous Attendance</th>
                                <th>Accumulated Amount</th>
                                <th>Attendance Bonus</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getAttendanceData?.map((row, index) => (
                                <tr key={index}>
                                  <td>{row.days}</td>
                                  <td>{row.amount}</td>
                                  <td>{row.bonus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="gift_history_div">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="gift_history_main">
                <div className="title_holder">
                  <h6 className="gift_history_title">Rules</h6>
                </div>

                <div className="rules-list-holder">
                  <ul>
                    {gameRule?.map((data, index) => (
                      <li>{data?.game_rule_language?.name}</li>
                    ))}
                    {/*<li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et</li>
                     <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etfghfg</li>
                    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etoyukhgkf</li>
                    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus ethjhfgjnfg</li>
                    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus etjyujyj</li> */}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Game_Rules;
