import React, { useState, useEffect } from "react";
import "./WalletMain.css";
import { Col, Row } from "react-bootstrap";
import Money_History from "../../Common-Component/Money_History/Money_History";
import { faBlenderPhone } from "@fortawesome/free-solid-svg-icons/faBlenderPhone";
import { Link } from "react-router-dom";
import BreadcrumComponent from '.././../Common-Component/BreadcrumComponent/BreadcrumComponent'
import { walletData } from "../../../utils/apis/master/Master";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

function WalletMain() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Wallet", link: "#" },

  ]);
  const [wallet, setWalletData] = useState();
  const getProjrct = async () => {
    const res = await walletData();
    if (res?.success) {
      setWalletData(res?.data)
    }
  }
  useEffect(() => {
    getProjrct();
  }, []);
  return (
    <>
      <section className="Walletmain_section">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="walletmain_div ">
          <Row>
            <Col xxl={4} xl={6} lg={6} md={6} sm={6} className="order-xxl-1 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1">
              <div className="wallet_card_div mb-3">
                <h6 className="wallet">Wallet</h6>
                <div className="btn  refresh_btn">
                <FontAwesomeIcon icon={faArrowsRotate} onClick={() => window.location.reload()} />
                </div>
                <div className="total_widraw_div">
                  <div className="total_main_div">
                    <div className="deposit_total_div">
                      <p className="total_text">Total Withdraw Amount</p>
                      <p className="total_text">{wallet?.total_withdraw}</p>
                    </div>
                    <div className="borderr"></div>

                    <div className="deposit_total_div deposit_total_div2">
                      <p className="total_text">Total Deposite Amount</p>
                      <p className="total_text">{wallet?.total_deposit}</p>
                    </div>
                  </div>
                  <p className=" total_balance_text">Total Available Balance</p>
                </div>
                <div className="total_money_mian">
                  <div className="total_money_div">
                    <img className="doller_icon" src={process.env.PUBLIC_URL + "/assets/Icons/doller.png"} />{wallet?.available_balance}
                    {/* <p>104.00</p> */}
                    
                  </div>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={6} lg={6} md={6} sm={6} className="order-xxl-2 order-xl-2 order-lg-2 order-md-2 order-sm-3 order-3">
              <div className=" history_sec">
                <div className="mb-4">
                  <Link to={"/wallet_withdraw_history"}><Money_History text="Withdraw History" icon={process.env.PUBLIC_URL + "/assets/Icons/payout.png"} /></Link>
                </div>
                <div className="mb-4 ">
                  <Link to={"/wallet_deposit_history"}><Money_History text="Deposit History" icon={process.env.PUBLIC_URL + "/assets/Icons/wallet.png"} /></Link>
                </div>
                <div className="mb-4 ">
                  <Link to={"/wallet_transection_history"}><Money_History text="Transaction History" icon={process.env.PUBLIC_URL + "/assets/Icons/xss.png"} /></Link>
                </div>
                <div className="mb-4 ">
                  <Link to={"/wallet_game_history"}><Money_History text="Game History" icon={process.env.PUBLIC_URL + "/assets/Icons/orderhistory.png"} /></Link>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={6} lg={6} md={6} sm={6} className=" order-xxl-3 order-xl-3 order-lg-3 order-md-3 order-sm-2 order-2">
              <div className=" mobile-deposit-secc" >
                <Link to={"/wallet/deposit"}>
                  <div className="wallet-white-box mb-3 me-xl-0 me-lg-0 me-md-0 me-sm-2 me-2">
                    <img className="payout-img me-xxl-4 me-xl-4 me-lg-4 me-md-3 me-sm-2 me-2" src={process.env.PUBLIC_URL + "/assets/profile/wallet.png"} /> <span>Deposit</span>
                  </div>
                </Link>
                <Link to={"/wallet/withdraw"}>
                  <div className="wallet-white-box">
                    <img className="payout-img me-xxl-4 me-xl-4 me-lg-4 me-md-3 me-sm-2 me-2" src={process.env.PUBLIC_URL + "/assets/profile/payout.png"} /> <span>Withdraw</span>
                  </div>
                </Link>
              </div>
            </Col>

          </Row>
        </div>
      </section>
    </>
  );
}

export default WalletMain;
