import React, { useEffect, useContext, useState, useRef } from "react";
//import './Withdraw_history.css'
import { Col, Dropdown, DropdownButton, Nav, Row, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip } from "@fortawesome/free-solid-svg-icons/faGrip";
import Wallet_Withdraw_Card from "../../../Common-Component/Wallet_Withdraw_Card/Wallet_Withdraw_Card";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { Context } from "../../../../utils/context";
import { depositeHistory } from "../../../../utils/apis/master/Master";
import { logDOM } from "@testing-library/react";

function Deposit_history() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Wallet", link: "/wallet" },
    { text: "Deposit history", link: "#" },
  ]);

  
  const { register: registerDropdown, handleSubmit: handleSubmitDropdown, setValue: setValueDropdown } = useForm();
  const { register: registerCalendar, handleSubmit: handleSubmitCalendar, setValue: setValueCalendar } = useForm();

  const [activeItem, setActiveItem] = useState("");
  const [wallethistory, setWalletHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { getMyGameHistory } = useContext(Context);

  const getHistory = async (selectedType, selectedDate) => {
    const res = await depositeHistory(selectedType, selectedDate);
    if (res?.success) {
      setWalletHistory(res?.data);
    }
  };

  const dropdownRef = useRef(null);

  const handleItemClick = (item) => () => {
    setActiveItem(item);
    setValueDropdown('type', item);
    getHistory(item);
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValueCalendar('selectedDate', date);
    getHistory(activeItem,format(date, 'yyyy-MM-dd'));
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  

  const onSubmitDropdown = (data) => {
    const selectedType = data.type;
    getHistory(selectedType);
  };

  const onSubmitCalendar = (data) => {
    const selectedDateFormatted = format(data.selectedDate, 'yyyy-MM-dd');
    getHistory(activeItem,selectedDateFormatted);
  };

  useEffect(() => {
    getHistory();
  }, []);
  return (
    <section className="withdraw_history_section">
      <BreadcrumComponent items={breadcrumbItems} />
      <div className="withdraw_history_main mt-4">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="history_tabs_top mb-4">
            <Nav variant="pills" className="">
              <Nav.Item className="me-md-4 me-sm-2 me-2 mb-2">
                <Nav.Link eventKey="first" >
                  <FontAwesomeIcon icon={faGrip} className=" me-2" /> All
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="dropdown_div mb-2">
              <form onSubmit={handleSubmitDropdown(onSubmitDropdown)}>
                <DropdownButton id="dropdown-basic-button" title="All" className="me-4 mb-2">
                  <div className={`dropdown-item ${activeItem === "Pending" ? "active" : ""}`}>
                    <div className="drop_menu" onClick={handleItemClick("Pending")}>Pendings</div>
                  </div>
                  <div className={`dropdown-item ${activeItem === "Complete" ? "active" : ""}`}>
                    <div className="drop_menu" onClick={handleItemClick("Complete")}>Completed</div>
                  </div>
                  <input type="hidden" {...registerDropdown('type')} value={activeItem} />
                  {/* <button type="submit" className="savebutton">
                    <div className="drop_menu">save</div>
                  </button> */}
                </DropdownButton>
              </form>
              <form onSubmit={handleSubmitCalendar(onSubmitCalendar)}>
                <DropdownButton id="dropdown-basic-button" title="Date" className="mb-2" ref={dropdownRef}>
                  <div className="mb-2">
                    <Calendar
                      onChange={handleDateChange}
                      value={selectedDate}
                      maxDate={new Date()}
                    />
                  </div>
                  {/* <Dropdown.Item as="button" type="submit" className="savebutton">
                    <div className="drop_menu">save</div>
                  </Dropdown.Item> */}
                </DropdownButton>
                <input type="hidden" {...registerCalendar('selectedDate')} value={selectedDate} />
              </form>
            </div>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="history_tab_div">
                <Row>
                  {wallethistory?.map((data, index) => (
                    <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                      <div className="withdraw_card_div mb-3">
                        <Wallet_Withdraw_Card
                          data={data?.updated_at}
                          deposit={data?.type}
                          status={data?.transaction_status}
                          type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"}
                          orderno={data?.transaction_number}
                          balance={data?.amount}
                        />
                      </div>
                    </Col>
                  ))}
                  {/* <Col xxl={4} xl={4} lg={6} md={6}>
                    <div className=" withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Deposit"
                        status="To be Paid"
                        type="/assets/Wallet/upi.png"
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className=" withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Deposit"
                        status="Completed"
                        type="/assets/Wallet/upi.png"
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="history_tab_div">
                <Row>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className="withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Deposit"
                        status="Completed"
                        type={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"}
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className=" withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Deposit"
                        status="To be Paid"
                        type="/assets/Wallet/upi.png"
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className=" withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Deposit"
                        status="Completed"
                        type="/assets/Wallet/upi.png"
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div className="history_tab_div">
                <Row>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className="withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Withdraw"
                        status="Completed"
                        type={
                          process.env.PUBLIC_URL + "/assets/Wallet/tlayer.png"
                        }
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className=" withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Withdraw"
                        status="Completed"
                        type="/assets/Wallet/tlayer.png"
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4} lg={6} md={6} sm={6}>
                    <div className=" withdraw_card_div mb-3">
                      <Wallet_Withdraw_Card
                        deposit="Withdraw"
                        status="Completed"
                        type="/assets/Wallet/tlayer.png"
                        orderno="13657751243312"
                        balance="104.00"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </section>
  );
}

export default Deposit_history;
