import React, { useState, useContext, useEffect } from "react";
import "./Om_Winner_Info.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
// Import Swiper styles
import "swiper/css";
// import required modules
import { Navigation } from "swiper/modules";
import Heading_Holder from "../../Common-Component/Heading_Holder/Heading_Holder";
import Winning_Information_Card from "../../Common-Component/Winning_Information_Card/Winning_Information_Card";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { winningInfo } from "../../../utils/apis/master/Master";

function Om_Winner_Info() {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  // Define an array of data representing each slide's content
  const slidesData = [
    {
      playerImgSource: "/assets/Home/Om_Winner_Info/player.png",
      playKitImgSource: "/assets/Home/Om_Winner_Info/PlayKit.png",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      ReceiveHeadingText: "Receive Winning Amount",
      playerName: "Paresh M",
      amountText: "104.00",
    },
    {
      playerImgSource: "/assets/Home/Om_Winner_Info/player.png",
      playKitImgSource: "/assets/Home/Om_Winner_Info/PlayKit.png",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      ReceiveHeadingText: "Receive Winning Amount",
      playerName: "Smita L",
      amountText: "105.00",
    },
    {
      playerImgSource: "/assets/Home/Om_Winner_Info/player.png",
      playKitImgSource: "/assets/Home/Om_Winner_Info/PlayKit.png",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      ReceiveHeadingText: "Receive Winning Amount",
      playerName: "Aditya J",
      amountText: "103.00",
    },
  ];
  const { IMG_URL } = useContext(Context);
  const [getWinningInfo, setWinningInfo] = useState();
  const getProject = async () => {
    const res = await winningInfo();
    if (res?.success) {
      setWinningInfo(res?.data)
    }
  }

  useEffect(() => {
    getProject();
  }, []);
  return (
    <>
      <div className="Om_Winner_Info">
        <div className="row">
          <div className="col-xxl-5 col-xl-5 col-md-6">
            <Link to={"/home-inner"}>
              <div className="Winner_info_card">
                <div className="row">
                  <div className="  col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <Heading_Holder headingText={"Om Winner"} />
                    <div className="info-text-holder">
                      <div className="text-holder">
                        <div className="info-text">
                          <p>Guess Number</p>
                          <p>Green/ Purple/Red to win</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="info-img-holder">
                      <img
                        className="bg_img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Home/Om_Winner_Info/om_winner_laptop.png"
                        }
                        alt="bg_img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xxl-7 col-xl-7 col-md-6">
            <div className="Winner_info_card ">
              <Heading_Holder headingText={"Winning Information"} />
              <div className="winner_card_slider_holder">
                <Swiper
                  className="winner_card_slider"
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                  spaceBetween={22}
                  slidesPerView={2}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                    485: {
                      slidesPerView: 1,
                    },
                    575: {
                      slidesPerView: 2,
                    },

                    768: {
                      slidesPerView: 1,
                    },

                    991: {
                      slidesPerView: 1,
                    },

                    1023: {
                      slidesPerView: 1,
                    },

                    1200: {
                      slidesPerView: 2,
                    },

                    1400: {
                      slidesPerView: 2,
                    },

                    1540: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {/* Map over the slidesData array and render each slide */}
                  {getWinningInfo?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <Winning_Information_Card
                        ReceiveHeadingText="Receive Winning Amount"
                        playerName={slide?.user?.name}
                        amountText={slide?.profit}
                        playerImgSource={IMG_URL + slide?.user?.image}
                        playKitImgSource="/assets/Home/Om_Winner_Info/PlayKit.png"
                        rupeeImgSource="/assets/Home/Om_Winner_Info/rupee.png"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div class="silder-btn">
                  <div
                    className="back-btn"
                    onClick={() => handleMainSliderClick("prev")}
                  ></div>
                  <div
                    className="next-btn"
                    onClick={() => handleMainSliderClick("next")}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Om_Winner_Info;
