import React from "react";
import "./Common_Button.css";
import { Link } from "react-router-dom";

function Common_Button({
  onClick,
  className,
  margin,
  text,
  border,
  background,
  borderRadius,
  fontSize,
  color,
  fontWeight,
  padding,
  textAlgin,
  width,
}) {
  const buttonStyle = {
    background,
    borderRadius,
    border,
    fontSize,
    fontWeight,
    color,
    padding,
    margin,
    className,
    textAlgin,
    width
  };
  return (
    <>
      <button
        onClick={onClick}
        type="button"
        className={className}
        style={buttonStyle}
      >
        {text}
      </button>
    </>
  );
}

export default Common_Button;
