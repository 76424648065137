import React, { useState,useContext,useEffect } from 'react'
import { Card, CardBody, CardFooter, Col, InputGroup, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import WithdrawalHistoryCard from '../WithdrawTab1/WithdrawalHistoryCard/WithdrawalHistoryCard';
import { Context } from '../../../../utils/context';
import SuccessfullyPopUp from "../../../Common-Component/Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";
import { bankData, walletData, withdrawHistory, withdrawMoney } from '../../../../utils/apis/master/Master';

const WithdrawTab3 = () => {
    const [cupError, setCupError] = useState("");
    const [modalShow, setModalShow] = useState(false);

    const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();


    // const toggleShowAll = () => {
    //     setShowAll(!showAll);
    // };
    // const Seedata = [
    //     {
    //         id: 1,
    //         content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
    //     },
    //     {
    //         id: 2,
    //         content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
    //     },
    //     {
    //         id: 3,
    //         content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
    //     },
    //     {
    //         id: 4,
    //         content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
    //     }
    // ];

    
    const{setWalletData,wallet,getWithdrawHistory,setWithdrawHistory,userBankDetailsHistory,setUserBankDetailsHistory,upiDetailsHistory,setUpiDetailsHistory}=useContext(Context);

    const onSubmit = async (data) => {
        const res = await withdrawMoney(data)
        if (res?.success) {
            setModalShow(true);
            setCupError('');
            getProjrct();
            getWithdrawHistorys();
            reset();
            setTimeout(() => {
                setModalShow(false);
            }, 3000);

        } else {
            setCupError(res?.response?.data?.data)
        }
    }


    const getProjrct = async () => {
        const res = await walletData();
        if (res?.success) {
            setWalletData(res?.data)
        }
    }

    
    const[bankDetails,setBankData]=useState([]);

    const getBank = async () => {
        const res = await bankData();
        if (res?.success) {
            setBankData(res?.data)
        }
    }

    useEffect(() => {
        getBank();
    }, []);


    const getWithdrawHistorys = async () => {
        const res = await withdrawHistory();
        if (res?.success) {
            setWithdrawHistory(res?.data)
            setUserBankDetailsHistory(res?.data.filter(item => item.user_b_d_id !== null));
            setUpiDetailsHistory(res?.data.filter(item => item.upi_id !== null));
        }
    }

    return (
        <>
            <section className="Withdraw-Tab1">
                <Row className="justify-content-center">
                    <Col xxl={6} xl={6} lg={12} md={12} >
                        <div className="Background">
                            {/* <Row className='mb-4'>
                                <Col xxl={4} xl={4} lg={4} md={3} sm={3} xs={4}>
                                    <div className='bank-background text-center'>
                                        <img className="bank-img" src={process.env.PUBLIC_URL + "/assets/profile/bank-of-maharashtra.png"} />
                                    </div>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={9} sm={9} xs={8}>
                                    <div className='bank-name mt-3'>
                                        <h2>Bank of Maharashtra...</h2>
                                        <h2>08667****980</h2>
                                    </div>
                                </Col>
                            </Row> */}


                            <div className="form">
                                <Form>
                                    <InputGroup size="sm" className="mb-3">
                                        {/* <InputGroup.Text id="inputGroup-sizing-sm"><img className="rupees me-2" src={process.env.PUBLIC_URL + "/assets/profile/rupay-1.png"} /></InputGroup.Text> */}
                                        <InputGroup.Text id="inputGroup-sizing-sm">Amount</InputGroup.Text>
                                        {/* <Form.Control
                                            placeholder="Please Enter the Amount"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                        /> */}
                                        <Form.Control
                                            type="text"
                                            name="amount"
                                            placeholder="Enter Amount"
                                            {...register("amount", {
                                                required: "Amount is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.amount,
                                            })}
                                            onKeyDown={(e) => {
                                                const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace'];
                                                if (!allowedKeys.includes(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </InputGroup>

                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Bank Name</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Please Enter the Bank Name"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                            {...register("bank_name", {
                                                required: "Name is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.bank_name,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </InputGroup>

                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">IFSC Code</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Please Enter the IFSC Code"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                            {...register("ifsc_code", {
                                                required: "ifsc_code is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.ifsc_code,
                                            })}
                                            // onKeyDown={(event) => {
                                            //     if (!/[A-Z-a-z ]/.test(event.key)) {
                                            //         event.preventDefault();
                                            //     }
                                            // }}
                                        />
                                    </InputGroup>


                                    <InputGroup size="sm" className="mb-3">
                                        <InputGroup.Text id="inputGroup-sizing-sm">Account Number</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Please Enter the Account Number"
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                            type="text"
                                            name="bank_number"
                                            {...register("bank_number", {
                                                required: "bank_number is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.bank_number,
                                            })}
                                            onKeyPress={(event) => {
                                                const charCode = event.which ? event.which : event.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                    <Form.Label><span style={{ color: "red" }}>{cupError}</span></Form.Label>
                                </Form>
                                <div className="d-flex ">
                                    <div className="text-balance">
                                        <p>Withdrawable Balance</p>
                                    </div>
                                    <div className="text-balance ms-auto">
                                        <h6><img className="rupees me-2" src={process.env.PUBLIC_URL + "/assets/profile/rupay-1.png"} /><span>{wallet?.available_balance}</span></h6>
                                    </div>
                                </div>
                                {/* <div className="d-flex ">
                                    <div className="text-balance">
                                        <p>Withdrawal Amount Received</p>
                                    </div>
                                    <div className="text-balance ms-auto">
                                        <h6><img className="rupees me-2" src={process.env.PUBLIC_URL + "/assets/profile/rupay-1.png"} /><span>00.00</span></h6>
                                    </div>
                                </div> */}
                            </div>
                            <div className="withdraw-btn-one">
                                <Common_Button text="withdraw" className={"SubmitBtn"} width={"100%"} background={"#F89001"} border={"1px solid #F89001"} border-radius={"7px"} onClick={handleSubmit(onSubmit)} />
                            </div>
                            {/* <div className="see-more-sec">
                                <div className="text-name text-center" onClick={toggleShowAll}>
                                    <p>{showAll ? 'See Less' : 'See More'}</p>
                                    <FontAwesomeIcon icon={showAll ? faAngleUp : faAngleDown} className="fa-down" />
                                </div>
                                <div className="all-see-text">
                                    {Seedata.slice(0, showAll ? Seedata.length : 0).map(item => (
                                        <div key={item.id} className='d-flex mb-3'>
                                            <div className="me-3">
                                                <div className='orange-box text-center mt-2'>
                                                    <div className='ractangle-icon'></div>
                                                </div>
                                            </div>
                                            <p>{item.content}</p>
                                        </div>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={12} md={12} >
                        <WithdrawalHistoryCard />

                    </Col>
                </Row>
            </section>
            <SuccessfullyPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
                textHeading={"Withdrow request placed Successfully!"}
            />
        </>
    )
}

export default WithdrawTab3
