import React from 'react'
import './Wallet_Withdraw_Card.css'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons'
import { logDOM } from '@testing-library/react'
function Wallet_Withdraw_Card({ deposit, status, type, orderno, balance, data, date }) {

  let statusColor = '';
  let depositbackground = '';
  let statusbackground = '';
  let statuscol = "";
  let depositcol = "";
  let underl = '';
  if (deposit === 'Deposit') {
    depositbackground = '#307A0C';
    depositcol = '#fff';
  };
  if (deposit === 'Withdraw' && status === 'Completed') {
    statusbackground = '#307A0C';
    statuscol = '#fff';
  }

  // Check the status and set the color accordingly
  if (status === 'To be Paid') {
    statusColor = '#FF0000';
  }
  if (deposit === 'Withdraw' && status === 'Completed') {
    statusColor = '#fff';
  }

  if (deposit === 'Succeed' && deposit === 'Failed') {
    if (deposit === 'Succeed') {
      depositcol = 'rgb(48, 122, 12)';
    }else{
      depositcol = '#fff';
    }
    
  }

  if (deposit === 'Deposit' && status === 'Completed' || deposit === 'Deposit' && status === 'To be Paid') {
    underl = 'underline';
  }
  return (
    <section className='wallet_withdraw_card_section'>
      <div className='wallet_withdraw_card_main'>
        <div className='details_row'>
          <div style={{ backgroundColor: depositbackground }} className='deposit_div '>
            <p style={{ color: depositcol, }} className='withdrow-text'>{deposit}</p>
          </div>
          <div style={{ backgroundColor: statusbackground, color: statuscol }} className='deposit_div'>
            <p style={{ color: statusColor, textDecoration: underl }} className=''>{status}</p>
          </div>
        </div>
        <div className='details_row bottom_border'>
          <div className=''>
            <p className=''>Type</p>
          </div>
          <div className=''>
            <p className=''>
              <img className="widraw_upi_icon" src={type} /></p>
          </div>
        </div>
        <div className='details_row bottom_border'>
          <div className=''>
            <p className=''>Order Number</p>
          </div>
          <div className=''>
            <p className=''>{orderno} {" "} <FontAwesomeIcon icon={faCopy} className='copy_icn ms-2' /></p>
          </div>
        </div>
        <div className='details_row'>
          <div className=''>
            <p className=''>Balance</p>
          </div>
          <div className=' balance_div'>
            <img className="widraw_doller_icon" src={process.env.PUBLIC_URL + "/assets/Icons/doller.png"} />
            {balance}
          </div>
        </div>
        <div className='dates'>
          <p className='mb-0'> <FontAwesomeIcon icon={faCalendarDays} className='me-2' />{new Date(data).toLocaleDateString('en-GB', {
            year: '2-digit',
            month: 'short',
            day: '2-digit',
          })}</p>
        </div>
      </div>
    </section>
  )
}

export default Wallet_Withdraw_Card