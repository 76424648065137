import React, { useState, useEffect, useContext } from "react";
import './ProfileAccount.css'
import { Col, Row } from 'react-bootstrap'
import Money_History from '../../Common-Component/Money_History/Money_History'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faGift, faMessage } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import FeedbackModal from '../../Common-Component/Common_Modal/AllSettingModal/FeedbackModal'
import Common_Button from '../../Common-Component/Common_Button/Common_Button'
import { Context } from '../../../utils/context';
import { loginNotification, walletData } from "../../../utils/apis/master/Master";
import { logDOM } from "@testing-library/react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProfileAccount = () => {
    const [modalFeedback, setModalFeedback] = React.useState(false);
    const { IMG_URL, userdata, getData, postData, deleteData, userId, setSignin } = useContext(Context);
    const [profile, setProdile] = useState();
    const [firstName, setfirstName] = useState();
    const [middleName, setmiddleName] = useState();
    const [lastName, setlastName] = useState();
    const navigate = useNavigate();
    const getUserProfile = async () => {
        const res = await getData(`/getProfile`);
        if (res?.success) {
            setProdile(res?.data);
            const names = res?.data?.name?.split(" ");
            setfirstName(names?.[0]);
            setmiddleName('');
            setlastName('');

            if (names?.length === 3) {
                setmiddleName(names?.[1]);
                setlastName(names?.[2]);
            } else if (names?.length === 2) {
                setlastName(names?.[1]);
            }
        }
    };
    const [noti, setNoti] = useState();
    const getNoti = async () => {
        const res = await loginNotification();
        if (res?.success) {
            setNoti(res?.data);
        }
    };
    const [wallet, setWallet] = useState();
    const getWallet = async () => {
        const res = await walletData();
        if (res?.success) {
            setWallet(res?.data);
        }
    };
    const handelclick = () => {
        console.log("dasd");
        Cookies.remove("om_winners_security");
        Cookies.remove("userID");
        setSignin(false);
        navigate('/');
    }
    useEffect(() => {
        getUserProfile();
        getNoti();
        getWallet();
    }, []);
    return (
        <>
            <section className='profile-account'>
                <Row>
                    <Col lg={6} md={6}>
                        <div className='card'>
                            <Row>
                                <Col col xxl={7} xl={7} lg={12} md={12} sm={6} className='order-sm-1 order-1' >
                                    <div className='d-flex'>
                                        <div className='profile-img me-3' >
                                            <img className="prof-logo " src={IMG_URL + profile?.image} alt="Logo" />
                                        </div>
                                        <div className='name-holder'>
                                            <h2>
                                                {profile?.name}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='details-holder mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-0 mt-0'>
                                        <div className='mob-text-img mb-2'>
                                            <img className="bedge-icon me-3" src={process.env.PUBLIC_URL + "/assets/profile/badge.png"} alt="Logo" /><span className='mo-name'>{profile?.udi}</span>
                                        </div>
                                        <p>Login Info:-
                                            {noti?.length > 0 && (
                                                <>{new Date(noti[0]?.date).toLocaleDateString('en-GB', {
                                                    year: '2-digit',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                })}</>
                                            )}
                                        </p>
                                        {/* <h6>Total Balance:</h6> */}
                                    </div>
                                </Col>
                                <Col xxl={5} xl={5} lg={12} md={12} sm={6} className='order-sm-2 order-3'>
                                    <Link to={"/Wallet"}>
                                        <div className='box-holder mb-3'>
                                            <img className="bedge-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/wallet2.png"} alt="Logo" /><span className='mo-name'>Wallet</span>
                                        </div>  </Link>

                                    <Link to={"/wallet/deposit"}>
                                        <div className='box-holder mb-3'>
                                            <img className="bedge-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/wallet.png"} alt="Logo" /><span className='mo-name'>Deposit</span>
                                        </div>
                                    </Link>

                                    <Link to="/wallet/withdraw">
                                        <div className='box-holder mb-3'>
                                            <img className="bedge-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/payout.png"} alt="Logo" /><span className='mo-name'>Withdraw</span>
                                        </div>
                                    </Link>


                                </Col>
                                <Col md={12} className='order-sm-3 order-2'>
                                    <div className='d-flex mb-md-0 mb-sm-3 mb-3 amounttext-bal-holder'>
                                        <div className='details-holder'>
                                            <h6>Total Balance:</h6>
                                        </div>
                                        <div className='rupees-holder '>
                                            <img className="rupees-icon mx-2" src={process.env.PUBLIC_URL + "/assets/profile/rupee.png"} alt="Logo" /><span className='mo-name'>{wallet?.available_balance ? ( wallet?.available_balance ) : (0)}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className='service-box'>
                            <div className='sevice-name text-center'>
                                <h2>Service Center</h2>
                            </div>
                            <div className='orance-box-holder'>
                                <Row>
                                    <Col xxl={6} xl={6} lg={12} md={12} sm={6} xs={6}>
                                        <div className='content-holder mb-3'>
                                            <Link to="/profile-setting"><img className="set-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/settings.png"} alt="Logo" /><span className='mo-name'>Settings</span></Link>
                                        </div>
                                        <div className='content-holder mb-3'>
                                            <Link to="/profile/Customer-services"><img className="set-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/customer.png"} alt="Logo" /><span className='mo-name'>Customer Service</span></Link>
                                        </div>
                                        <div className='content-holder mb-3'>
                                            <Link to="/aboutus">  <img className="set-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/download.png"} alt="Logo" /><span className='mo-name'>About Us</span></Link>
                                        </div>
                                    </Col>

                                    <Col xxl={6} xl={6} lg={12} md={12} sm={6} xs={6}>
                                        <div className='content-holder mb-3' onClick={() => setModalFeedback(true)}>
                                            <img className="set-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/notes-medical.png"} alt="Logo" /><span className='mo-name'>Feedback</span>
                                        </div>
                                        <div className='content-holder mb-3'>
                                            <Link to="/notification">  <img className="set-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/notifications-active.png"} alt="Logo" /><span className='mo-name'>Notification</span></Link>
                                        </div>
                                        <div className='content-holder mb-3'>
                                            <Link to="/wallet"> <img className="set-icon me-2" src={process.env.PUBLIC_URL + "/assets/profile/book.png"} alt="Logo" /><span className='mo-name'>Beginner's Guide</span></Link>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='white-box text-center'>
                                    <img className="customer-icon" src={process.env.PUBLIC_URL + "/assets/profile/customer-support.png"} alt="Logo" />
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={7} md={6}>
                        <div className='blue-box-holder mt-5'>
                            <Col xxl={6} xl={8} lg={10}>

                                <Link to={"/profile_gift"}>
                                    <div className='mb-3'>
                                        <Money_History text={'Gifts'}
                                            icon={process.env.PUBLIC_URL + "/assets/profile/Icongift.png"}
                                        />
                                    </div>
                                </Link>

                                <Link to={"/profile_language"}>
                                    <div className='mb-3'>
                                        <Money_History text={'Languege'}
                                            icon={process.env.PUBLIC_URL + "/assets/profile/message-2.png"}
                                        />
                                    </div>
                                </Link>

                                <Link to={"/profile_notification"}>
                                    <div className='mb-3'>
                                        <Money_History text={'Notification'}
                                            icon={process.env.PUBLIC_URL + "/assets/profile/material-notifications.png"}
                                        />
                                    </div>
                                </Link>

                            </Col>
                            <div className='overlay-color'></div>
                        </div>
                    </Col>
                    <div className='logout-button text-md-end text-sm-center text-center my-5'>
                        <Common_Button onClick={handelclick} text={'Logout'} className={'log-btn'} />
                    </div>
                </Row>
            </section>

            <FeedbackModal
                headingName="Feedback"
                show={modalFeedback}
                onHide={() => setModalFeedback(false)}
            />
        </>
    )
}

export default ProfileAccount