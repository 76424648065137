import React, { useState, useEffect, useContext } from "react";
import "./MyHistory.css";
import Simple_Box from "../../../../Common-Component/Simple_Box/Simple_Box";
import { Accordion, Pagination, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import View_full_history from "../../../../Common-Component/Common_Modal/View_full_history/View_full_history";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Context } from "../../../../../utils/context";
import { myGameHistory } from "../../../../../utils/apis/master/Master";

// Import Swiper styles
import "swiper/css";

function MyHistory() {
  const [modalShow, setModalShow] = useState(false);
  const [isAccordionExpanded, setAccordionExpanded] = useState(false);

  const toggleAccordion = () => {
    setAccordionExpanded(!isAccordionExpanded);
  };



  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();


  // Data for the repeated rows
  const repeatedRowsData = [
    {
      title: "Order Number",
      orderNumber: "WG20202799890789236475473954802873",
    },
    { title: "Period", orderNumber: "123456789012" },
    { title: "Purchase Amount", orderNumber: <>10.00</> },
    { title: "Quantity", orderNumber: "1" },
    {
      title: "Result",
      orderNumber: (
        <>
          <div>
            <p className="data-text-holder">
              2{" "}
              <Simple_Box
                className={"Simple_Box red_Box"}
                padding={"5px 12px"}
                margin={"2px 30px 2px 10px"}
                borderRadius={"3px"}
              />
              Small
            </p>
          </div>
        </>
      ),
    },

    {
      title: "Selected",
      orderNumber: (
        <>
          <div>
            <p className="data-text-holder">
              <Simple_Box
                className={"Simple_Box green_Box"}
                padding={"8px 12px"}
                margin={"4px 5px 4px 5px"}
                borderRadius={"3px"}
              />
            </p>
          </div>
        </>
      ),
    },

    { title: "Status", orderNumber: "Loss" },

    { title: "Win/Lose", orderNumber: "-9" },

    { title: "Order Time", orderNumber: "4 Feb 24" },
  ];


  const [pageLinks, setPageLinks] = useState([]);
  const { getMyGameHistory, setMyGameHistory, getData } = useContext(Context);
  // const [getMyGameHistory, setGameHistory] = useState();
  const getProject = async (page) => {
    // alert();
    const res = await myGameHistory(page);
    if (res?.success) {
      setPageLinks(res?.data?.links);
      setMyGameHistory(res?.data?.data);
    }
  }

  useEffect(() => {
    getProject();
  }, []);

  return (
    <>
      <div className="MyHistory">
        <div className="table-data-holder">
          <div className="table-responsive">
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Color"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Period"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Big/ Small"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Result"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"bet Amount"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Profit/Loss"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Date"} />
                  </th>
                  <th className="mobile-view-text-data">
                    <Simple_Box className={"Simple_Box"} text={"History"} />
                  </th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>
                    <Simple_Box
                      className={"Simple_Box red_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Loss"}
                      className={"Simple_Box grey_Box"}
                      color={"#FF1717"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={
                        <>
                          <div className="amount_holder">
                            <div className="rupee-img-holder">
                              <img
                                className="rupee_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Om_Winner_Info/rupee.png"
                                }
                                alt="rupee_img"
                              />
                            </div>

                            <div className="amount_text_holder">
                              <h4>-9</h4>
                            </div>
                          </div>
                        </>
                      }
                      className={"Simple_Box grey_Box"}
                      color={"#FF1717"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"4 Feb 24"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="desktop-toggle-arrow">
                    <FontAwesomeIcon
                      className="Accordian_arrow"
                      icon={faCaretDown}
                      onClick={toggleAccordion}
                    />
                  </td>
                  <td className="mobile-view-text-data">
                    <Simple_Box
                      text={"View full history"}
                      className={"Simple_Box grey_Box"}
                      textDecoration={"underline"}
                      onClick={() => setModalShow(true)}
                    />
                  </td>
                  <td></td>
                </tr> */}

                {/* {isAccordionExpanded &&
                  repeatedRowsData.map((rowData, index) => (
                    <>
                      <tr key={index} className="scale-up-ver-top">
                        <td></td>
                        <td colSpan={5}>
                          <Simple_Box
                            text={
                              <>
                                <div className="text-holder">
                                  <div className="title-holder">
                                    <p>{rowData.title} </p>
                                  </div>
                                  <div className="info-holder">
                                    <p>{rowData.orderNumber}</p>
                                  </div>
                                </div>
                              </>
                            }
                            className={"Simple_Box grey_Box"}
                            padding={"1px 5px"}
                            fontSize="13px"
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </>
                  ))} */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      className={"Simple_Box voilet_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Big"}
                      className={"Simple_Box grey_Box"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Loss"}
                      className={"Simple_Box grey_Box"}
                      color={"#FF1717"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={
                        <>
                          <div className="amount_holder">
                            <div className="rupee-img-holder">
                              <img
                                className="rupee_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Om_Winner_Info/rupee.png"
                                }
                                alt="rupee_img"
                              />
                            </div>

                            <div className="amount_text_holder">
                              <h4>-9</h4>
                            </div>
                          </div>
                        </>
                      }
                      className={"Simple_Box grey_Box"}
                      color={"#FF1717"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"4 Feb 24"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr> */}

                {getMyGameHistory?.map((data, index) => (
                  <tr>
                    <td>
                      <Simple_Box
                        className={data?.color === "Red" ? "Simple_Box red_Box" :
                          data?.color === "Violet" ? "Simple_Box voilet_Box" :
                            data?.color === "Green" ? "Simple_Box green_Box" :
                              ""}
                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                        width={"38px"}
                        height={"38px"}
                        margin={"0px auto"}
                      />
                    </td>
                    <td>
                      <Simple_Box
                        text={data?.period}
                        className={data?.result === "Loss" ? "Simple_Box grey_Box" :
                          "Simple_Box grey_Box Succeed"
                        }
                      />
                    </td>
                    <td>
                      {(data?.size || data?.number) && (
                        <Simple_Box
                          text={data?.size ?? data?.number}
                          className={data?.result === "Failed" ? "Simple_Box grey_Box" : "Simple_Box grey_Box Succeed"}
                          fontWeight="600"
                        />
                      )}

                    </td>
                    <td>
                      <Simple_Box
                        text={data?.result}
                        className={data?.result === "Failed" ? "Simple_Box grey_Box" :
                          "Simple_Box grey_Box Succeed"}
                        color={"#FF1717"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td>
                      <Simple_Box
                        text={
                          <>
                            <div className="amount_holder">
                              <div className="rupee-img-holder">
                                <img
                                  className="rupee_img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Home/Om_Winner_Info/rupee.png"
                                  }
                                  alt="rupee_img"
                                />
                              </div>

                              <div className="amount_text_holder">
                                <h4>{data?.amount}</h4>
                              </div>
                            </div>
                          </>
                        }
                        className={data?.result === "Loss" ? "Simple_Box grey_Box" :
                          "Simple_Box grey_Box Succeed"}
                        color={"#FF1717"}
                        fontWeight={"600"}
                      />
                    </td>

                    <td>
                      <Simple_Box
                        text={
                          <>
                            <div className="amount_holder">
                              <div className="rupee-img-holder">
                                <img
                                  className="rupee_img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Home/Om_Winner_Info/rupee.png"
                                  }
                                  alt="rupee_img"
                                />
                              </div>

                              <div className="amount_text_holder">
                                <h4>{data?.result === "Failed" ? "-" : "+"}{data?.result === "Failed" ? data?.loss : data?.profit}</h4>
                              </div>
                            </div>
                          </>
                        }
                        className={data?.result === "Failed" ? "Simple_Box grey_Box" :
                          "Simple_Box grey_Box Succeed"}
                        color={"#FF1717"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td>
                      <Simple_Box
                        text={new Date(data?.created_at).toLocaleDateString('en-GB', {
                          year: '2-digit',
                          month: 'short',
                          day: '2-digit',
                        })}

                        className={data?.result === "Loss" ? "Simple_Box grey_Box" :
                          "Simple_Box grey_Box Succeed"}
                      />
                    </td>
                  </tr>
                ))}

                {/* <tr>
                  <td>
                    <Simple_Box
                      className={"Simple_Box red_Box Succeed"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box Succeed"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box Succeed"}
                      border={"1px solid #368601"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Succeed"}
                      className={"Simple_Box grey_Box Succeed"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={
                        <>
                          <div className="amount_holder">
                            <div className="rupee-img-holder">
                              <img
                                className="rupee_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Om_Winner_Info/rupee.png"
                                }
                                alt="rupee_img"
                              />
                            </div>

                            <div className="amount_text_holder">
                              <h4>+10</h4>
                            </div>
                          </div>
                        </>
                      }
                      className={"Simple_Box grey_Box Succeed"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"4 Feb 24"}
                      className={"Simple_Box grey_Box Succeed"}
                    />
                  </td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className="pagination-design">
        <Pagination>
          {pageLinks?.map((val) => (
            <>
              {val?.label === "&laquo; Previous" && (
                <button
                  className="ms-3 previous"
                  onClick={() => {
                    getProject(val?.url?.split("?")[1]);
                  }}
                >
                  {htmlToReactParser.parse(val?.label)}
                </button>
              )}

              {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                <button
                  className={val?.active ? "ms-3 active number" : "ms-3 number"}
                  onClick={() => {
                    getProject(val?.url?.split("?")[1]);
                  }}
                >
                  {htmlToReactParser.parse(val?.label)}
                </button>
              )}

              {val?.label === "Next &raquo;" && (
                <button
                  className="ms-3 next"
                  onClick={() => {
                    getProject(val?.url?.split("?")[1]);
                  }}
                >
                  {htmlToReactParser.parse(val?.label)}
                </button>
              )}
            </>
          ))}
        </Pagination>




        {/* <>
              <Pagination.Prev />
              <Pagination.Item active>{1}</Pagination.Item>

              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Next />
            </> */}
      </div>
      <View_full_history show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default MyHistory;
