import React, { useState, useEffect } from "react";
import "./AddCash_Options.css";
import { Col, Row } from "react-bootstrap";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { walletData } from "../../../../../utils/apis/master/Master";
function AddCash_Options({selectedCard, setSelectedCard,wallet, setWalletData,getProjrct}) {

  const handleCardClick = (cardIndex) => {
    setSelectedCard(cardIndex);
  };
 

  return (
    <section className="addcash_main_section">
      <div className="addcash_main">
        <Row>
          <Col xxl={3} xl={3} lg={4} md={4}>
            <div className="left_card_div">
              <p>Balance</p>
              <div className="inner_main_data">
                <div className="innercard_div">
                  <img className="doller_icnn" src={process.env.PUBLIC_URL + "/assets/Icons/doller.png"} /><span className="prices ms-2">{wallet?.available_balance}</span>
                </div>
                <div className="wall_icn_div">
                  <FontAwesomeIcon icon={faWallet} className="fawalet" />
                </div>
              </div>
            </div>
          </Col>
          {/* <Col xxl={9} xl={9} lg={8} md={8}>
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4} className="mb-4">
                <div className={`small_cardss ${selectedCard === 1 ? 'selected' : ''}`}
                  onClick={() => handleCardClick(1)}>
                  <div className="small_cards_img">
                    <img className="qrcode" src={process.env.PUBLIC_URL + "/assets/Wallet/boxgroup.png"} />
                  </div>
                  <div className="upi_text_div">
                    <p className={`upi_text ${selectedCard === 1 ? 'afterclicktext' : ''}`}>UPI*APPS</p>
                    <div className={`click_color ${selectedCard === 1 ? 'afterClick' : ''}`}>

                    </div>
                  </div>

                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4} className="mb-4">
                <div className={`small_cardss ${selectedCard === 2 ? 'selected' : ''}`}
                  onClick={() => handleCardClick(2)}>
                  <div className="small_cards_img">
                    <img className="qrcode" src={process.env.PUBLIC_URL + "/assets/Wallet/qrcode.png"} />
                  </div>
                  <div className="upi_text_div">
                    <p className={`upi_text ${selectedCard === 2 ? 'afterclicktext' : ''}`}>UPI*QR</p>
                    <div className={`click_color ${selectedCard === 2 ? 'afterClick' : ''}`}></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4} className="mb-4">
                <div className={`small_cardss ${selectedCard === 3 ? 'selected' : ''}`}
                  onClick={() => handleCardClick(3)}>
                  <div className="small_cards_img">
                    <img className="upicode" src={process.env.PUBLIC_URL + "/assets/Wallet/upi.png"} />
                  </div>
                  <div className="upi_text_div">
                    <p className={`upi_text ${selectedCard === 3 ? 'afterclicktext' : ''}`}>UPI*PAYTM</p>
                    <div className={`click_color ${selectedCard === 3 ? 'afterClick' : ''}`}></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4} className="mb-4">
                <div className={`small_cardss ${selectedCard === 4 ? 'selected' : ''}`}
                  onClick={() => handleCardClick(4)}>
                  <div className="small_cards_img">
                    <img className="qrcode" src={process.env.PUBLIC_URL + "/assets/Wallet/tlayer.png"} />
                  </div>
                  <div className="upi_text_div">
                    <p className={`upi_text ${selectedCard === 4 ? 'afterclicktext' : ''}`}>UPI*USDT</p>
                    <div className={`click_color ${selectedCard === 4 ? 'afterClick' : ''}`}></div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </div>
    </section>
  );
}

export default AddCash_Options;