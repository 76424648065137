import React, { useState, useContext, useEffect } from "react";
import "./Profile_Gift_Main.css";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import BreadcrumComponent from "../../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import SuccessfullyPopUp from "../../../Common-Component/Common_Modal/SuccessfullyPopUp/SuccessfullyPopUp";
import Gift_Img_Holder from "../../../Common-Component/Gift_Img_Holder/Gift_Img_Holder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import { gift, giftGet } from "../../../../utils/apis/master/Master";

function Profile_Gift_Main() {
  const [modalSuccess, setModalSuccess] = React.useState(false);
  const [cupError, setCupError] = useState("");
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Profile", link: "/profile" },
    { text: "Activity", link: "/activity" },
    { text: "Gifts", link: "" },
  ]);

  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();
  const onSubmit = async (data) => {
    const res = await gift(data)
    if (res?.success) {
      setModalSuccess(true);
      reset();
    } else {
      setCupError(res?.response?.data?.data)
    }
  }
  const [getGiftGet, setGiftGet] = useState();
  const getProject = async () => {
    const res = await giftGet();
    if (res?.success) {
      setGiftGet(res?.data)
    }
  }

  useEffect(() => {
    getProject();
  }, []);

  return (
    <>
      <section className="profile_gift_section p-xxl-4 p-xl-4 p-lg-4 p-md-2 p-sm-0 p-0">
        <BreadcrumComponent items={breadcrumbItems} />
        <div className="profile_gift_main mt-5">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="giftbanner">
                <div className="gift_banner_img_div">
                  <img
                    className="gift_banner_img"
                    src={
                      process.env.PUBLIC_URL + "/assets/profile/gift-banner.png"
                    }
                  />
                </div>
                <div className="banner_half_overlay"></div>
                {/* <div className="gift_ban_text mt-3">
                  <p>Hi, We have Gifts for you</p>
                </div> */}

                <div className="gift_formdiv">
                  <Row>
                    <Col xxl={6} xl={6} lg={6} md={8} className="mx-auto">
                      <div className="gift_form_main ">
                        <Form>
                          <Form.Group
                            className="mb-3 form_group_div"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="gift_code_label">
                            Hi, We have Gifts for you
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="code"
                              placeholder="Please enter the Gift Code"
                              {...register("code", {
                                required: "Code is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.code,
                              })}
                            />
                            <span style={{ color: "red" }}>{cupError}</span>
                          </Form.Group>
                          <Common_Button
                            className="receive_button mt-4"
                            text={"Receive"}
                            onClick={handleSubmit(onSubmit)}
                          />
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="gift_history_div">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <div className="gift_history_main">
                <h6 className="gift_history_title">History</h6>

                <div className="gift-history-data-holder">
                  <div className="card-row-holder">
                    <div className="row justify-content-center">
                      {getGiftGet?.map((data) => (
                        <div className="col-xxl-6 col-xl-6 col-lg-10 col-md-10  ">
                          <div className="gift-history-card">
                            <div className="history-data-holder">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="text-holder">
                                    <h4>Successfully Received</h4>
                                    <p>{new Intl.DateTimeFormat('en-GB', {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                    }).format(new Date(data?.created_at))}{' '}
                                      {new Intl.DateTimeFormat('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                      }).format(new Date(data?.created_at))}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="btn-holder">
                                    <Common_Button
                                      className={"SubmitBtn"}
                                      text={
                                        <>
                                          <FontAwesomeIcon
                                            className="gift-icon me-5"
                                            icon={faGift}
                                          />
                                          {data?.amount}
                                        </>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* <div className="col-xxl-6 col-xl-6 col-lg-10 col-md-10  ">
                        <div className="gift-history-card">
                          <div className="history-data-holder">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-holder">
                                  <h4>Successfully Received</h4>
                                  <p>2024-05-17 11:15:54</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="btn-holder">
                                  <Common_Button
                                    className={"SubmitBtn"}
                                    text={
                                      <>
                                        <FontAwesomeIcon
                                          className="gift-icon me-5"
                                          icon={faGift}
                                        />
                                        15
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  {/* <div className="card-row-holder">
                    <div className="row justify-content-center">
                      <div className="col-xxl-6 col-xl-6 col-lg-10 col-md-10  ">
                        <div className="gift-history-card">
                          <div className="history-data-holder">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-holder">
                                  <h4>Successfully Received</h4>
                                  <p>2024-05-17 11:15:54</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="btn-holder">
                                  <Common_Button
                                    className={"SubmitBtn"}
                                    text={
                                      <>
                                        <FontAwesomeIcon
                                          className="gift-icon me-5"
                                          icon={faGift}
                                        />
                                        15
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-10 col-md-10  ">
                        <div className="gift-history-card">
                          <div className="history-data-holder">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-holder">
                                  <h4>Successfully Received</h4>
                                  <p>2024-05-17 11:15:54</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="btn-holder">
                                  <Common_Button
                                    className={"SubmitBtn"}
                                    text={
                                      <>
                                        <FontAwesomeIcon
                                          className="gift-icon me-5"
                                          icon={faGift}
                                        />
                                        15
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-row-holder">
                    <div className="row justify-content-center">
                      <div className="col-xxl-6 col-xl-6 col-lg-10 col-md-10  ">
                        <div className="gift-history-card">
                          <div className="history-data-holder">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-holder">
                                  <h4>Successfully Received</h4>
                                  <p>2024-05-17 11:15:54</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="btn-holder">
                                  <Common_Button
                                    className={"SubmitBtn"}
                                    text={
                                      <>
                                        <FontAwesomeIcon
                                          className="gift-icon me-5"
                                          icon={faGift}
                                        />
                                        15
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-10 col-md-10  ">
                        <div className="gift-history-card">
                          <div className="history-data-holder">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-holder">
                                  <h4>Successfully Received</h4>
                                  <p>2024-05-17 11:15:54</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="btn-holder">
                                  <Common_Button
                                    className={"SubmitBtn"}
                                    text={
                                      <>
                                        <FontAwesomeIcon
                                          className="gift-icon me-5"
                                          icon={faGift}
                                        />
                                        15
                                      </>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
                <Gift_Img_Holder />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <SuccessfullyPopUp
        textHeading="GIft Received Successfully!"
        show={modalSuccess}
        onHide={() => setModalSuccess(false)}
      />
    </>
  );
}

export default Profile_Gift_Main;
