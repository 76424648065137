import React, { useContext, useEffect, useState } from "react";
import Simple_Box from "../../../../Common-Component/Simple_Box/Simple_Box";
import { Pagination, Table } from "react-bootstrap";
import { SteppedLineTo } from "react-lineto";
import { Context } from "../../../../../utils/context";
import { gameHistory } from "../../../../../utils/apis/master/Master";

const GameChart = () => {
  const { IMG_URL } = useContext(Context);

  
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  
  const [pageLinks, setPageLinks] = useState([]);
  const [page, setPage] = useState();
  const { getGameHistory, setGameHistory } = useContext(Context);;

  const getProject = async (page) => {
    const res = await gameHistory(page);
    if (res?.success) {
      setGameHistory(res?.data?.data);      
      setPageLinks(res?.data?.links);
    }
  }


  useEffect(() => {
      getProject(page);
  }, [page]);

  return (
    <>
      <div className="GameChart">
        <div className="table-data-holder">
          <div className="table-responsive">
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Period"} />
                  </th>
                  <th colSpan={5}>
                    <Simple_Box className={"Simple_Box"} text={"Number"} />
                  </th>
                  <th>
                    <Simple_Box className={"Simple_Box"} text={"Big/ Small"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {getGameHistory?.map((data, index) => (

                  <tr>
                    <td>
                      <Simple_Box
                        text={data?.period}
                        className={"Simple_Box grey_Box"}
                      />
                    </td>
                    <td className="A">
                      <Simple_Box
                        text={0}
                        className={
                          data?.number === "0" ? (
                            data?.color === "Red" ? "Simple_Box red_Box" :
                              data?.color === "Violet" ? "Simple_Box voilet_Box" :
                                data?.color === "Green" ? "Simple_Box green_Box" :
                                  "Simple_Box grey_Box" // If number is 8, use grey_Box
                          ) : "Simple_Box grey_Box" // If number is not 8, use grey_Box
                        }
                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                        width={"38px"}
                        height={"38px"}
                        margin={"0px auto"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td className="B">
                      <Simple_Box
                        text={2}
                        className={
                          data?.number === "2" ? (
                            data?.color === "Red" ? "Simple_Box red_Box" :
                              data?.color === "Violet" ? "Simple_Box voilet_Box" :
                                data?.color === "Green" ? "Simple_Box green_Box" :
                                  "Simple_Box grey_Box" // If number is 8, use grey_Box
                          ) : "Simple_Box grey_Box" // If number is not 8, use grey_Box
                        }
                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                        width={"38px"}
                        height={"38px"}
                        margin={"0px auto"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td className="C">
                      <Simple_Box
                        text={4}
                        className={
                          data?.number === "4" ? (
                            data?.color === "Red" ? "Simple_Box red_Box" :
                              data?.color === "Violet" ? "Simple_Box voilet_Box" :
                                data?.color === "Green" ? "Simple_Box green_Box" :
                                  "Simple_Box grey_Box" // If number is 8, use grey_Box
                          ) : "Simple_Box grey_Box" // If number is not 8, use grey_Box
                        }
                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                        width={"38px"}
                        height={"38px"}
                        margin={"0px auto"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td className="D">
                      <Simple_Box
                        text={6}
                        className={
                          data?.number === "6" ? (
                            data?.color === "Red" ? "Simple_Box red_Box" :
                              data?.color === "Violet" ? "Simple_Box voilet_Box" :
                                data?.color === "Green" ? "Simple_Box green_Box" :
                                  "Simple_Box grey_Box" // If number is 8, use grey_Box
                          ) : "Simple_Box grey_Box" // If number is not 8, use grey_Box
                        }
                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                        width={"38px"}
                        height={"38px"}
                        margin={"0px auto"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td className="E">
                      <Simple_Box
                        text={8}
                        className={
                          data?.number === "8" ? (
                            data?.color === "Red" ? "Simple_Box red_Box" :
                              data?.color === "Violet" ? "Simple_Box voilet_Box" :
                                data?.color === "Green" ? "Simple_Box green_Box" :
                                  "Simple_Box grey_Box" // If number is 8, use grey_Box
                          ) : "Simple_Box grey_Box" // If number is not 8, use grey_Box
                        }

                        boxshadow={"0px 3px 6px #FFFFFF29"}
                        padding={"17px 0px"}
                        width={"38px"}
                        height={"38px"}
                        margin={"0px auto"}
                        fontWeight={"600"}
                      />
                    </td>
                    <td>
                      <Simple_Box
                        text={data?.size}
                        className={"Simple_Box grey_Box"}
                      />
                    </td>
                  </tr>
                ))}

                {/* <SteppedLineTo from="E" to="G" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="F">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="G">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box green_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="H">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="I">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="J">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Big"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr> */}

                <SteppedLineTo from="G" to="M" orientation="v" />

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="K">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="K">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="L">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="M">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box green_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="O">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr>

                <SteppedLineTo from="M" to="Q" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="P">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="Q">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box red_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="R">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="S">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="T">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr>

                <SteppedLineTo from="Q" to="X" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="U">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="V">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="W">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="X">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box voilet_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="y">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr>

                <SteppedLineTo from="X" to="BB" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="zzz">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="Z">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="AA">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="BB">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box voilet_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="CC">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr>

                <SteppedLineTo from="BB" to="FF" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="DD">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="EE">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="33">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="FF">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box red_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="GG">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr>

                <SteppedLineTo from="FF" to="KK" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="HH">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="37">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="II">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="JJ">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="KK">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box voilet_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr>
                <SteppedLineTo from="KK" to="LL" orientation="v" /> */}

                {/* <tr>
                  <td>
                    <Simple_Box
                      text={"123456789012"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                  <td className="LL">
                    <Simple_Box
                      text={0}
                      className={"Simple_Box red_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="MM">
                    <Simple_Box
                      text={2}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="NN">
                    <Simple_Box
                      text={4}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="OO">
                    <Simple_Box
                      text={6}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td className="pp">
                    <Simple_Box
                      text={8}
                      className={"Simple_Box grey_Box"}
                      boxshadow={"0px 3px 6px #FFFFFF29"}
                      padding={"17px 0px"}
                      width={"38px"}
                      height={"38px"}
                      margin={"0px auto"}
                      fontWeight={"600"}
                    />
                  </td>
                  <td>
                    <Simple_Box
                      text={"Small"}
                      className={"Simple_Box grey_Box"}
                    />
                  </td>
                </tr> */}
                {/* <div>
              <SteppedLineTo from="E" to="G" orientation="v" />
              <SteppedLineTo from="G" to="M" orientation="v" />
              <SteppedLineTo from="M" to="Q" orientation="v" />
              <SteppedLineTo from="Q" to="X" orientation="v" />
              <SteppedLineTo from="X" to="BB" orientation="v" />
              <SteppedLineTo from="BB" to="FF" orientation="v" />
              <SteppedLineTo from="FF" to="KK" orientation="v" />
              <SteppedLineTo from="KK" to="LL" orientation="v" />
              </div> */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className="pagination-design">
        <Pagination>
          {pageLinks?.map((val) => (
            <>
              {val?.label === "&laquo; Previous" && (
                <button
                  className="ms-3 previous"
                  onClick={() => {
                    setPage(val?.url?.split("?")[1]);
                  }}
                >
                  {htmlToReactParser.parse(val?.label)}
                </button>
              )}

              {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                <button
                  className={val?.active ? "ms-3 active number" : "ms-3 number"}
                  onClick={() => {
                    setPage(val?.url?.split("?")[1]);
                  }}
                >
                  {htmlToReactParser.parse(val?.label)}
                </button>
              )}

              {val?.label === "Next &raquo;" && (
                <button
                  className="ms-3 next"
                  onClick={() => {
                    setPage(val?.url?.split("?")[1]);
                  }}
                >
                  {htmlToReactParser.parse(val?.label)}
                </button>
              )}
            </>
          ))}
        </Pagination>
        {/* <Pagination>
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>

          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Next />
        </Pagination> */}
      </div>
    </>
  );
}

export default GameChart;
