import { faAngleRight, faBlenderPhone } from "@fortawesome/free-solid-svg-icons";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons/faGreaterThan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import './Money_History.css'
function Money_History({ text, icon }) {
  return (
    <section className="money_history_section">
      <div className="money_history_main">
        <div className="text_divs">
          <p className="history_text">
            {text}
          </p>
          <div>
            <FontAwesomeIcon icon={faAngleRight} className="icn_size" />

          </div>
        </div>
        <div className="iconed_div">
          {/* <FontAwesomeIcon icon={icon} className="icn_size" /> */}
          <img className="icn_size" src={icon} />

        </div>
      </div>
    </section>
  );
}

export default Money_History;
