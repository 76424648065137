import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import Succsessfull_Modal from "../../Common-Component/Common_Modal/Succsessfull_Modal/Succsessfull_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Notice_Modal from "../../Common-Component/Common_Modal/Notice_Modal/Notice_Modal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";

function LoginWithPhone() {
  const {
    IMG_URL,
    userdata,
    getData,
    postData,
    deleteData,
    setSignin,
    setuserId,
  } = useContext(Context);
  const [error, setErrors] = useState("");
  const [successfullModalShow, setSuccessfullModalShow] = useState(false);
  const [noticeModalShow, setNoticeModalShow] = useState(false);

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm();


  const win = window.sessionStorage;

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  const onSubmit = async (data) => {
    if(isChecked){
      Cookies.set("mobile_number", data.mobile_number);
      Cookies.set("password", data.password);
    }
    const res = await postData(`/mobileLogin`, data);
    if (res?.success) {
      Cookies.set("om_winners_security", res?.data?.token);
      Cookies.set("userID", res?.data?.user);
      reset();
      setErrors("");
      setSuccessfullModalShow(true);
      setTimeout(() => {
        setSuccessfullModalShow(false);
        setNoticeModalShow(true);
      }, 3000);
      setSignin(true);
      setuserId(res?.data?.user);
      navigate("/");
    } else {
      setErrors(res?.response?.data?.data);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  useEffect(() => {
    const mo_number  =   Cookies.get('mobile_number');
    const password   =   Cookies.get('password');
    if(mo_number && password){
      setValue('mobile_number',mo_number);
      setValue('password',password);
    }
  }, []);

  // const handleLogin = () => {
  //   // Show success modal

  //   // After 3 seconds, hide success modal and show notice modal
  //   setTimeout(() => {
  //     setSuccessfullModalShow(false);
  //     setNoticeModalShow(true);
  //   }, 3000); // Adjust the delay time as needed
  // };

  return (
    <>
      <section className="loginwith_section">
        <Container fluid className="p-0">
          <div className="img-holder desktop-view-image">
            <img
              className="bg_img"
              src={process.env.PUBLIC_URL + "/assets/Home/Login/bg-img.png"}
              alt="bg_img"
            />
          </div>
          <div className="img-holder mobile-view-image">
            <img
              className="bg_img"
              src={
                process.env.PUBLIC_URL +
                "/assets/modal-bg-img/login-bg-banner.png"
              }
              alt="bg_img"
            />
          </div>
          <div className="logincontainner">
            <Container>
              <div className="loginrowdiv">
                <Row>
                  <Col xxl={4} xl={5} lg={6} md={7} sm={9} className="mx-auto auth-form">
                    <div className="form_main">
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="labelnames">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.mobile_number,
                            })}
                            {...register("mobile_number", {
                              required: "Mobile number required",
                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                message: "Mobile number Invalid",
                              },
                            })}
                            type="text"
                            placeholder="Enter Phone No"
                            maxLength={10}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="labelnames">
                            Password
                          </Form.Label>
                          {/* <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            className="inputboxess"
                          /> */}

                          <div className="password-field-holder">
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.password,
                              })}
                              {...register("password", {
                                required: "Password is required",
                              })}
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter Password"
                            />
                            <div className="eye-icon-holder ">
                              <FontAwesomeIcon
                                onClick={togglePasswordVisibility}
                                className="eye-icon"
                                icon={showPassword ? faEyeSlash : faEye}
                              />
                            </div>
                          </div>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Remember Password"
                            onChange={handleCheckboxChange}
                          />
                        </Form.Group>
                        <br></br>
                        <div className="loginbuttonmain">
                          {/* <Button variant="primary" type="submit" className="loginbutton">
                                                        Login
                                                    </Button> */}
                          <br></br>
                          <div className="row">
                            <span
                              className="text-center"
                              style={{ color: "red" }}
                            >
                              {error}
                            </span>
                          </div>
                          <br></br>

                          <Common_Button
                            type="button"
                            text="Login"
                            className="loginbutton"
                            onClick={handleSubmit(onSubmit)}
                          />

                          <p className="donthave">
                            Don't have an account?{" "}
                            <Link
                              to={"/register-with-phone"}
                              className="registerclick"
                            >
                              Register
                            </Link>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </Container>
      </section>

      <Succsessfull_Modal
        show={successfullModalShow}
        onHide={() => setSuccessfullModalShow(false)}
        smsText="Login Successfully!"
      />

      <Notice_Modal
        show={noticeModalShow}
        onHide={() => setNoticeModalShow(false)}
      />
    </>
  );
}

export default LoginWithPhone;
