import React, { useContext, useState, useEffect } from "react";
import "./HomeBanner.css";
import Common_Card from "../../Common-Component/Common_Card/Common_Card";
import HomeInner_Page from "../HomeInner_Page/HomeInner_Page";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Context } from "../../../utils/context";

function HomeBanner({homeBanner,firstHomeBanner}) {

  
  const {IMG_URL} = useContext(Context)

  const [swiperInstance, setSwiperInstance] = useState(null);
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const UpcomingGames = [
    {
      images: process.env.PUBLIC_URL + "/assets/Home/Banner/home-page-img.png",
    },
    {
      images: process.env.PUBLIC_URL + "/assets/Home/Banner/home-page-img.png",
    },
    {
      images: process.env.PUBLIC_URL + "/assets/Home/Banner/home-page-img.png",
    },
    {
      images: process.env.PUBLIC_URL + "/assets/Home/Banner/home-page-img.png",
    },
  ];
  
  return (
    <>
      <div className="HomeBanner my-3">
        <div className="row">
          <div className="col-xl-8 col-md-8 col-lg-8 col-sm-8 col-12">
            <div className="banner-swiper ">
              <Swiper
                className="HomeBanner-mySwiper "
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                slidesPerView={1}
                spaceBetween={20}
              >
                {homeBanner?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Common_Card
                      imgClassName="banner-img"
                      // imgSource={
                      //   process.env.PUBLIC_URL +
                      //   "/assets/Home/Banner/home-page-img.png"
                      // }
                      imgSource={item?.image}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("next")}
                ></div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("prev")}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-lg-4 col-sm-4 col-12">
            <Common_Card
              imgClassName="banner-img"
              // imgSource={
              //   process.env.PUBLIC_URL + "/assets/Home/Banner/banner_img2.png"
              // }
              imgSource={firstHomeBanner?.image}
              baseLink="/home-inner"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBanner;
