import React, { useState,useEffect } from "react";
import "./Circle_Text_Holder.css";

function Circle_Text_Holder({ balanceText, background, setMultiply,activeIndex, setActiveIndex }) {

  const [isActive, setIsActive] = useState(false);

  const handleClick = (index, value) => {
    setMultiply(value);
    setActiveIndex(index === activeIndex ? null : index);
  };
  


  const textStyle = {
    background,
  };

  return (
    <>
      <div className="Circle_Text_Holder">
        {[1, 50, 100, 200, 500, 1500, 2000, 2500, 3000].map((value, index) => (
          <div
            key={index}
            className={`text-holder ${activeIndex === index ? "active" : ""}`}
            onClick={() => handleClick(index, value)}
            style={textStyle}
          >
            <h1>X {value}</h1>
          </div>
        ))}
      </div>
    </>
  );
}

export default Circle_Text_Holder;