import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import { userLanguage } from "./apis/master/Master";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";


export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://localhost/om_winner/";
  // const IMG_URL = "http://omwinners.profcymadev.in/";
  const IMG_URL = "https://omwinner.profcymabackend.com/";

  const navigate = useNavigate();

  const [sellerId, setSellerId] = useState(8);
  const [userId, setuserId] = useState();
  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  
  const [betNumber,setBetNumber] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [number, setNumber] = useState("");
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);  
  const [getMyGameHistory, setMyGameHistory] = useState([]);  
  const [getGameHistory, setGameHistory] = useState([]);  
  const [wallet, setWalletData] = useState();
  const [pageLinks, setPageLinks] = useState();
  const [multiply, setMultiply] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [getWithdrawHistory, setWithdrawHistory] = useState();    
  const [userBankDetailsHistory, setUserBankDetailsHistory] = useState([]);
  const [upiDetailsHistory, setUpiDetailsHistory] = useState([]);

  const minLength = 2;
  const maxLength = 30;


  const [userLanguageId, setUserLanguage] = useState();
  const getProject = async () => {
    const res = await userLanguage();
    if (res?.success) {
      setUserLanguage(res?.data?.language_id)
      Cookies.set('language_id', res?.data?.language_id);
    } else {
      setUserLanguage(1)
      Cookies.set('language_id', 1);
    }
  }
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  useEffect(() => {
    isTokenValid();
    getProject();
  }, [signin]);

  // const [cartCount, setCartCount] = useState(0);

  // useEffect(() => {
  //   if (Cookies.get("om_winners_security")) {
  //     setCartCount(JSON.parse(Cookies.get("om_winners_security")).length);
  //   }
  // }, [Cookies.get("om_winners_security")]);

  const isTokenValid = async () => {
    const token = Cookies.get("om_winners_security");
    setuserId(Cookies.get("userID"));
    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("om_winners_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        setUsertype(decodedToken.rolename);
        setSignin(true);
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype("");
    }
  };

  const wishlistContext = async () => {
    if (signin) {
      // const res = await getWishlist();
      // if (res?.success) {
      //   await setWishlistData(res?.data?.id);
      // }
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  const [wishlistData, setWishlistData] = useState([]);
  const [addCartModal, setAddCartModal] = useState(false);
  const [shimmerLoader, setShimmerLoader] = useState(false);
  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        userId,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setuserId,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        // token,
        // setToken,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RegxExpression,
        shimmerLoader,
        setShimmerLoader,
        addCartModal,
        setAddCartModal,
        // cartCount,
        wishlistData,
        setWishlistData,
        wishlistContext,
        sellerId,
        setSellerId,
        userLanguageId,
        setUserLanguage,
        setSelectedColor,
        selectedColor,
        setSelectedSize,
        selectedSize,
        setMyGameHistory,
        getMyGameHistory,
        wallet,
        setWalletData,
        setGameHistory,
        getGameHistory,
        setPageLinks,
        pageLinks,
        multiply,
        setMultiply,
        activeIndex,
        setActiveIndex,
        getWithdrawHistory,
        setWithdrawHistory,
        userBankDetailsHistory,
        setUserBankDetailsHistory,
        upiDetailsHistory,
        setUpiDetailsHistory,
        betNumber,
        setBetNumber
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
