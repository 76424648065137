import React, { useContext, useState, useEffect } from "react";
import "./Earnings_Chart.css";
import Heading_Holder from "../../Common-Component/Heading_Holder/Heading_Holder";
import Winner_Card from "../../Common-Component/Winner_Card/Winner_Card";
import Winner_ScoreCard_Card from "../../Common-Component/Winner_ScoreCard_Card/Winner_ScoreCard_Card";
import { Context } from "../../../utils/context";
import { todaysEarningGet } from "../../../utils/apis/master/Master";


function Earnings_Chart() {
 
  // Data for Winner_ScoreCard_Card components
  const scoreCardData = [
    {
      playerImgSource: "/assets/Home/Earnings_Chart/winner_img_3.png",
      playerName: "Yash Mali",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      amountText: "800,456.43",
      scoreText: "04",
    },
    {
      playerImgSource: "/assets/Home/Earnings_Chart/winner_img_2.png",
      playerName: "Charulata Rathod",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      amountText: "800,456.43",
      scoreText: "05",
    },
    {
      playerImgSource: "/assets/Home/Earnings_Chart/winner_img_1.png",
      playerName: "Rutuja Atkale",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      amountText: "800,456.43",
      scoreText: "06",
    },
    {
      playerImgSource: "/assets/Home/Earnings_Chart/winner_img_3.png",
      playerName: "Harshada Sontakke",
      rupeeImgSource: "/assets/Home/Om_Winner_Info/rupee.png",
      amountText: "800,456.43",
      scoreText: "07",
    },
  ];
  const { IMG_URL, userdata, getData, postData, deleteData, userId, setSignin } = useContext(Context);
  const [todaysEarning, setTodaysEarning] = useState();
  const getTodaysEarning = async () => {
    const res = await todaysEarningGet();
    if (res?.success) {
      setTodaysEarning(res?.data)
    }
  }

  useEffect(() => {
    getTodaysEarning();
  }, []);
  return (
    <>
      <div className="Earnings_Chart">
        <div className="img_overlay_holder">
          <div className="bg_img_holder">
            <img
              className="bg_img_holder-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/Home/Earnings_Chart/Earnings_Chart_banner.png"
              }
              alt="bg_img_holder"
            />
          </div>

          <div className="overlay_color"></div>
        </div>
        <div className="content-holder">
          <Heading_Holder headingText={"Today's Earnings Chart"} />

          <div className="Earnings_Chart_card_content">
            <div>
              <div className="row me-0 ms-0">
                <div className="col-xl-8 col-md-12">
                  <div className="win_player_card_holder">
                    <div className="row top_three_winners justify-content-center">
                      {todaysEarning?.length > 0 && (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                          <div className="First-winner-card-align">
                            <div className="Win_Player_card first_winner_card">
                              <Winner_Card
                                medalImgClassName="medal_img"
                                winnerImgClassName="winner_img"
                                rupeeImgSource="/assets/Home/Om_Winner_Info/rupee.png"
                                amountText={todaysEarning[0]?.profit}
                                // winnerImgSource={IMG_URL + todaysEarning[0]?.user?.image}
                                winnerImgSource={
                                  todaysEarning[0]?.user?.image
                                    ? IMG_URL + todaysEarning[0]?.user?.image
                                    : process.env.PUBLIC_URL + "/assets/Header/Header_logo/user-circle.png"
                                }
                                winnerName={todaysEarning[0]?.user?.name}
                                medalImgSource="/assets/Home/Earnings_Chart/Win_1.png"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {todaysEarning?.length > 0 && (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                          <div className="second-winner-card-align">
                            <div className="Win_Player_card second_winner_card">
                              <Winner_Card
                                medalImgClassName="medal_img "
                                winnerImgClassName="winner_img"
                                rupeeImgSource="/assets/Home/Om_Winner_Info/rupee.png"
                                amountText={todaysEarning[1]?.profit}
                                // winnerImgSource={IMG_URL + todaysEarning[1]?.user?.image}
                                winnerImgSource={
                                  todaysEarning[1]?.user?.image
                                    ? IMG_URL + todaysEarning[1]?.user?.image
                                    : process.env.PUBLIC_URL + "/assets/Header/Header_logo/user-circle.png"
                                }
                                winnerName={todaysEarning[1]?.user?.name}
                                medalImgSource="/assets/Home/Earnings_Chart/Win_2.png"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {todaysEarning?.length > 0 && (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                          <div className="third-winner-card-align">
                            <div className=" Win_Player_card third_winner_card">
                              <Winner_Card
                                medalImgClassName="medal_img "
                                winnerImgClassName="winner_img"
                                rupeeImgSource="/assets/Home/Om_Winner_Info/rupee.png"
                                amountText={todaysEarning[2]?.profit}
                                // winnerImgSource={IMG_URL + todaysEarning[2]?.user?.image}
                                winnerImgSource={
                                  todaysEarning[2]?.user?.image
                                    ? IMG_URL + todaysEarning[2]?.user?.image
                                    : process.env.PUBLIC_URL + "/assets/Header/Header_logo/user-circle.png"
                                }
                                winnerName={todaysEarning[2]?.user?.name}
                                medalImgSource="/assets/Home/Earnings_Chart/Win_3.png"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-12">
                  <div className="players_chart">
                    <div className="row justify-content-end">
                      {todaysEarning?.slice(3, 7).map((data, index) => (
                        <div key={index} className="col-xxl-9 col-xl-11 col-md-12">
                          <Winner_ScoreCard_Card
                            // playerImgSource={IMG_URL + data?.user?.image}
                            playerImgSource={
                              data?.user?.image
                                ? IMG_URL + data?.user?.image
                                : process.env.PUBLIC_URL + "/assets/Header/Header_logo/user-circle.png"
                            }
                            playerName={data?.user?.name}
                            rupeeImgSource="/assets/Home/Om_Winner_Info/rupee.png"
                            amountText={data?.profit}
                            scoreText={index + 4} // Adjust scoreText based on the starting index
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

 
    </>
  );
}

export default Earnings_Chart;
