import React, { useEffect, useState } from "react";
import "./Sorry_Modal.css";
import { Form, Modal } from "react-bootstrap";
import Simple_Box from "../../Simple_Box/Simple_Box";

function Sorry_Modal(props) {

  const [clockSecond, setClockSecond] = useState();
  let interval;
  const starterTime = () => {
    const countdownDate = new Date("January 28,2022").getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);
      if (interval < 0) {
        clearInterval(interval.current);
      } else {
        setClockSecond(seconds);
      }
    });
  };

  useEffect(() => {
    starterTime();
  });

  return (
    <>
      <div className="Congratulations_Modal">
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Notice_Modal Congratulations_Modal Sorry_Modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="img-holder">
              <img
                className="bg-img-holder"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/modal-bg-img/sorry-top-img2.png"
                }
                alt="bg-img-holder"
              />
            </div>
            <div className="content-img-holder">
              <div className="content-holder">
                <div className="text-content-holder">
                  <div className="modal-heading">
                    <h4>
                      Sorry! <br />
                      You loss bet
                    </h4>
                  </div>

                  <div className="ribbin-img-text-holder">
                    <div className="img-holder">
                      <img
                        className="ribbin-holder"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/modal-bg-img/ribbin.png"
                        }
                        alt="ribbin-holder"
                      />
                    </div>

                    <div className="ribbin-heading-holder">
                      <div className="ribbin-heading">
                        <p>Your Selected Result</p>
                      </div>

                      <div className="white-box-text-holder">
                        <p>2 </p>{" "}
                        <Simple_Box
                          className={"Simple_Box voilet_Box"}
                          boxshadow={"0px 3px 6px #FFFFFF29"}
                          padding={"14px 0px"}
                          width={"30px"}
                          height={"20px"}
                          margin={"0px auto"}
                        />{" "}
                        <p>Small</p>
                      </div>
                    </div>
                  </div>

                  <div className="text-holder">
                    <p>Period: OM Winner 1 Min </p>
                    <p> 123456789012</p>
                  </div>

                  <div className="white-patch-content-holder">
                    <div className="img-holder">
                      <img
                        className="white-patch-holder"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/modal-bg-img/white-patch.png"
                        }
                        alt="ribbin-holder"
                      />
                    </div>

                    <div className="white-patch-text-holder">
                      <div className="white-patch-heading">
                        <div>
                          <h4>You Lossed</h4>

                          <div className="amount_holder ">
                            <div className="rupee-img-holder">
                              <img
                                className="rupee_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Om_Winner_Info/rupee.png"
                                }
                                alt="rupee_img"
                              />
                            </div>

                            <div className="amount_text_holder">
                              <h4>-81</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="check-box-holder">
                    <Form>
                      <div className="mb-3">
                        <Form.Check
                          label="3 Sec auto Close"
                          name="group1"
                          type="radio"
                        />
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>

            
     
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Sorry_Modal;
