import React, { useContext, useState, useEffect } from "react";
import "./Om_Winner_Cards.css";
import Heading_Holder from "../../../Common-Component/Heading_Holder/Heading_Holder";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import Cowndown from "../../../Common-Component/Cowndown/Cowndown";
import { Link } from "react-router-dom";
import Text_Circle_Holder from "../../../Common-Component/Text_Circle_Holder/Text_Circle_Holder";
import Notice_Modal from "../../../Common-Component/Common_Modal/Notice_Modal/Notice_Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import How_To_Play_Modal from "../../../Common-Component/Common_Modal/How_To_Play_Modal/How_To_Play_Modal";
import { betData, howToPlayGet, walletData } from "../../../../utils/apis/master/Master";
import Cookies from 'js-cookie'
import { Context } from "../../../../utils/context";

function Om_Winner_Cards() {
  const { signin, wallet, setWalletData,betNumber,setBetNumber } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [cardClicked, setCardClicked] = useState(false);
  const userLanguageId = Cookies.get("language_id");
  const [howToPlay, setHowtoPlay] = useState([]);

  const getHowtoPlay = async () => {
    const res = await howToPlayGet(userLanguageId);
    if (res?.success) {
      setHowtoPlay(res?.data);
    }
  }


  const getWallet = async () => {
    const res = await walletData();
    if (res?.success) {
      setWalletData(res?.data)
    }
  }

  // const [betNumber,setBetNumber] = useState("");
  const getLatestBet = async () => {
    const res = await betData();
    if (res?.success) {
      setBetNumber(res?.data)
    }
  }

  useEffect(() => {
    getHowtoPlay();
    getWallet();
    getLatestBet();
  }, []);

  const handleCardClick = () => {
    setCardClicked(!cardClicked); // Toggle the state value
  };

  return (
    <>
      <section className="Om_Winner_Cards">
        <div className="row justify-content-between">
          <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-6 col-sm-6 col-12 mb-md-5 mb-xl-0">
            <div className="card-holder ">
              <Heading_Holder headingText={"Wallet Balance "} />
              <div className="Available_balance_Card first-card">
                <div className="text-holder">
                  <h1>Available Balance</h1>
                </div>

                <div className="amount_holder ">
                  <div className="rupee-img-holder">
                    <img
                      className="rupee_img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Home/Om_Winner_Info/rupee.png"
                      }
                      alt="rupee_img"
                    />
                  </div>

                  <div className="amount_text_holder">
                    <h4>{wallet?.available_balance}</h4>
                  </div>
                </div>

                {/* <div className="circle-holder">
                  <div class="circle"></div>
                </div> */}
              </div>
              <div className="btn-holder">
                {signin ? (
                  <Link to={"/wallet/withdraw"}>
                    <Common_Button
                      className={"SubmitBtn"}
                      text={"Withdraw"}
                      background={"transparent"}
                      border={"1px solid #fff"}
                      padding={"10px 30px"}
                      borderRadius={"5px"}
                      // margin={"0px 10px"}
                      width={"100%"}
                    />
                  </Link>
                ) : (
                  <Link to={"/login-with-phone"}>
                    <Common_Button
                      className={"SubmitBtn"}
                      text={"Withdraw"}
                      background={"transparent"}
                      border={"1px solid #fff"}
                      padding={"10px 30px"}
                      borderRadius={"5px"}
                      // margin={"0px 10px"}
                      width={"100%"}
                    />
                  </Link>
                )}
                {signin ? (
                  <Link to={"/wallet/deposit"}>
                    <Common_Button
                      className={"SubmitBtn"}
                      text={"Deposit"}
                      background={
                        "transparent linear-gradient(180deg, #F89001 0%, #FFAA00 100%)"
                      }
                      borderRadius={"5px"}
                      padding={"10px 40px"}
                      width={"100%"}
                      // margin={"0px 10px"}
                      border={" 1px solid rgb(248, 144, 1)"}
                    />
                  </Link>
                ) : (
                  <Link to={"/login-with-phone"}>
                    <Common_Button
                      className={"SubmitBtn"}
                      text={"Deposit"}
                      background={
                        "transparent linear-gradient(180deg, #F89001 0%, #FFAA00 100%)"
                      }
                      borderRadius={"5px"}
                      padding={"10px 40px"}
                      width={"100%"}
                      // margin={"0px 10px"}
                      border={" 1px solid rgb(248, 144, 1)"}
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 mb-md-5 mb-xl-0">
            <div className="card-holder middle_card">
              <div
                className={`main-card-content-holder ${cardClicked ? "card-clicked" : ""
                  }`}
              >
                <div
                  className="Available_balance_Card"
                  onClick={handleCardClick}
                >
                  <div className="img-holder">
                    <img
                      className="spinner_img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Home/Home-inner/Game_Card/fortune-black.png"
                      }
                      alt="spinner_img"
                    />
                    <img
                      className="spinner_img2"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Home/Home-inner/Game_Card/fortune.png"
                      }
                      alt="spinner_img2"
                    />
                  </div>
                </div>
                <div className="om-winner-heading-text-holder">
                  <h4>
                    {" "}
                    Om Winner <br /> 1 : 15 Min
                  </h4>
                </div>
                {cardClicked && (
                  <div className="card-border-bottom-holder"></div>
                )}
                <div className="bet-no-holder">
                  <p>
                    OnGoing Bet No :      {betNumber?.period}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 mb-md-5 mb-xl-0">
            <div className="card-holder">
              <Heading_Holder headingText={"Time Remaining"} />

              <div
                className="countdown_holder"

              >
                <Cowndown />
              </div>

              <div
                className="Available_balance_Card third-card"
                onClick={() => setModalShow(true)}
              >
                <div className="text-holder">
                  <h1>How To Play</h1>
                </div>

                <div className="white-circle-holder">
                  <Text_Circle_Holder
                    text={
                      <>
                        <FontAwesomeIcon icon={faQuestion} />
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <How_To_Play_Modal
        show={modalShow}
        howToPlay={howToPlay}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Om_Winner_Cards;
