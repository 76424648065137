import React from 'react'
import "./LoginModal.css"
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import Common_Button from '../../Common_Button/Common_Button';
const AddUsdtModal = (props) => {
    const { headingName } = props;
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='all-setting-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {headingName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='form-secc'>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Select Main Network</Form.Label>
                            <Form.Control type="email" placeholder="TRC" />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>USDT Address</Form.Label>
                            <Form.Control type="email" placeholder="Please Enter the USDT Address" />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                            <Form.Label>Address Alias</Form.Label>
                            <Form.Control type="email" placeholder="Please Enter a Remark of the Withdrawal Address" />
                        </Form.Group>

                        <div className='button-holder mt-5 text-center'>
                            <Common_Button className={'save-btn'} text={'Save'} onClick={props.onHide}></Common_Button>
                        </div>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AddUsdtModal