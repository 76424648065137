import React, { useContext, useState, useEffect } from "react";
import "./Invitation_Rules.css";
import Sidebar from "../../Sidebar/Sidebar";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import Banner_Heading from "../../Common-Component/Banner_Heading/Banner_Heading";
import Text_Circle_Holder from "../../Common-Component/Text_Circle_Holder/Text_Circle_Holder";
import { getInvitationRule } from "../../../utils/apis/master/Master";
import Cookies from 'js-cookie'

function Invitation_Rules() {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Promotion", link: "/promotion" },
    { text: "Invitation Rules" },
  ]);

  // Define card contents
  const cardContents = [
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
      circleText: "01",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus .",
      circleText: "02",
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus .",
      circleText: "03",
    },

    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus .",
      circleText: "04",
    },

    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus .",
      circleText: "05",
    },

    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus .",
      circleText: "06",
    },
  ];


  const userLanguageId = Cookies.get('language_id');
  const [invitationRule, setInvitationRule] = useState([]);

  const invitationRuleGet = async () => {
    const res = await getInvitationRule(userLanguageId);
    if (res?.success) {
      setInvitationRule(res?.data)
    }
  }


  useEffect(() => {
    invitationRuleGet();
  }, []);

  return (
    <>
      <section className="Invitation_Rules">
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              <BreadcrumComponent items={breadcrumbItems} />

              <Banner_Heading heading={"Invitation Rules"} />

              <div className="subheading-holder">
                <p>This Activity is Valid For Long Time</p>
              </div>

              <div className="Invitation_Rules_Card">
                <div className="row Invitation_Rules_Card_holder">
                  {invitationRule?.map((card, index) => (
                    <div
                      key={index}
                      className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                      <div className="card_content">
                        <div>
                          <p>{card?.invitation_rule_language?.name}</p>
                        </div>

                        <div className="circle_holder">
                          <Text_Circle_Holder text={index + 1} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Invitation_Rules;
