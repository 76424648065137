import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Common_Button from "../../Common_Button/Common_Button";
import "./Notice_Modal.css";
import Heading_Holder from "../../Heading_Holder/Heading_Holder";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie'
import { noticeGet } from "../../../../utils/apis/master/Master";

function Notice_Modal(props, { }) {

  const [getNoticeGet, setNoticeGet] = useState([]);

  const userLanguageId = Cookies.get("language_id");

  const getNotice = async () => {
    const res = await noticeGet(userLanguageId);
    if (res?.success) {
      setNoticeGet(res?.data);
    }
  }
  
  useEffect(() => {
    getNotice();
  }, []);

  return (
    <>
      <div className="Notice_Modal_sec">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="Notice_Modal"
        >
          <Link to={"/"}>
            <Modal.Header closeButton >
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
          </Link>
          <Modal.Body>
            <Heading_Holder headingText={"Notice"} />
            <div className="text-holder mx-auto">
              {getNoticeGet?.map((data, index) => (
                <ol>
                  <li>
                    {data?.notice_language?.name}
                  </li>

                  {/* <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur, officiis exercitationem. Cum, magnam numquam
                  officia rerum earum ipsam porro laborum molestias suscipit.
                </li>

                <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur, officiis exercitationem. Cum, magnam numquam
                  officia rerum earum ipsam porro laborum molestias suscipit.
                </li>

                <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Consequuntur, officiis exercitationem. Cum, magnam numquam
                  officia rerum earum ipsam porro laborum molestias suscipit.
                </li> */}
                </ol>
              ))}
            </div>
          </Modal.Body>
          <Link to={"/"}>
            <Modal.Footer>
              <Common_Button
                onClick={props.onHide}
                className={"SubmitBtn"}
                text={"Confirm"}
                width={"100%"}
                background={
                  "transparent linear-gradient(180deg, #F89001 0%, #F6AE4C 100%)"
                }
                borderRadius={"5px"}
                border={"1px solid #F6AE4C"}
                fontSize={"16px"}
                color={"#fff"}
                padding={"5px 0px"}
                margin={"0px"}
                fontWeight={"500"}
              />
            </Modal.Footer>
          </Link>
        </Modal>
      </div>
    </>
  );
}

export default Notice_Modal;
