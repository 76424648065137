import React, { useEffect,useState,useContext} from "react";
import "./Game_Info_Tabs.css";
import { Nav, Tab, Table } from "react-bootstrap";
import GameHistoryTable from "./GameHistoryTable/GameHistoryTable";
import MyHistory from "./MyHistory/MyHistory";
import GameChart from "./GameChart/GameChart";
import Nav_Pills_Holder from "../../../Common-Component/Nav_Pills_Holder/Nav_Pills_Holder";
// import Without_Login_PopUp from "../Without_Login_PopUp/Without_Login_PopUp";
import Without_Login_PopUp from "../../../Common-Component/Common_Modal/Without_Login_PopUp/Without_Login_PopUp";
import { Context } from "../../../../utils/context";


function Game_Info_Tabs() {
  const ShopTrendsPiils = [
    { eventKey: "gameHistory", title: "Game History" },
    { eventKey: "myHistory", title: "My History" },
    { eventKey: "chart", title: "Chart" },
  ];
  const {signin} = useContext(Context);
  
  const [modalWithoutLoginShow, setModalWithoutLoginShow] = useState(false);

  const handleSelect = (key) => {
    if (key === "myHistory" && !signin) {
      setModalWithoutLoginShow(true);
      return;
    }
  };

  return (
    <>
      <div className="Game_Info_Tabs">
        <Tab.Container id="left-tabs-example" defaultActiveKey="gameHistory" onSelect={handleSelect}>
          <div className="nav-tabs-holder"> 
            <Nav_Pills_Holder tabs={ShopTrendsPiils} />
          </div>

          <div className="content-holder">
            <Tab.Content>
              <Tab.Pane eventKey="gameHistory">
                <GameHistoryTable />
              </Tab.Pane>

              <Tab.Pane eventKey="myHistory">
              {signin ? <MyHistory /> : null}
              </Tab.Pane>

              <Tab.Pane eventKey="chart">
                <GameChart />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
        <Without_Login_PopUp
          show={modalWithoutLoginShow}
          onHide={() => setModalWithoutLoginShow(false)}
          smsText={"Please Log in!"}
        />
      </div>
    </>
  );
}

export default Game_Info_Tabs;
