import React, { useContext, useState } from "react";
import "./HomeInner_Page.css";
import Om_Winner_Cards from "./Om_Winner_Cards/Om_Winner_Cards";
import Sidebar from "../../Sidebar/Sidebar";
import Game_Info_Tabs from "./Game_Info_Tabs/Game_Info_Tabs";
import Game_Bid from "./Game_Bid/Game_Bid";
import BreadcrumComponent from "../../Common-Component/BreadcrumComponent/BreadcrumComponent";
import Notice from "../Notice/Notice";
import { Context } from "../../../utils/context";

function HomeInner_Page() {
  
  const { signin } = useContext(Context);
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Om Winners" },
  ]);
  return (
    <>
      <section className="HomeInner_Page">
        <div className="row me-0 ms-0">
          <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-0 p-0">
            <div className="sidebar_holder">
              <Sidebar />
            </div>
          </div>

          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-12 p-0">
            <div className="main-content-holder">
              <BreadcrumComponent items={breadcrumbItems} />
              <Om_Winner_Cards />
              <div className="notice-section ">
                <Notice />
              </div>
              <Game_Bid />
              <Game_Info_Tabs />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeInner_Page;
