import React, { useContext, useState, useEffect } from "react";
import "./Agreement.css";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { aboutUsBanner, aboutUsDescription } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import Cookies from 'js-cookie'
function Agreement() {

  const text1 = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus musLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo."


  const userLanguageId = Cookies.get('language_id');
  const { IMG_URL } = useContext(Context)

  const [activeKey, setActivekey] = useState(1);

  const [aboutBanner, setAboutBanner] = useState();

  const getAboutUSBanner = async () => {
    const res = await aboutUsBanner();
    if (res?.success) {
      setAboutBanner(res?.data)
    }
  }

  const [aboutDescription, setAboutDescription] = useState([]);

  const getAboutUSDescription = async () => {
    const res = await aboutUsDescription(userLanguageId);
    if (res?.success) {
      setAboutDescription(res?.data)
      setActivekey(res?.data?.id)
    }
  }


  useEffect(() => {
    getAboutUSBanner();
    getAboutUSDescription();
  }, []);

  return (
    <>
      <section className="agreement_section">
        <div className="agreement_main">
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey}>
            <Row>
              <Col xxl={5} xl={5} lg={12} md={12}>
                <div className="tab_button_main_div mb-3">
                  <div className="content_div">
                    <h4 className="discover">Discover Our Story</h4>
                    <h6 className="aboutus_text">About us</h6>
                    <div className="button_main_div desktop-view-tabs">
                      <Nav variant="pills" className="flex-column">
                        {aboutDescription?.map((data, index) => (
                          <Nav.Item className="navtabsss mb-3">
                            <Nav.Link eventKey={data?.id} className="agreement_button">{data?.about_description_language?.name} <FontAwesomeIcon icon={faAngleRight} className="ms-4" /></Nav.Link>
                          </Nav.Item>
                        ))}
                        {/* <Nav.Item className="navtabsss">
                          <Nav.Link eventKey="second" className="agreement_button">Risk Disclosure Agreement <FontAwesomeIcon icon={faAngleRight} className="ms-4" /></Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </div>
                  </div>
                  <div className="back_image_div">
                    {/* <img className="back_image" src={process.env.PUBLIC_URL + "/assets/AboutUS/Groupsecond.png"} /> */}
                    <img className="back_image" src={IMG_URL + aboutBanner?.image} />
                  </div>
                  <div className="image_overlay"></div>

                </div>
                <div className="button_main_div mobile-view-tabs">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item className="navtabsss mb-3">
                      <Nav.Link eventKey="first" className="agreement_button">Confidentiality Agreement <FontAwesomeIcon icon={faAngleRight} className="ms-4" /></Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="navtabsss">
                      <Nav.Link eventKey="second" className="agreement_button">Risk Disclosure Agreement <FontAwesomeIcon icon={faAngleRight} className="ms-4" /></Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col xxl={7} xl={7} lg={12} md={12}>
                <div className="tab_contents p-3">
                  <Tab.Content>
                    {aboutDescription?.map((data, index) => (
                      <Tab.Pane eventKey={data?.id}>
                        <div className="tab_text_div">
                          <h6 className="agreement_title">{data?.about_description_language?.name}</h6>
                          <p className="agree_text1">{data?.about_description_language?.description}</p>
                        </div>
                      </Tab.Pane>
                    ))}
                    {/* <Tab.Pane eventKey="second">
                      <div className="tab_text_div">
                        <h6 className="agreement_title">Risk Disclosure Agreement</h6>
                        <p className="agree_text1">{text1}</p>
                      </div>
                    </Tab.Pane> */}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}

export default Agreement;
