import React, { useState } from 'react'
import BreadcrumComponent from '../../../Common-Component/BreadcrumComponent/BreadcrumComponent'
import './ProfileGoogleVerification.css'
import { Row } from 'react-bootstrap';
import Common_Button from '../../../Common-Component/Common_Button/Common_Button';
import PasswordValidationModal from '../../../Common-Component/Common_Modal/AllSettingModal/PasswordValidationModal';
import Banner_Heading from '../../../Common-Component/Banner_Heading/Banner_Heading';
const ProfileVerification = () => {
    const [modalValid, setModalValid] = React.useState(false);
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        { text: "Profile", link: "/profile" },
        { text: "Google Verification", link: "#" },

    ]);
    const data = [
        {
            id: 1,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        },
        {
            id: 2,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        },
        {
            id: 3,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        },
        {
            id: 4,
            content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus."
        }
    ];
    return (
        <>
            <section className='google-verification'>
                <BreadcrumComponent items={breadcrumbItems} />
                <Row>
                    <Banner_Heading heading={"Google Authenticator"} />
                    <div className='white-box-holder mt-lg-5 mt-md-3 mt-sm-3 mt-3'>
                        {data.map(item => (
                            <div key={item.id} className='d-flex mb-lg-3 mb-md-0 mb-sm-0 mb-0'>
                                <div className='me-3'>
                                    <div className='orange-box '>
                                        <div className='ractangle-icon'></div>
                                    </div>
                                </div>
                                <p>{item.content}</p>
                            </div>
                        ))}
                    </div>
                    <div className='button-holder text-center mt-4'>
                        <Common_Button className={'goolgle-btn'} text={'Turn On Google Verification'} onClick={() => setModalValid(true)}></Common_Button>
                    </div>
                </Row>
            </section>
            <PasswordValidationModal
                headingName="Password Validation"
                show={modalValid}
                onHide={() => setModalValid(false)}
            />
        </>
    )
}

export default ProfileVerification