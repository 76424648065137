import React, { useState, useEffect, useContext } from 'react'
import './Delete_Popup.css'
import { Button, Modal } from 'react-bootstrap';
import Common_Button from '../../Common_Button/Common_Button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessfullyPopUp from '../SuccessfullyPopUp/SuccessfullyPopUp';
import { Context } from '../../../../utils/context';
import { deleteNoti } from '../../../../utils/apis/master/Master';
function Delete_Popup(props) {
  const [modalDelete, setModalDelete] = React.useState(false);
  const { show, onHide, smtext } = props;

 
  return (
    <>
      <section className='delete_popup_section'>
        <div className='delete_popup_div'>
          <Modal
            {...props}
            size="md"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
          >
            <Modal.Body>
              <div className='delete_popup_main'>
                <div className='delete_icon_div'>
                  <FontAwesomeIcon icon={faTrash} className="popup_delete_icon" />
                </div>
                <p>{smtext}</p>
                <div className='buttons_div'>
                  <Common_Button className="cancel_button" text="Cancel" onClick={() => { props.onHide(); }} />
                  <Common_Button className="delete_button" text="Delete" onClick={() => { props.DeleteNotification(); setModalDelete(true); props.onHide(); }} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>
      <SuccessfullyPopUp
        textHeading="Login Information deleted Successfully!"
        show={modalDelete}
        onHide={() => setModalDelete(false)}
      />
    </>
  )
}

export default Delete_Popup