import React, { useState, useEffect } from "react";
import "./App.css";
import ScrollToTop from "./Component/ScrollTotop/ScrollTotop";
import Header from "./Component/Header/Header";
import "./Component/Custom_css/Custom_css.css";
import AllRoutes from "./Component/AllRoutes/AllRoutes";
import Mobile_View_Landing from "./Component/Mobile_View_Landing/Mobile_View_Landing";
import Footer from "./Component/Footer/Footer";
import Sidebar from "./Component/Sidebar/Sidebar";
import AppContext from "./utils/context";

function App() {
  const [showLanding, setShowLanding] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLanding(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  // Check if current path is "/"
  const isHomePage = window.location.pathname === "/";

  return (
    <>
       <AppContext>

      {isHomePage && showLanding ? (
        <Mobile_View_Landing />
      ) : (
        <>
          <ScrollToTop />
          <Header />
          <AllRoutes />
          <div className="mobile-view-footer">
            <Footer />
          </div>
        </>
      )}
       </AppContext>
    </>
  );
}

export default App;
