import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./SuccessfullyPopUp.css";
import { Col, Row } from "react-bootstrap";

const SuccessfullyPopUp = (props) => {
  const { textHeading, onHide } = props;

  // Automatically close the modal after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      onHide(); // Trigger onHide event to close the modal
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clear the timer on component unmount to avoid memory leaks
    return () => clearTimeout(timer);
  }, [onHide]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Successfully-popup"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <div className="text-holder mx-auto">
            <p>{textHeading}</p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuccessfullyPopUp;
