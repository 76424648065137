import React from "react";
import "./Heading_Holder.css"

function Heading_Holder({headingText, color, fontSize, textDecoration }) {
 
const textStyle={
color,
fontSize,
textDecoration
}
  return (
    <>
      <div className="Heading_Holder">
        <div className="text-holder">
          <div className="heading-holder" >
            <h1  style={textStyle}>{headingText}</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Heading_Holder;
