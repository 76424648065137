import React from "react";
import "./Gift_Img_Holder.css";

function Gift_Img_Holder() {
  return (
    <>
      <div className="Gift_Img_Holder">
        <div className="empty_data_img_holder">
          <img className="empty_data_img" src={process.env.PUBLIC_URL + "/assets/Wallet/openbox.png"}/>
        </div>
      </div>
    </>
  );
}

export default Gift_Img_Holder;
