import React, { useState } from "react";
import "./LoginModal.css";
import Modal from "react-bootstrap/Modal";
import { Form, InputGroup } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../../Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../../utils/apis/common/Common";
import Succsessfull_Modal from "../Succsessfull_Modal/Succsessfull_Modal";


const LoginModal = (props) => {


  const [successfullModalShow, setSuccessfullModalShow] = useState(false);
  const { headingName } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [emailerror, setemailErrors] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setNewShowPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };


  
  const [contactError, setContactError] = useState("");

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();



  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  const onSubmit = async (d) => {

    if (d.new_password == d.confirm_new_password) {
      const res = await changePassword(d);
      if (res?.success) {
        setSuccessfullModalShow(true);
        reset();  
        setTimeout(() => {
          props.onHide();
          setSuccessfullModalShow(false);
        }, 3000);
      } else{        
        setemailErrors(res?.response?.data?.data);
      }
    }else {
      setContactError("PassWord Does Not Match")
    }
  }

  
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="all-setting-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-secc">
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Login Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  aria-label="password"
                  placeholder="Enter Your Login Password"
                  aria-describedby="basic-addon1"
                  className={classNames("", {
                    "is-invalid": errors?.password,
                  })}
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                    icon={showPassword ? faEye : faEyeSlash}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            
            <span className="text-center" style={{ color: 'red' }}>{emailerror}</span>


            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>New Login Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  aria-label="password"
                  placeholder="Enter Your New Login Password"
                  aria-describedby="basic-addon1"
                  {...register("new_password", {
                    required: "Password is required",
                    pattern: {
                      // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S.{8,}$/,
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*()-+=]{8,}$/,
                      message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                    }
                  })}
                  onChange={e => setPassword(e.target.value)}
                />
                <InputGroup.Text id="basic-addon1">
                  {" "}
                  <FontAwesomeIcon
                    onClick={toggleNewPasswordVisibility}
                    className="eye-icon"
                    icon={showNewPassword ? faEye : faEyeSlash}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm New Login Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your New Login Password"
                  type={showConfirmPassword ? "text" : "password"}
                  aria-label="password"
                  aria-describedby="basic-addon1"
                  className={classNames("", {
                    "is-invalid": errors?.confirm_new_password,
                  })}
                  {...register("confirm_new_password", {
                    required: "confirm new password is required",
                    pattern: {
                      value: /^(?!.*\s).*$/, // Regex to disallow spaces
                      message: "Password cannot contain spaces",
                    },
                  })}
                  onChange={e => setPasswordAgain(e.target.value)}
                />
                <InputGroup.Text id="basic-addon1">
                  {" "}
                  <FontAwesomeIcon
                    onClick={toggleConfirmPasswordVisibility}
                    className="eye-icon"
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                  />
                </InputGroup.Text>
              </InputGroup>

              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "match"]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => { }}
              />

            </Form.Group>

            
            <span style={{ color: 'red' }}>{contactError}</span>

            <Link to="/profile/Customer-services">
              {" "}
              <p className="service-text">Contact Customer Service</p>
            </Link>
            <div className="button-holder mt-3 text-center">
              <Common_Button
                className={"save-btn"}
                text={"Save"}
                // onClick={props.onHide}
                type='button'
                onClick={handleSubmit(onSubmit)}
              ></Common_Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Succsessfull_Modal
        show={successfullModalShow}
        onHide={() => setSuccessfullModalShow(false)}
        smsText="Change Password Successfully!"
      />

    </>
  );
};

export default LoginModal;
