import React, { useState } from "react";
import "./LoginModal.css";
import Modal from "react-bootstrap/Modal";
import { Form, InputGroup } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../../Common_Button/Common_Button";
import SuccessfullyPopUp from "../SuccessfullyPopUp/SuccessfullyPopUp";

const GoogleForgotPassModal = (props) => {
  const [modalSuccess, setModalSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const { headingName } = props;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="all-setting-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {headingName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-secc">
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Please retrieve/change your password through your mobile phone
                number or email
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter Your  Password"
                  type={showPassword ? "text" : "password"}
                  aria-label="password"
                  aria-describedby="basic-addon1"
                />
                <InputGroup.Text id="basic-addon1">
                
                  <FontAwesomeIcon
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <div
              className="button-holder mt-3 text-center"
              onClick={() => {
                setModalSuccess(true);
                props.onHide();
              }}
            >
              <Common_Button
                className={"save-btn"}
                text={"Confirm"}
                onClick={props.onHide}
              ></Common_Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessfullyPopUp
        textHeading="Change password Successfully!"
        show={modalSuccess}
        onHide={() => setModalSuccess(false)}
      />
    </>
  );
};

export default GoogleForgotPassModal;
