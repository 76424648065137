import React, { useState, useEffect, useContext } from 'react';
import Circle_Cownt_Down_Modal from '../Common_Modal/Circle_Cownt_Down_Modal/Circle_Cownt_Down_Modal';
import './Cowndown.css';
import { Context } from '../../../utils/context';
import { betData, betNew, gameHistory, myGameHistory, resultAction, walletData } from '../../../utils/apis/master/Master';
import Congratulations_Modal from '../Common_Modal/Congratulations_Modal/Congratulations_Modal';
import Sorry_Modal from '../Common_Modal/Sorry_Modal/Sorry_Modal';
import ReactAudioPlayer from "react-audio-player";
// import "src/sound/timer_music.mp3";
import MP3 from "../../../sound/timer_music.mp3";


function Cowndown() {
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [modalCongratsShow, setCongratsModalShow] = useState(false);
  const [modalSorryShow, setSorryModalShow] = useState(false);
  const [modalCowntDownShow, setModalCowntDownShowShow] = useState(false);
  // Function to compute the current seconds since midnight
  function currentRunningSecond() {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return (now - midnight) / 1000; // seconds since midnight
  }

  // Helper function to pad numbers
  function padTime(time) {
    return time.toString().padStart(2, '0');
  }

  const [getBetNew, setBetNew] = useState();
  const getProjectOne = async () => {
    const res = await betNew();
    if (res?.success) {
      setBetNew(res?.data)
    }
  }

  
  const {setMyGameHistory,setGameHistory, setPageLinks,setWalletData,betNumber,setBetNumber } =useContext(Context);
  const [getResultAction, setResultAction] = useState();
  const [winningData, setwinningData] = useState();
  const getProjectTwo = async () => {
    const res = await resultAction();
    if (res?.success) {
      getProjectOne();
      setResultAction(res?.data);
      setwinningData(res?.data?.userResult);
      getProject();
      getGame();
      getLatestBet();
      getWallet();
      if (res?.data?.userResult) {
        setCongratsModalShow(true);
        setTimeout(() => {
          setCongratsModalShow(false);
        }, 3000);
      }

    }
  }


  const getLatestBet = async () => {
    const res = await betData();
    if (res?.success) {
      setBetNumber(res?.data)
    }
  }

  const getProject = async () => {
    const res = await myGameHistory();
    if (res?.success) {
      setMyGameHistory(res?.data?.data)
    }
  }

  const getGame = async (page) => {
    const res = await gameHistory(page);
    if (res?.success) {
      setGameHistory(res?.data?.data);      
      setPageLinks(res?.data?.links);
    }
  }


  const getWallet = async () => {
    const res = await walletData();
    if (res?.success) {
      setWalletData(res?.data)
    }
  }

  // Function to update minutes and seconds
  function updateTimer() {
    const totalSeconds = 75 - (currentRunningSecond() % 75);
    const computedMinutes = padTime(Math.floor(totalSeconds / 60));
    const computedSeconds = padTime(Math.floor(totalSeconds % 60));
    if (computedMinutes == '00' && computedSeconds <= '10') {
      setModalCowntDownShowShow(true)
      if (computedMinutes == '00' && computedSeconds == '00') {
        setModalCowntDownShowShow(false)
      }
    }
    if (computedMinutes == '00' && computedSeconds == '01') {
      getProjectTwo();
    }
    setMinutes(computedMinutes);
    setSeconds(computedSeconds);
  }

  useEffect(() => {
    updateTimer(); // Initial update
    const interval = setInterval(() => {
      updateTimer(); // Update every second
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <>
      <div className="countdown">
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </div>
      <Circle_Cownt_Down_Modal
        seconds={seconds}
        MP3={MP3}
        show={modalCowntDownShow}
        onHide={() => setModalCowntDownShowShow(false)}
      />
      <Congratulations_Modal
        show={modalCongratsShow}
        winningData={winningData}
        onHide={() => setCongratsModalShow(false)}
      />
      <Sorry_Modal
        show={modalSorryShow}
        onHide={() => setSorryModalShow(false)}
      />
      
    </>

  );
}

export default Cowndown;
